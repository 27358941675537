import ButtonOld from "@mui/material/Button";
import { ReactElement } from "react";

import { TypesButton } from "types";

import "./button.scss";

type ButtonProps = {
  type: TypesButton;
  text: string;
  handleClick: Function;
  size?: number;
  disabled?: boolean;
  icon?: ReactElement;
};

const Button = ({
  type,
  text,
  handleClick,
  size,
  disabled,
  icon,
}: ButtonProps) => {
  return (
    <ButtonOld
      className={`btn-${TypesButton[type]}`}
      onClick={() => handleClick()}
      disabled={disabled || false}
      style={{ width: `${size}px` }}
    >
      {icon} {text}
    </ButtonOld>
  );
};

export default Button;
