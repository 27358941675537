type ISvgProps = {
  isDisable?: boolean;
};

const ExportSvg = ({ isDisable = false }: ISvgProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.5325 4.78267L7.25 3.05767V10.2502C7.25 10.4491 7.32902 10.6398 7.46967 10.7805C7.61032
        10.9211 7.80109 11.0002 8 11.0002C8.19891 11.0002 8.38968 10.9211 8.53033 10.7805C8.67098
        10.6398 8.75 10.4491 8.75 10.2502V3.05767L10.4675 4.78267C10.5372 4.85296 10.6202 4.90876 10.7116
        4.94683C10.803 4.98491 10.901 5.00451 11 5.00451C11.099 5.00451 11.197 4.98491 11.2884 4.94683C11.3798
        4.90876 11.4628 4.85296 11.5325 4.78267C11.6028 4.71294 11.6586 4.62999 11.6967 4.5386C11.7347 4.4472
        11.7543 4.34918 11.7543 4.25017C11.7543 4.15116 11.7347 4.05313 11.6967 3.96173C11.6586 3.87034
        11.6028 3.78739 11.5325 3.71767L8.5325 0.717666C8.46117 0.649386 8.37706 0.595862 8.285 0.560166C8.1024
        0.485153 7.8976 0.485153 7.715 0.560166C7.62294 0.595862 7.53883 0.649386 7.4675 0.717666L4.4675
        3.71767C4.39757 3.7876 4.3421 3.87061 4.30426 3.96198C4.26641 4.05335 4.24693 4.15127 4.24693
        4.25017C4.24693 4.34906 4.26641 4.44699 4.30426 4.53835C4.3421 4.62972 4.39757 4.71274 4.4675
        4.78267C4.53743 4.8526 4.62045 4.90807 4.71181 4.94591C4.80318 4.98376 4.90111 5.00323 5 5.00323C5.09889
        5.00323 5.19682 4.98376 5.28819 4.94591C5.37955 4.90807 5.46257 4.8526 5.5325 4.78267ZM14.75
        9.50017C14.5511 9.50017 14.3603 9.57918 14.2197 9.71984C14.079 9.86049 14 10.0513 14 10.2502V13.2502C14
        13.4491 13.921 13.6398 13.7803 13.7805C13.6397 13.9211 13.4489 14.0002 13.25 14.0002H2.75C2.55109
        14.0002 2.36032 13.9211 2.21967 13.7805C2.07902 13.6398 2 13.4491 2 13.2502V10.2502C2 10.0513
        1.92098 9.86049 1.78033 9.71984C1.63968 9.57918 1.44891 9.50017 1.25 9.50017C1.05109 9.50017
        0.860322 9.57918 0.71967 9.71984C0.579018 9.86049 0.5 10.0513 0.5 10.2502V13.2502C0.5 13.8469
        0.737053 14.4192 1.15901 14.8412C1.58097 15.2631 2.15326 15.5002 2.75 15.5002H13.25C13.8467
        15.5002 14.419 15.2631 14.841 14.8412C15.2629 14.4192 15.5 13.8469 15.5 13.2502V10.2502C15.5
        10.0513 15.421 9.86049 15.2803 9.71984C15.1397 9.57918 14.9489 9.50017 14.75 9.50017Z"
        fill={isDisable ? "#00000042" : "#1a9696"}
      />
    </svg>
  );
};

export default ExportSvg;
