import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";

import { useAppDispatch, useAppSelector } from "app/hook";
import { TypesButton } from "types";
import { ModalConfirm, Button, EditSvg, DeleteSvg } from "components/library";

import {
  Bend,
  getBends,
} from "features/content-management/content-management-slice";

import ModalAddBend from "./components/modal-add-bend";

const BendManagement = () => {
  const { listBends, loading } = useAppSelector(
    (state) => state.contentManagement
  );

  const { email, userId } = useAppSelector((state) => state.loginUser);

  const [showModalAdd, setShowModalAdd] = useState(false);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [selectedBend, setSelectedBend] = useState<Bend | null>(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (userId !== "" && email !== "" && !loading && listBends === null) {
      dispatch(getBends());
    }
  }, [dispatch, email, userId, listBends, loading]);

  const columnsBend: GridColDef[] = [
    {
      field: "",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: any) => {
        return (
          <>
            <IconButton
              className="action-btn hover-btn"
              onClick={() => {
                setSelectedBend(params.row);
                setShowModalAdd(true);
              }}
            >
              <EditSvg />
              <EditSvg isHover />
            </IconButton>
            <IconButton
              className="action-btn hover-btn"
              onClick={() => {
                setSelectedBend(params.row);
                setShowModalConfirm(true);
              }}
            >
              <DeleteSvg />
              <DeleteSvg isHover />
            </IconButton>
          </>
        );
      },
    },

    {
      field: "N",
      headerName: "№",
      width: 150,
      renderCell: (params: any) => {
        return params.api.getRowIndex(params.row.id) + 1;
      },
    },
    {
      field: "pipe_diameter",
      headerName: "Pipe diameter inch",
      width: 250,
    },
    {
      field: "bend_diameter",
      headerName: "Bend diameter inch",
      width: 250,
    },
  ];

  return (
    <div className="bend-management">
      <div className="bend-management_head">
        <div>
          <Button
            type={TypesButton.primary}
            text="Add a new dimension"
            handleClick={() => {
              setSelectedBend(null);
              setShowModalAdd(true);
            }}
            size={205}
            icon={<AddIcon fontSize="small" />}
          />
        </div>
      </div>
      <Box>
        <div style={{ height: "65vh", width: "100%" }}>
          <DataGrid
            rows={listBends || []}
            columns={columnsBend}
            rowsPerPageOptions={[10, 20, 50, 100]}
            // checkboxSelection
            loading={loading}
            components={{
              Toolbar: GridToolbar,
            }}
            onRowDoubleClick={(selectedRow) => {
              setSelectedBend(selectedRow.row);
              setShowModalAdd(true);
            }}
          />
        </div>
      </Box>
      {showModalAdd && (
        <ModalAddBend
          show={showModalAdd}
          bend={selectedBend}
          handleClose={setShowModalAdd}
        />
      )}
      {showModalConfirm && selectedBend !== null && (
        <ModalConfirm
          show={showModalConfirm}
          // eslint-disable-next-line max-len
          textQuestion={`Are you sure that you want to delete bend dimension - ${selectedBend?.id} ${selectedBend?.bend_diameter}?`}
          handleCancel={() => setShowModalConfirm(false)}
          handleYes={() => alert("User delete bend")}
        />
      )}
    </div>
  );
};

export default BendManagement;
