import { useState, useEffect } from "react";
import {
  Button,
  ButtonGroup,
  FormControlLabel,
  MenuItem,
  Box,
  Typography,
  Select,
  // InputLabel,
  FormControl,
} from "@mui/material";

import { useAppDispatch, useAppSelector } from "app/hook";

import { TypesButton } from "types";

import { Button as ButtonCustom } from "components/library";

import { statisticData } from "../../../../constants/index";

import { getDataFiltredValues } from "../../statistics-slice";

import CustomDate from "../date-range-picker/datePicker";
import { IDatePickers } from "../../statistics-slice";

import {
  getFillers,
  getPrimers,
  Filters,
  SelectedTapeRef,
  SelectedRisnRef,
} from "../../statistics-slice";

import "./dataFilter.scss";

const DataFilter = () => {
  const [filteredData, setFilteredData] = useState<Filters>({
    geozone: null,
    location: null,
    tapeRef: null,
    product: null,
    resinRef: null,
    fillerRef: null,
    primerRef: null,
    userType: "ALL",
  });
  const [dates, setDates] = useState<IDatePickers>(["", ""]);
  const [applyBtn, setApplyBtn] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const dispatch = useAppDispatch();

  const handleChange = () => {
    dispatch(getDataFiltredValues({ filteredData, dates }));
    setApplyBtn(!applyBtn);
    setDates(["", ""]);
    // setFilteredData({
    //   ...filteredData,
    //   geozone: null,
    //   location: null,
    //   tapeRef: null,
    //   product: null,
    //   resinRef: null,
    //   fillerRef: null,
    //   primerRef: null,
    // });
  };

  useEffect(() => {
    if (filteredData.product === "R4D" || filteredData.product === "R2D") {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [filteredData.product]);

  useEffect(() => {
    if (filteredData.primerRef === null) dispatch(getFillers());
    dispatch(getPrimers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filteredData.tapeRef !== null) {
      dispatch(SelectedTapeRef(filteredData.tapeRef));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredData.tapeRef]);

  useEffect(() => {
    if (filteredData.product !== null) {
      dispatch(SelectedRisnRef(filteredData.product));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredData.product]);
  const {
    listRisnRef,
    listProduct,
    listTapeRef,
    listPrimers,
    listFillers,
    geoZones,
    locations,
    totalCreatedProjects,
    totalRepairCases,
    totalValidatedProposal,
    totalRepairLength,
    totalUsedLength,
    totalNumberOfPatches,
    totalNumberQuantity,
    totalQuantityFillers,
    totalQuantityPrimers,
  } = useAppSelector((state) => state.statistics);

  const DataItemValue = (id: string, value: number) => {
    if (id === "distributors") {
      value = totalCreatedProjects;
    } else if (id === "repaired_cases") {
      value = totalRepairCases;
    } else if (id === "validated_tech_proposals") {
      value = totalValidatedProposal;
    } else if (id === "repair_length") {
      value = totalRepairLength;
    } else if (id === "tape_length") {
      value = totalUsedLength;
    } else if (id === "total_number") {
      value = totalNumberOfPatches;
    } else if (id === "quantity_resin") {
      value = totalNumberQuantity;
    } else if (id === "quantity_filler") {
      value = totalQuantityFillers;
    } else if (id === "quantity_primers") {
      value = totalQuantityPrimers;
    }

    return value;
  };

  return (
    <>
      <Box
        className="data__filter--control"
        sx={{ width: "100%", mt: "110px", minHeight: "671px" }}
      >
        <Box className="title">
          <Typography component="span" className="main__title">
            Data
          </Typography>
          <Typography component="span">
            Filter data to see correct results
          </Typography>
        </Box>
        <Typography>General filters</Typography>
        <Box
          className="data__box-control"
          component="div"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: "20px",
            ml: "30px",
            mr: "31px",
          }}
        >
          <Box
            className="user__role"
            sx={{ flexWrap: "wrap", width: "35%", mr: "30px" }}
          >
            <Typography component="div" sx={{ mb: "8px" }}>
              User role
            </Typography>
            <ButtonGroup
              className="btn-group"
              sx={{ width: "100%" }}
              variant="contained"
            >
              <Button
                className={`${
                  filteredData.userType === "ALL" ? "BtnActive" : ""
                } `}
                onClick={() =>
                  setFilteredData({
                    ...filteredData,
                    userType: "ALL",
                  })
                }
                sx={{ width: "40%" }}
              >
                All
              </Button>
              <Button
                className={`${
                  filteredData.userType === "_3X_USERS" ? "BtnActive" : ""
                } `}
                onClick={() =>
                  setFilteredData({
                    ...filteredData,
                    userType: "_3X_USERS",
                  })
                }
                sx={{ width: "80%", whiteSpace: "nowrap" }}
              >
                3X Users
              </Button>
              <Button
                className={`${
                  filteredData.userType === "DISTRIBUTORS" ? "BtnActive" : ""
                } `}
                onClick={() =>
                  setFilteredData({
                    ...filteredData,
                    userType: "DISTRIBUTORS",
                  })
                }
                sx={{ width: "80%" }}
              >
                Distributors
              </Button>
            </ButtonGroup>
          </Box>
          <Box
            component="div"
            className="select-form-box"
            sx={{
              width: "74%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "end",
            }}
          >
            <FormControlLabel
              sx={{
                width: "29%",
                paddingLeft: "8px",
                mr: "30px",
                maxWidth: "29%",
              }}
              label={"Geozone"}
              className="user__form-control"
              control={
                <Select
                  sx={{ width: "100%", maxWidth: "100%" }}
                  displayEmpty={true}
                  renderValue={(value) =>
                    value?.length
                      ? Array.isArray(value)
                        ? value.join(", ")
                        : value
                      : "Enter Geozone"
                  }
                  value={filteredData.geozone}
                  onChange={(e) =>
                    setFilteredData({
                      ...filteredData,
                      geozone: e.target.value,
                    })
                  }
                >
                  {geoZones !== null &&
                    geoZones.map((item) => {
                      return (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      );
                    })}
                </Select>
              }
            />

            <FormControlLabel
              sx={{ width: "29%", mr: "30px", maxWidth: "29%" }}
              label="Location"
              className="user__form-control"
              control={
                <Select
                  sx={{ width: "100%", maxWidth: "100%" }}
                  displayEmpty={true}
                  renderValue={(value) =>
                    value?.length
                      ? Array.isArray(value)
                        ? value.join(", ")
                        : value
                      : "Enter Location"
                  }
                  value={filteredData.location}
                  onChange={(e) =>
                    setFilteredData({
                      ...filteredData,
                      location: e.target.value,
                    })
                  }
                >
                  {locations !== null &&
                    locations.map((item) => {
                      return (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      );
                    })}
                </Select>
              }
            />

            <FormControlLabel
              sx={{ width: "29%", maxWidth: "29%" }}
              className="user__form-control"
              label="Date"
              control={
                <Box sx={{ width: "100%", maxWidth: "100%" }}>
                  <CustomDate setDates={setDates} />
                </Box>
              }
            />
          </Box>
        </Box>
        <Typography>Special filter</Typography>
        <Box
          className="special__filters--box"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: "20px",
            ml: "30px",
            mr: "31px",
            flexWrap: "wrap",
          }}
        >
          <FormControlLabel
            sx={{ width: "24%", maxWidth: "24%", paddingLeft: "12px" }}
            className="user__form-control"
            label="Tape Ref"
            control={
              <Select
                sx={{ width: "100%", maxWidth: "100%" }}
                disabled={disabled}
                displayEmpty={true}
                renderValue={(value) =>
                  value?.length
                    ? Array.isArray(value)
                      ? value.join(", ")
                      : value
                    : "Tape Ref"
                }
                value={filteredData.tapeRef}
                onChange={(e) =>
                  setFilteredData({
                    ...filteredData,
                    tapeRef: e.target.value,
                  })
                }
              >
                {listTapeRef !== null &&
                  listTapeRef.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
              </Select>
            }
          />

          <FormControlLabel
            sx={{ width: "20%", maxWidth: "20%" }}
            className="user__form-control"
            label="Product"
            control={
              <Select
                sx={{ width: "100%", maxWidth: "100%" }}
                required
                displayEmpty={true}
                renderValue={(value) =>
                  value?.length
                    ? Array.isArray(value)
                      ? value.join(", ")
                      : value
                    : "Product"
                }
                value={filteredData.product}
                onChange={(e) =>
                  setFilteredData({
                    ...filteredData,
                    product: e.target.value,
                  })
                }
              >
                {listProduct !== null &&
                  listProduct.map((item) => {
                    return (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
              </Select>
            }
          />

          <FormControlLabel
            sx={{ width: "20%", maxWidth: "20%" }}
            className="user__form-control"
            label="Resin ref"
            control={
              <Select
                sx={{ width: "100%", maxWidth: "100%" }}
                displayEmpty={true}
                renderValue={(value) =>
                  value?.length
                    ? Array.isArray(value)
                      ? value.join(", ")
                      : value
                    : "Resin ref"
                }
                value={filteredData.resinRef}
                onChange={(e) =>
                  setFilteredData({
                    ...filteredData,
                    resinRef: e.target.value,
                  })
                }
              >
                {listRisnRef !== null &&
                  listRisnRef.map((item) => {
                    return (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
              </Select>
            }
          />

          <FormControlLabel
            sx={{ width: "20%", maxWidth: "20%" }}
            className="user__form-control"
            label="Filler ref"
            control={
              <Select
                sx={{ width: "100%", maxWidth: "100%" }}
                displayEmpty={true}
                renderValue={(value) =>
                  value?.length
                    ? Array.isArray(value)
                      ? value.join(", ")
                      : value
                    : "Filler ref"
                }
                value={filteredData.fillerRef}
                onChange={(e) =>
                  setFilteredData({
                    ...filteredData,
                    fillerRef: e.target.value,
                  })
                }
              >
                {listFillers !== null &&
                  listFillers.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.name}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            }
          />
        </Box>
        <FormControl
          sx={{
            mt: "30px",
            ml: "30px",
            paddingRight: "31px",
          }}
        >
          <Typography sx={{ mb: "8px" }} component="span">
            Primer ref
          </Typography>
          <Select
            sx={{ width: "24%", maxWidth: "24%" }}
            displayEmpty={true}
            renderValue={(value) =>
              value?.length
                ? Array.isArray(value)
                  ? value.join(", ")
                  : value
                : "Primer ref"
            }
            value={filteredData.primerRef}
            onChange={(e) =>
              setFilteredData({
                ...filteredData,
                primerRef: e.target.value,
              })
            }
          >
            {listPrimers !== null &&
              listPrimers.map((item, index) => {
                return (
                  <MenuItem key={index} value={item.name}>
                    {item.name}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>

        <Box className="box-row">
          <ButtonCustom
            type={TypesButton.primary}
            text="Apply filter settings"
            handleClick={() => handleChange()}
            size={275}
          />
        </Box>
      </Box>
      <Box className="statistic-total-values">
        {statisticData.map((item) => {
          return (
            <Box
              className={`statistic-total-values_item ${item.css}
              ${item.image ? "" : "without-image"}`}
              key={item.id}
            >
              {item.image ? (
                <Box className="image-wrapp">
                  <img src={item.image} alt={item.id} />
                </Box>
              ) : (
                <Box className="empty"></Box>
              )}
              <Typography component="span" className="title">
                {item.text}
              </Typography>

              <Typography component="span" className="total-value">
                {(item.value = DataItemValue(item.id, item.value))}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </>
  );
};

export default DataFilter;
