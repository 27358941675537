// import { distribData } from "constants/";
import { useState, useEffect } from "react";
import {
  Button,
  ButtonGroup,
  FormControlLabel,
  LinearProgress,
  MenuItem,
  Box,
  Typography,
} from "@mui/material";
import Select from "@mui/material/Select";

import { useAppDispatch, useAppSelector } from "app/hook";

import { TypesButton } from "types";

import { Button as ButtonCustom } from "components/library";

import { IDatePickers } from "../../statistics-slice";
import { fetchDistributors, geoByUserFilter } from "../../statistics-slice";

// import GraphChart from "../graph-chart/graph-chart";
import CustomDate from "../date-range-picker/datePicker";
import { getScoringOfUser } from "../../statistics-slice";

import { scoringByUser } from "../../../../helpers/createUrl";

// import { barData, datasets } from "./bar-chart";
import BarChart from "./bar-chart";

import "../dataFilter/dataFilter.scss";

const UserScoring: React.FC = () => {
  const {
    listUsers,
    totalClosedProject,
    totalValidated,
    // ProductivityTotalGenereted,
    ActiveTotalGenereted,
    ProductivitySentTechProp,
    allDistributors,
    geoByUser,
    totalCreatedProjectsByUser,
  } = useAppSelector((state) => state.statistics);

  const [geozone, setGeoZone] = useState<string>("Enter");
  const [users, setUsers] = useState("");
  const [applyBtn, setApplyBtn] = useState(false);
  const [scoringActiveButton, setScoringActiveButton] = useState("ALL");
  const [dates, setDates] = useState<IDatePickers>(["", ""]);
  const dispatch = useAppDispatch();

  const getUsersID = (users: string) => {
    const arr = listUsers
      .filter((item) => item.name === users)
      .map((item) => item.id);

    return arr[0];
  };

  const handleChange = () => {
    dispatch(
      getScoringOfUser(scoringByUser(dates[0], dates[1], getUsersID(users)))
    );
    setApplyBtn(!applyBtn);
    setDates(["", ""]);
  };

  const distribData = {
    labels: ["Successfulness", "Productivity", "Activeness"],
    datasets: [
      {
        data: [
          totalValidated + totalClosedProject,
          totalCreatedProjectsByUser + ActiveTotalGenereted,
          ProductivitySentTechProp,
        ],
        barPercentage: 0.3,
        backgroundColor: ["#2AD4AC", "#F65761", "#F3A50C"],
      },
    ],
    scales: {
      stepSize: 25,
    },
  };

  useEffect(() => {
    setUsers(listUsers[0]?.name || "");
  }, [listUsers]);

  useEffect(() => {
    if (scoringActiveButton === "DISTRIBUTORS") {
      dispatch(fetchDistributors());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scoringActiveButton]);

  useEffect(() => {
    if (users) {
      getUsersID(users);
      dispatch(geoByUserFilter(users));
      setGeoZone("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  return (
    <>
      <Box
        className="scoring__system--control"
        sx={{ width: "100%", mt: "110px", minHeight: "359px" }}
      >
        <Box className="title">
          <Typography component="span" className="main__title">
            Scoring System
          </Typography>
          <Typography component="span">
            {" "}
            Filter data to see correct results
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: "50px",
            ml: "30px",
            mr: "31px",
          }}
        >
          <Box
            className="user__role"
            sx={{ flexWrap: "wrap", width: "35%", mr: "30px" }}
          >
            <Typography component="p" sx={{ mb: "8px" }}>
              User role
            </Typography>
            <ButtonGroup
              variant="contained"
              className="btn-group"
              sx={{ width: "100%" }}
            >
              <Button
                className={`${
                  scoringActiveButton === "ALL" ? "BtnActive" : ""
                } `}
                onClick={() => setScoringActiveButton("ALL")}
                sx={{ width: "40%" }}
              >
                All
              </Button>
              <Button
                className={`${
                  scoringActiveButton === "_3X_USERS" ? "BtnActive" : ""
                } `}
                onClick={() => setScoringActiveButton("_3X_USERS")}
                sx={{ width: "80%", whiteSpace: "nowrap" }}
              >
                3X Users
              </Button>
              <Button
                className={`${
                  scoringActiveButton === "DISTRIBUTORS" ? "BtnActive" : ""
                } `}
                onClick={() => setScoringActiveButton("DISTRIBUTORS")}
                sx={{ width: "80%" }}
              >
                Distributors
              </Button>
            </ButtonGroup>
          </Box>
          <Box component="form" className="form">
            <FormControlLabel
              sx={{
                width: "29%",
                paddingLeft: "8px",
                mr: "30px",
                maxWidth: "29%",
              }}
              label={"User"}
              className="user__form-control"
              control={
                <Select
                  sx={{ width: "100%", maxWidth: "100%" }}
                  value={users}
                  onChange={(e) => setUsers(e.target.value)}
                >
                  {scoringActiveButton === "DISTRIBUTORS"
                    ? allDistributors.map((item) => {
                        return (
                          <MenuItem key={item.id} value={item.name}>
                            {item.name}
                          </MenuItem>
                        );
                      })
                    : listUsers !== null &&
                      listUsers.map((item) => {
                        return (
                          <MenuItem key={item.id} value={item.name}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                </Select>
              }
            />
            <FormControlLabel
              sx={{ width: "29%", mr: "30px", maxWidth: "29%" }}
              label={"Geozone"}
              className="user__form-control"
              control={
                <Select
                  sx={{ width: "100%", maxWidth: "100%" }}
                  displayEmpty={true}
                  renderValue={(value) =>
                    value?.length
                      ? Array.isArray(value)
                        ? value.join(", ")
                        : value
                      : `${geoByUser}`
                  }
                  value={geozone}
                  onChange={(e) => setGeoZone(e.target.value)}
                >
                  {geoByUser !== null && (
                    <MenuItem value={geoByUser}>{geoByUser}</MenuItem>
                  )}
                </Select>
              }
            />
            <FormControlLabel
              sx={{ width: "29%", maxWidth: "29%" }}
              className="user__form-control"
              label={"Date"}
              control={<CustomDate setDates={setDates} />}
            />
          </Box>
        </Box>
        <Box className="box-row">
          <ButtonCustom
            type={TypesButton.primary}
            text="Apply filter settings"
            handleClick={() => handleChange()}
            size={275}
          />
        </Box>
      </Box>
      <Box className="personal-scoring">
        <Box className="personal-scoring_chart">
          <Typography component="span" className="chart-title">
            Distributor scoring
          </Typography>

          <Box
            className="border-box"
            sx={{
              mt: "50px",
            }}
          >
            <Typography sx={{ mt: "100px" }} component="span">
              Distributor:{users}
            </Typography>

            <BarChart width={200} height={200} barData={distribData} />
          </Box>
        </Box>
        <Box className="personal-scoring_your-score">
          <Box className="title">Components of your score</Box>
          <Box className="successfulness">
            <Typography component="span" className="subtitle">
              Successfulness
            </Typography>
            <LinearProgress variant="determinate" value={totalValidated} />
            <Box className="created-value-wrapp">
              <Typography component="span">{totalValidated}</Typography>
              Number of validated technical proposal
            </Box>
            <LinearProgress variant="determinate" value={totalClosedProject} />
            <Box className="created-value-wrapp">
              <Typography component="span">{totalClosedProject}</Typography>{" "}
              Number of closed project « job done »
            </Box>
          </Box>
          <Box className="activeness">
            <Typography component="span" className="subtitle">
              Activeness
            </Typography>
            <LinearProgress
              variant="determinate"
              value={totalCreatedProjectsByUser}
            />
            <Box className="created-value-wrapp">
              <Typography component="span">
                {totalCreatedProjectsByUser}
              </Typography>
              Number of created project by user
            </Box>
            <LinearProgress
              variant="determinate"
              value={ActiveTotalGenereted}
            />
            <Box className="created-value-wrapp">
              <Typography component="span">{ActiveTotalGenereted}</Typography>
              Number of generated technical proposal
            </Box>
          </Box>
          <Box className="productivity">
            <Typography component="span" className="subtitle">
              Productivity
            </Typography>
            {/* <LinearProgress
              variant="determinate"
              value={ProductivityTotalGenereted}
            />
            <Box className="created-value-wrapp">
              <Typography component="span">
                {ProductivityTotalGenereted}
              </Typography>
              Number of generated technical proposals
            </Box> */}
            <LinearProgress
              variant="determinate"
              value={ProductivitySentTechProp}
            />
            <Box className="created-value-wrapp">
              <Typography component="span">
                {ProductivitySentTechProp}
              </Typography>
              Number of sent technical proposal to client
            </Box>
          </Box>
        </Box>
      </Box>
      {/* <GraphChart /> */}
    </>
  );
};

export default UserScoring;
