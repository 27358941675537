import { memo } from "react";

import "./general-information.scss";

type GeneralInformationTextProps = {
  generalText: String[];
  setField: Function;
  loading: boolean;
};

const GeneralInformationText = ({
  generalText,
  setField,
  loading,
}: GeneralInformationTextProps) => {
  if (loading)
    return (
      <div className="general-information_form_input">
        <ul>
          <li>Saving texts...</li>
        </ul>
      </div>
    );

  return (
    <section
      className="general-information_form_input"
      id="textarea"
      contentEditable="true"
      suppressContentEditableWarning={true}
      onBlur={(e) => {
        const textarea = e.target as HTMLElement;
        setField(textarea.innerText.split("\n"));
      }}
    >
      <ul>
        {generalText !== null &&
          generalText.length > 0 &&
          generalText.map((item, index) => {
            return (
              <div key={`${index} - ${new Date().toUTCString()}`}>
                <li>{item}</li>
              </div>
            );
          })}
      </ul>
    </section>
  );
};

export default memo(GeneralInformationText);
