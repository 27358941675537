import { useEffect } from "react";
import { Box } from "@mui/material";

import { useAppDispatch, useAppSelector } from "app/hook";

import UsersFilter from "./components/userStatistic/usersFilter";
import DataFilter from "./components/dataFilter/dataFilter";
import UserScoring from "./components/userScoring/userScoring";

import {
  getUsersConfirmed,
  getGeoZonesStatistic,
  getLocation,
  getAllProjects,
  getComposits,
} from "./statistics-slice";

import "./statistics.scss";

const GeneralStatistic: React.FC = () => {
  const dispatch = useAppDispatch();
  const { accountType } = useAppSelector((state) => state.loginUser);
  const { geoZones } = useAppSelector((state) => state.statistics);

  useEffect(() => {
    if (geoZones.length === 0) {
      dispatch(getGeoZonesStatistic());
    }

    dispatch(getLocation());
    dispatch(getComposits());
    if (accountType === "ADMINISTRATOR") {
      dispatch(getUsersConfirmed());
      dispatch(getAllProjects());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className="general-statistic">
      <UsersFilter />
      <DataFilter />
      <UserScoring />
    </Box>
  );
};

export default GeneralStatistic;
