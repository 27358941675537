import { useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";

import { useAppDispatch, useAppSelector } from "app/hook";
import { TypesButton } from "types";
import {
  BootstrapDialog,
  BootstrapDialogTitle,
  Button,
} from "components/library";

import {
  cleanError,
  Composit,
  productCreateEditComposit,
  resetCreatedProduct,
} from "../products-management-slice";

import "./modal-add-composit.scss";

type ModalAddCompositErrors = {
  resin_name?: string;
  composite_length?: string;
  tape_type?: string;
  composite_thickness?: string;
  composite_type?: string;
  alpha_a?: string;
  alpha_c?: string;
  epsilon_lt?: string;
  g_lcl_1?: string;
  g_lcl_2?: string;
  g_lcl_3?: string;
  tau?: string;
  nb_resin_tape?: string;
  e_a?: string;
  e_c?: string;
  nu_lt?: string;
  sheer_modulus?: string;
  laminate_covering_offset_percent?: string;
  minimum_operating_temperature?: string;
  maximum_operating_temperature?: string;
  minimum_installation_temperature?: string;
  maximum_installation_temperature?: string;
  t_test?: string;
  t_amb?: string;
  tg_c?: string;
  sigma_lt?: string;
};

type ModalAddCompositProps = {
  show: boolean;
  handleClose: Function;
  composit: Composit | null;
  isCopy: boolean;
};

const ModalAddComposit = ({
  show,
  handleClose,
  composit,
  isCopy,
}: ModalAddCompositProps) => {
  const { error, isCreatedProduct, isEditedProduct } = useAppSelector(
    (state) => state.productsManagement
  );

  const [form, setForm] = useState(
    composit === null
      ? {
          id: null,
          trade_name: "",
          resin_name: "",
          composite_length: 0,
          composite_width: 25,
          composite_thickness: 0,
          composite_type: "",
          minimum_operating_temperature: 0,
          maximum_operating_temperature: 0,
          minimum_installation_temperature: 0,
          maximum_installation_temperature: 0,
          epsilon_lt: 0,
          sigma_lt: 0,
          alpha_a: 0,
          alpha_c: 0,
          e_a: 0,
          e_c: 0,
          nu_lt: 0,
          sheer_modulus: 0,
          g_lcl_1: 0,
          g_lcl_2: 0,
          g_lcl_3: 0,
          tau: 0,
          tg_c: 0,
          t_test: 0,
          t_amb: 0,
          laminate_covering_offset_percent: 0,
          performance_data: 0,
          tape_type: "",
          suitable_residual_or_internal_corrosion: false,
          suitable_subsea: false,
          nb_resin_tape: 0,
          color_hexadecimal: "",
          display_for_distributor: false,
          status: false,
        }
      : {
          id: composit.id,
          trade_name: composit.trade_name,
          resin_name: composit.resin_name,
          status: composit.status,
          display_for_distributor: composit.display_for_distributor,
          composite_length: composit.composite_length,
          composite_width: composit.composite_width,
          composite_thickness: composit.composite_thickness,
          composite_type: composit.composite_type,
          minimum_operating_temperature: composit.minimum_operating_temperature,
          maximum_operating_temperature: composit.maximum_operating_temperature,
          minimum_installation_temperature:
            composit.minimum_installation_temperature,
          maximum_installation_temperature:
            composit.maximum_installation_temperature,
          epsilon_lt: composit.epsilon_lt,
          sigma_lt: composit.sigma_lt,
          alpha_a: composit.alpha_a,
          alpha_c: composit.alpha_c,
          e_a: composit.e_a,
          e_c: composit.e_c,
          nu_lt: composit.nu_lt,
          sheer_modulus: composit.sheer_modulus,
          g_lcl_1: composit.g_lcl_1,
          g_lcl_2: composit.g_lcl_2,
          g_lcl_3: composit.g_lcl_3,
          tau: composit.tau,
          tg_c: composit.tg_c,
          t_test: composit.t_test,
          t_amb: composit.t_amb,
          laminate_covering_offset_percent:
            composit.laminate_covering_offset_percent,
          performance_data: composit.performance_data,
          tape_type: composit.tape_type,
          suitable_residual_or_internal_corrosion:
            composit.suitable_residual_or_internal_corrosion,
          suitable_subsea: composit.suitable_subsea,
          nb_resin_tape: composit.nb_resin_tape,
          color_hexadecimal: composit.color_hexadecimal,
        }
  );
  const [errors, setErrors] = useState<ModalAddCompositErrors>({});

  const dispatch = useAppDispatch();

  const setField = (field: string, value: number | string | boolean) => {
    if (field === "composite_type") {
      setForm({
        ...form,
        composite_type: `${value}`,
        trade_name: `${value}_${form.resin_name}_${form.composite_length}/${form.composite_width}_${form.tape_type}`,
      });
    } else if (field === "resin_name") {
      setForm({
        ...form,
        resin_name: `${value}`,
        // eslint-disable-next-line max-len
        trade_name: `${form.composite_type}_${value}_${form.composite_length}/${form.composite_width}_${form.tape_type}`,
      });
    } else if (field === "composite_length") {
      setForm({
        ...form,
        composite_length: +value,
        trade_name: `${form.composite_type}_${form.resin_name}_${value}/${form.composite_width}_${form.tape_type}`,
      });
    } else if (field === "composite_width") {
      setForm({
        ...form,
        composite_width: +value,
        trade_name: `${form.composite_type}_${form.resin_name}_${form.composite_length}/${value}_${form.tape_type}`,
      });
    } else if (field === "tape_type") {
      setForm({
        ...form,
        tape_type: `${value}`,
        // eslint-disable-next-line max-len
        trade_name: `${form.composite_type}_${form.resin_name}_${form.composite_length}/${form.composite_width}_${value}`,
      });
    } else {
      setForm({
        ...form,
        [field]: value,
      });
    }

    if (!!errors[field as keyof ModalAddCompositErrors]) {
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };

  const validateForm = () => {
    const { resin_name } = form;
    const newErrors: ModalAddCompositErrors = {};

    if (!resin_name || resin_name === "")
      newErrors.resin_name = "Please enter resin name";
    // if (!max_temp || max_temp === 0)
    //   newErrors.max_temp = "Please enter temperature Pipe Max";
    // if (!density || density === 0) newErrors.density = "Please enter density";

    return newErrors;
  };

  const handleCloseModal = () => {
    handleClose();
    dispatch(cleanError());
    dispatch(resetCreatedProduct());
  };

  const handleSave = () => {
    const formErrors = validateForm();

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      if (isCopy) {
        dispatch(productCreateEditComposit({ ...form, id: null }));
      } else {
        dispatch(productCreateEditComposit({ ...form }));
      }
      setErrors({});
    }
  };

  return (
    <BootstrapDialog
      onClose={() => handleCloseModal()}
      aria-labelledby="customized-add-composit"
      open={show}
      className="modal-add-composit"
    >
      <BootstrapDialogTitle
        id="customized-add-composit"
        onClose={() => handleCloseModal()}
      >
        {composit === null && "Add a new composite"}
        {composit !== null && (isCopy ? "Copy composite" : "Edit composite")}
      </BootstrapDialogTitle>
      <DialogContent className="modal-add-composit_body">
        <form className="modal-add-composit_body_form" autoComplete="off">
          <FormControlLabel
            className="modal-add-composit_body_form_control-label-switch"
            control={
              <Switch
                checked={form.status}
                onChange={(e) => {
                  setField("status", e.target.checked);
                }}
              />
            }
            label={"Product status"}
            labelPlacement="start"
            value="status"
          />

          <FormControlLabel
            className="modal-add-composit_body_form_control-label-switch"
            control={
              <Switch
                checked={form.display_for_distributor}
                onChange={(e) => {
                  setField("display_for_distributor", e.target.checked);
                }}
              />
            }
            label={"Display for distrib"}
            labelPlacement="start"
            value="display_for_distributor"
          />

          <FormControlLabel
            className="modal-add-composit_body_form_control-label-switch"
            control={
              <Switch
                checked={form.suitable_subsea}
                onChange={(e) => {
                  setField("suitable_subsea", e.target.checked);
                }}
              />
            }
            label={"Is subsea"}
            labelPlacement="start"
            value="suitable_subsea"
          />

          <FormControlLabel
            className="modal-add-composit_body_form_control-label-switch"
            control={
              <Switch
                checked={form.suitable_residual_or_internal_corrosion}
                onChange={(e) => {
                  setField(
                    "suitable_residual_or_internal_corrosion",
                    e.target.checked
                  );
                }}
              />
            }
            sx={{
              whiteSpace: "pre-line",
            }}
            label={
              "Is Suitable for residual metal < 1mm\nor internal corrosion with metal loss > 80%"
            }
            labelPlacement="start"
            value="suitable_residual_or_internal_corrosion"
          />

          <FormControlLabel
            className="modal-add-composit_body_form_control-label"
            control={
              <TextField
                id="trade_name"
                value={form.trade_name}
                // onChange={(e) => {
                //   setField("trade_name", e.target.value);
                // }}
                sx={{
                  marginTop: "0px",
                  marginBottom: "0px",
                }}
                disabled
              />
            }
            label={"Trade name"}
            labelPlacement="start"
            value="trade_name"
          />
          <FormControlLabel
            className="modal-add-composit_body_form_control-label"
            control={
              <TextField
                autoFocus
                id="resin_name"
                value={form.resin_name}
                onChange={(e) => {
                  setField("resin_name", e.target.value);
                }}
                required
                error={errors.resin_name ? true : false}
                helperText={errors.resin_name}
                sx={{
                  marginTop: "0px",
                  marginBottom: "0px",
                }}
              />
            }
            label={"Resin name"}
            labelPlacement="start"
            value="resin_name"
          />
          <FormControlLabel
            className="modal-add-composit_body_form_control-label"
            control={
              <TextField
                id="composite_type"
                value={form.composite_type}
                onChange={(e) => {
                  setField("composite_type", e.target.value);
                }}
                required
                error={errors.composite_type ? true : false}
                helperText={errors.composite_type}
                sx={{
                  marginTop: "0px",
                  marginBottom: "0px",
                }}
              />
            }
            label={"Composite type"}
            labelPlacement="start"
            value="composite_type"
          />
          <FormControlLabel
            className="modal-add-composit_body_form_control-label"
            control={
              <TextField
                type="number"
                // InputProps={{
                //   inputProps: {
                //     max: 100,
                //     min: 0,
                //   },
                // }}
                error={errors.composite_length ? true : false}
                id="composite_length"
                value={form.composite_length}
                onChange={(e) => {
                  setField("composite_length", parseFloat(e.target.value));
                }}
                required
                helperText={errors.composite_length}
                sx={{
                  marginTop: "0px",
                  marginBottom: "0px",
                }}
              />
            }
            label={"Composite length"}
            labelPlacement="start"
            value="composite_length"
          />

          <FormControlLabel
            className="modal-add-composit_body_form_control-label"
            control={
              <Select
                id="composite_width"
                value={form.composite_width}
                onChange={(e) => {
                  setField("composite_width", e.target.value);
                }}
              >
                <MenuItem key="25" value="25">
                  25
                </MenuItem>
                <MenuItem key="50" value="50">
                  50
                </MenuItem>
                <MenuItem key="100" value="100">
                  100
                </MenuItem>
                <MenuItem key="300" value="300">
                  300
                </MenuItem>
              </Select>
            }
            label={"Composite width"}
            labelPlacement="start"
            value="composite_width"
          />

          <FormControlLabel
            className="modal-add-composit_body_form_control-label"
            control={
              <TextField
                error={errors.tape_type ? true : false}
                id="tape_type"
                value={form.tape_type}
                onChange={(e) => {
                  setField("tape_type", e.target.value);
                }}
                required
                helperText={errors.tape_type}
                sx={{
                  marginTop: "0px",
                  marginBottom: "0px",
                }}
              />
            }
            label={"Tape type"}
            labelPlacement="start"
            value="tape_type"
          />

          <div className="modal-add-composit_body_form_wrap-cols">
            <div className="modal-add-composit_body_form_wrap-cols_col1">
              <FormControlLabel
                className="modal-add-composit_body_form_control-label-small-two"
                control={
                  <div className="modal-add-composit_body_form_control-label-small-two_wrap-field">
                    <div>
                      <span className="modal-add-composit_body_form_control-label-small-two_prefix">
                        min
                      </span>
                      <TextField
                        type="number"
                        // InputProps={{
                        //   inputProps: {
                        //     max: 1000,
                        //     min: 0,
                        //   },
                        // }}
                        error={
                          errors.minimum_operating_temperature ? true : false
                        }
                        id="minimum_operating_temperature"
                        value={form.minimum_operating_temperature}
                        onChange={(e) => {
                          setField(
                            "minimum_operating_temperature",
                            parseFloat(e.target.value)
                          );
                        }}
                        required
                        helperText={errors.minimum_operating_temperature}
                        sx={{
                          marginTop: "0px",
                          marginBottom: "0px",
                        }}
                      />
                      <span className="modal-add-composit_body_form_control-label-small_units">
                        °C
                      </span>
                    </div>
                    <div>
                      <span className="modal-add-composit_body_form_control-label-small-two_prefix">
                        max
                      </span>
                      <TextField
                        type="number"
                        // InputProps={{
                        //   inputProps: {
                        //     max: 1000,
                        //     min: 0,
                        //   },
                        // }}
                        error={
                          errors.maximum_operating_temperature ? true : false
                        }
                        id="maximum_operating_temperature"
                        value={form.maximum_operating_temperature}
                        onChange={(e) => {
                          setField(
                            "maximum_operating_temperature",
                            parseFloat(e.target.value)
                          );
                        }}
                        required
                        helperText={errors.maximum_operating_temperature}
                        sx={{
                          marginTop: "0px",
                          marginBottom: "0px",
                        }}
                      />
                      <span className="modal-add-composit_body_form_control-label-small_units">
                        °C
                      </span>
                    </div>
                  </div>
                }
                label={"Temperature pipe C"}
                labelPlacement="start"
                value="maximum_operating_temperature"
              />

              <FormControlLabel
                className="modal-add-composit_body_form_control-label-small-two"
                control={
                  <div className="modal-add-composit_body_form_control-label-small-two_wrap-field">
                    <div>
                      <span className="modal-add-composit_body_form_control-label-small-two_prefix">
                        min
                      </span>
                      <TextField
                        type="number"
                        // InputProps={{
                        //   inputProps: {
                        //     max: 1000,
                        //     min: 0,
                        //   },
                        // }}
                        error={
                          errors.minimum_installation_temperature ? true : false
                        }
                        id="minimum_installation_temperature"
                        value={form.minimum_installation_temperature}
                        onChange={(e) => {
                          setField(
                            "minimum_installation_temperature",
                            parseFloat(e.target.value)
                          );
                        }}
                        required
                        helperText={errors.minimum_installation_temperature}
                        sx={{
                          marginTop: "0px",
                          marginBottom: "0px",
                        }}
                      />
                      <span className="modal-add-composit_body_form_control-label-small_units">
                        °C
                      </span>
                    </div>
                    <div>
                      <span className="modal-add-composit_body_form_control-label-small-two_prefix">
                        max
                      </span>
                      <TextField
                        type="number"
                        // InputProps={{
                        //   inputProps: {
                        //     max: 1000,
                        //     min: 0,
                        //   },
                        // }}
                        error={
                          errors.maximum_installation_temperature ? true : false
                        }
                        id="maximum_installation_temperature"
                        value={form.maximum_installation_temperature}
                        onChange={(e) => {
                          setField(
                            "maximum_installation_temperature",
                            parseFloat(e.target.value)
                          );
                        }}
                        required
                        helperText={errors.maximum_installation_temperature}
                        sx={{
                          marginTop: "0px",
                          marginBottom: "0px",
                        }}
                      />
                      <span className="modal-add-composit_body_form_control-label-small_units">
                        °C
                      </span>
                    </div>
                  </div>
                }
                label={"Temperature install C"}
                labelPlacement="start"
                value="maximum_installation_temperature"
              />

              <FormControlLabel
                className="modal-add-composit_body_form_control-label-small"
                control={
                  <div className="modal-add-composit_body_form_control-label-small_wrap-field">
                    <TextField
                      type="number"
                      // InputProps={{
                      //   inputProps: {
                      //     max: 1000,
                      //     min: 0,
                      //   },
                      // }}
                      error={errors.e_a ? true : false}
                      id="e_a"
                      value={form.e_a}
                      onChange={(e) => {
                        setField("e_a", parseFloat(e.target.value));
                      }}
                      required
                      helperText={errors.e_a}
                      sx={{
                        marginTop: "0px",
                        marginBottom: "0px",
                      }}
                    />
                    <span className="modal-add-composit_body_form_control-label-small_units">
                      (Mpa)
                    </span>
                  </div>
                }
                label={"El : Laminate longitudinal modulus"}
                labelPlacement="start"
                value="e_a"
              />

              <FormControlLabel
                className="modal-add-composit_body_form_control-label-small"
                control={
                  <div className="modal-add-composit_body_form_control-label-small_wrap-field">
                    <TextField
                      type="number"
                      // InputProps={{
                      //   inputProps: {
                      //     max: 1000,
                      //     min: 0,
                      //   },
                      // }}
                      error={errors.e_c ? true : false}
                      id="e_c"
                      value={form.e_c}
                      onChange={(e) => {
                        setField("e_c", parseFloat(e.target.value));
                      }}
                      required
                      helperText={errors.e_c}
                      sx={{
                        marginTop: "0px",
                        marginBottom: "0px",
                      }}
                    />
                    <span className="modal-add-composit_body_form_control-label-small_units">
                      (Mpa)
                    </span>
                  </div>
                }
                label={"Et : Laminate transverse modulus "}
                labelPlacement="start"
                value="e_c"
              />

              <FormControlLabel
                className="modal-add-composit_body_form_control-label-small"
                control={
                  <div className="modal-add-composit_body_form_control-label-small_wrap-field">
                    <TextField
                      type="number"
                      // InputProps={{
                      //   inputProps: {
                      //     max: 1000,
                      //     min: 0,
                      //   },
                      // }}
                      error={errors.nu_lt ? true : false}
                      id="nu_lt"
                      value={form.nu_lt}
                      onChange={(e) => {
                        setField("nu_lt", parseFloat(e.target.value));
                      }}
                      required
                      helperText={errors.nu_lt}
                      sx={{
                        marginTop: "0px",
                        marginBottom: "0px",
                      }}
                    />
                    <span className="modal-add-composit_body_form_control-label-small_units">
                      (Mpa)
                    </span>
                  </div>
                }
                label={"Nult : Laminate longitudinal Poisson's number "}
                labelPlacement="start"
                value="nu_lt"
              />

              <FormControlLabel
                className="modal-add-composit_body_form_control-label-small"
                control={
                  <div className="modal-add-composit_body_form_control-label-small_wrap-field">
                    <TextField
                      type="number"
                      // InputProps={{
                      //   inputProps: {
                      //     max: 1000,
                      //     min: 0,
                      //   },
                      // }}
                      error={errors.sheer_modulus ? true : false}
                      id="sheer_modulus"
                      value={form.sheer_modulus}
                      onChange={(e) => {
                        setField("sheer_modulus", parseFloat(e.target.value));
                      }}
                      required
                      helperText={errors.sheer_modulus}
                      sx={{
                        marginTop: "0px",
                        marginBottom: "0px",
                      }}
                    />
                    <span className="modal-add-composit_body_form_control-label-small_units">
                      (Mpa)
                    </span>
                  </div>
                }
                label={"Glt : Shear modulus of the composite Laminate"}
                labelPlacement="start"
                value="sheer_modulus"
              />

              <FormControlLabel
                className="modal-add-composit_body_form_control-label-small"
                control={
                  <div className="modal-add-composit_body_form_control-label-small_wrap-field">
                    <TextField
                      type="number"
                      // InputProps={{
                      //   inputProps: {
                      //     max: 1000,
                      //     min: 0,
                      //   },
                      // }}
                      error={errors.t_test ? true : false}
                      id="t_test"
                      value={form.t_test}
                      onChange={(e) => {
                        setField("t_test", parseFloat(e.target.value));
                      }}
                      required
                      helperText={errors.t_test}
                      sx={{
                        marginTop: "0px",
                        marginBottom: "0px",
                      }}
                    />
                    <span className="modal-add-composit_body_form_control-label-small_units"></span>
                  </div>
                }
                label={"Ttest"}
                labelPlacement="start"
                value="t_test"
              />

              <FormControlLabel
                className="modal-add-composit_body_form_control-label-small"
                control={
                  <div className="modal-add-composit_body_form_control-label-small_wrap-field">
                    <TextField
                      type="number"
                      // InputProps={{
                      //   inputProps: {
                      //     max: 1000,
                      //     min: 0,
                      //   },
                      // }}
                      error={errors.t_amb ? true : false}
                      id="t_amb"
                      value={form.t_amb}
                      onChange={(e) => {
                        setField("t_amb", parseFloat(e.target.value));
                      }}
                      required
                      helperText={errors.t_amb}
                      sx={{
                        marginTop: "0px",
                        marginBottom: "0px",
                      }}
                    />
                    <span className="modal-add-composit_body_form_control-label-small_units"></span>
                  </div>
                }
                label={"Tamb"}
                labelPlacement="start"
                value="t_amb"
              />

              <FormControlLabel
                className="modal-add-composit_body_form_control-label-small"
                control={
                  <div className="modal-add-composit_body_form_control-label-small_wrap-field">
                    <TextField
                      type="number"
                      // InputProps={{
                      //   inputProps: {
                      //     max: 1000,
                      //     min: 0,
                      //   },
                      // }}
                      error={errors.tg_c ? true : false}
                      id="tg_c"
                      value={form.tg_c}
                      onChange={(e) => {
                        setField("tg_c", parseFloat(e.target.value));
                      }}
                      required
                      helperText={errors.tg_c}
                      sx={{
                        marginTop: "0px",
                        marginBottom: "0px",
                      }}
                    />
                    <span className="modal-add-composit_body_form_control-label-small_units"></span>
                  </div>
                }
                label={"Tg"}
                labelPlacement="start"
                value="tg_c"
              />

              <FormControlLabel
                className="modal-add-composit_body_form_control-label-small"
                control={
                  <div className="modal-add-composit_body_form_control-label-small_wrap-field">
                    <TextField
                      type="number"
                      // InputProps={{
                      //   inputProps: {
                      //     max: 1000,
                      //     min: 0,
                      //   },
                      // }}
                      error={
                        errors.laminate_covering_offset_percent ? true : false
                      }
                      id="laminate_covering_offset_percent"
                      value={form.laminate_covering_offset_percent}
                      onChange={(e) => {
                        setField(
                          "laminate_covering_offset_percent",
                          parseFloat(e.target.value)
                        );
                      }}
                      required
                      helperText={errors.laminate_covering_offset_percent}
                      sx={{
                        marginTop: "0px",
                        marginBottom: "0px",
                      }}
                    />
                    <span className="modal-add-composit_body_form_control-label-small_units">
                      (%)
                    </span>
                  </div>
                }
                label={"Laminate covering offset"}
                labelPlacement="start"
                value="laminate_covering_offset_percent"
              />

              <FormControlLabel
                className="modal-add-composit_body_form_control-label-small"
                control={
                  <div className="modal-add-composit_body_form_control-label-small_wrap-field">
                    <TextField
                      type="number"
                      // InputProps={{
                      //   inputProps: {
                      //     max: 1000,
                      //     min: 0,
                      //   },
                      // }}
                      error={errors.composite_thickness ? true : false}
                      id="composite_thickness"
                      value={form.composite_thickness}
                      onChange={(e) => {
                        setField(
                          "composite_thickness",
                          parseFloat(e.target.value)
                        );
                      }}
                      required
                      helperText={errors.composite_thickness}
                      sx={{
                        marginTop: "0px",
                        marginBottom: "0px",
                      }}
                    />
                    <span className="modal-add-composit_body_form_control-label-small_units">
                      (mm)
                    </span>
                  </div>
                }
                label={"Thickness of the composite"}
                labelPlacement="start"
                value="composite_thickness"
              />
            </div>
            <div className="modal-add-composit_body_form_wrap-cols_col2">
              <FormControlLabel
                className="modal-add-composit_body_form_control-label-small"
                control={
                  <div className="modal-add-composit_body_form_control-label-small_wrap-field">
                    <TextField
                      type="number"
                      // InputProps={{
                      //   inputProps: {
                      //     max: 1000,
                      //     min: 0,
                      //   },
                      // }}
                      error={errors.alpha_a ? true : false}
                      id="alpha_a"
                      value={form.alpha_a}
                      onChange={(e) => {
                        setField("alpha_a", parseFloat(e.target.value));
                      }}
                      required
                      helperText={errors.alpha_a}
                      sx={{
                        marginTop: "0px",
                        marginBottom: "0px",
                      }}
                    />
                    <span className="modal-add-composit_body_form_control-label-small_units">
                      (Mpa)
                    </span>
                  </div>
                }
                label={
                  "Alpha_a : Repair laminate thermal expansion coefficient, axial direction (10-6 x mm/mm.C)"
                }
                labelPlacement="start"
                value="alpha_a"
              />

              <FormControlLabel
                className="modal-add-composit_body_form_control-label-small"
                control={
                  <div className="modal-add-composit_body_form_control-label-small_wrap-field">
                    <TextField
                      type="number"
                      // InputProps={{
                      //   inputProps: {
                      //     max: 1000,
                      //     min: 0,
                      //   },
                      // }}
                      error={errors.alpha_c ? true : false}
                      id="alpha_c"
                      value={form.alpha_c}
                      onChange={(e) => {
                        setField("alpha_c", parseFloat(e.target.value));
                      }}
                      required
                      helperText={errors.alpha_c}
                      sx={{
                        marginTop: "0px",
                        marginBottom: "0px",
                      }}
                    />
                    <span className="modal-add-composit_body_form_control-label-small_units">
                      (Mpa)
                    </span>
                  </div>
                }
                label={
                  "Alpha_c : Repair laminate thermal expansion coefficient, circumferential direction (10-6 x mm/mm.C)"
                }
                labelPlacement="start"
                value="alpha_c"
              />

              <FormControlLabel
                className="modal-add-composit_body_form_control-label-small"
                control={
                  <div className="modal-add-composit_body_form_control-label-small_wrap-field">
                    <TextField
                      type="number"
                      // InputProps={{
                      //   inputProps: {
                      //     max: 1000,
                      //     min: 0,
                      //   },
                      // }}
                      error={errors.epsilon_lt ? true : false}
                      id="alpha_c"
                      value={form.epsilon_lt}
                      onChange={(e) => {
                        setField("epsilon_lt", parseFloat(e.target.value));
                      }}
                      required
                      helperText={errors.epsilon_lt}
                      sx={{
                        marginTop: "0px",
                        marginBottom: "0px",
                      }}
                    />
                    <span className="modal-add-composit_body_form_control-label-small_units" />
                  </div>
                }
                label={
                  "Epsilonlt : lower confidence limit of the long-term strain determined by performance testing	"
                }
                labelPlacement="start"
                value="epsilon_lt"
              />

              <FormControlLabel
                className="modal-add-composit_body_form_control-label-small"
                control={
                  <div className="modal-add-composit_body_form_control-label-small_wrap-field">
                    <TextField
                      type="number"
                      // InputProps={{
                      //   inputProps: {
                      //     max: 1000,
                      //     min: 0,
                      //   },
                      // }}
                      error={errors.sigma_lt ? true : false}
                      id="sigma_lt"
                      value={form.sigma_lt}
                      onChange={(e) => {
                        setField("sigma_lt", parseFloat(e.target.value));
                      }}
                      required
                      helperText={errors.sigma_lt}
                      sx={{
                        marginTop: "0px",
                        marginBottom: "0px",
                      }}
                    />
                    <span className="modal-add-composit_body_form_control-label-small_units">
                      (N/m2)
                    </span>
                  </div>
                }
                label={
                  "Slt : Lower confidence limit of the long-term strength determined by performance testing"
                }
                labelPlacement="start"
                value="sigma_lt"
              />

              <FormControlLabel
                className="modal-add-composit_body_form_control-label-small"
                control={
                  <div className="modal-add-composit_body_form_control-label-small_wrap-field">
                    <TextField
                      type="number"
                      // InputProps={{
                      //   inputProps: {
                      //     max: 1000,
                      //     min: 0,
                      //   },
                      // }}
                      error={errors.g_lcl_1 ? true : false}
                      id="g_lcl_1"
                      value={form.g_lcl_1}
                      onChange={(e) => {
                        setField("g_lcl_1", parseFloat(e.target.value));
                      }}
                      required
                      helperText={errors.g_lcl_1}
                      sx={{
                        marginTop: "0px",
                        marginBottom: "0px",
                      }}
                    />
                    <span className="modal-add-composit_body_form_control-label-small_units">
                      (J/m2)
                    </span>
                  </div>
                }
                label={"Gamma_lcl1: Energy release rate"}
                labelPlacement="start"
                value="g_lcl_1"
              />

              <FormControlLabel
                className="modal-add-composit_body_form_control-label-small"
                control={
                  <div className="modal-add-composit_body_form_control-label-small_wrap-field">
                    <TextField
                      type="number"
                      // InputProps={{
                      //   inputProps: {
                      //     max: 1000,
                      //     min: 0,
                      //   },
                      // }}
                      error={errors.g_lcl_2 ? true : false}
                      id="g_lcl_2"
                      value={form.g_lcl_2}
                      onChange={(e) => {
                        setField("g_lcl_2", parseFloat(e.target.value));
                      }}
                      required
                      helperText={errors.g_lcl_2}
                      sx={{
                        marginTop: "0px",
                        marginBottom: "0px",
                      }}
                    />
                    <span className="modal-add-composit_body_form_control-label-small_units">
                      (J/m2)
                    </span>
                  </div>
                }
                label={"Gamma_lcl2: Energy release rate"}
                labelPlacement="start"
                value="g_lcl_2"
              />

              <FormControlLabel
                className="modal-add-composit_body_form_control-label-small"
                control={
                  <div className="modal-add-composit_body_form_control-label-small_wrap-field">
                    <TextField
                      type="number"
                      // InputProps={{
                      //   inputProps: {
                      //     max: 1000,
                      //     min: 0,
                      //   },
                      // }}
                      error={errors.g_lcl_3 ? true : false}
                      id="g_lcl_3"
                      value={form.g_lcl_3}
                      onChange={(e) => {
                        setField("g_lcl_3", parseFloat(e.target.value));
                      }}
                      required
                      helperText={errors.g_lcl_3}
                      sx={{
                        marginTop: "0px",
                        marginBottom: "0px",
                      }}
                    />
                    <span className="modal-add-composit_body_form_control-label-small_units">
                      (J/m2)
                    </span>
                  </div>
                }
                label={"Gamma_lcl3: Energy release rate"}
                labelPlacement="start"
                value="g_lcl_3"
              />

              <FormControlLabel
                className="modal-add-composit_body_form_control-label-small"
                control={
                  <div className="modal-add-composit_body_form_control-label-small_wrap-field">
                    <TextField
                      type="number"
                      // InputProps={{
                      //   inputProps: {
                      //     max: 1000,
                      //     min: 0,
                      //   },
                      // }}
                      error={errors.tau ? true : false}
                      id="tau"
                      value={form.tau}
                      onChange={(e) => {
                        setField("tau", parseFloat(e.target.value));
                      }}
                      required
                      helperText={errors.tau}
                      sx={{
                        marginTop: "0px",
                        marginBottom: "0px",
                      }}
                    />
                    <span className="modal-add-composit_body_form_control-label-small_units">
                      (Mpa)
                    </span>
                  </div>
                }
                label={"Tau : Lap shear strength"}
                labelPlacement="start"
                value="tau"
              />

              <FormControlLabel
                className="modal-add-composit_body_form_control-label-small"
                control={
                  <div className="modal-add-composit_body_form_control-label-small_wrap-field">
                    <TextField
                      type="number"
                      // InputProps={{
                      //   inputProps: {
                      //     max: 1000,
                      //     min: 0,
                      //   },
                      // }}
                      error={errors.nb_resin_tape ? true : false}
                      id="nb_resin_tape"
                      value={form.nb_resin_tape}
                      onChange={(e) => {
                        setField("nb_resin_tape", parseFloat(e.target.value));
                      }}
                      required
                      helperText={errors.nb_resin_tape}
                      sx={{
                        marginTop: "0px",
                        marginBottom: "0px",
                      }}
                    />
                    <span className="modal-add-composit_body_form_control-label-small_units" />
                  </div>
                }
                label={"Number of buckets of resin per tape"}
                labelPlacement="start"
                value="nb_resin_tape"
              />

              <FormControlLabel
                className="modal-add-composit_body_form_control-label-small"
                control={
                  <div className="modal-add-composit_body_form_control-label-small_wrap-field">
                    <TextField
                      // error={errors.color_hexadecimal ? true : false}
                      id="color_hexadecimal"
                      value={form.color_hexadecimal}
                      onChange={(e) => {
                        setField("color_hexadecimal", e.target.value);
                      }}
                      required
                      // helperText={errors.color_hexadecimal}
                      sx={{
                        marginTop: "0px",
                        marginBottom: "0px",
                      }}
                    />
                    <span className="modal-add-composit_body_form_control-label-small_units" />
                  </div>
                }
                label={"Color hexadecimal"}
                labelPlacement="start"
                value="color_hexadecimal"
              />

              <FormControlLabel
                className="modal-add-composit_body_form_control-label-small"
                control={
                  <div className="modal-add-composit_body_form_control-label-small_wrap-field">
                    <TextField
                      // error={errors.color_hexadecimal ? true : false}
                      id="performance_data"
                      value={form.performance_data}
                      onChange={(e) => {
                        setField("performance_data", e.target.value);
                      }}
                      required
                      // helperText={errors.performance_data}
                      sx={{
                        marginTop: "0px",
                        marginBottom: "0px",
                      }}
                    />
                    <span className="modal-add-composit_body_form_control-label-small_units" />
                  </div>
                }
                label={"Performance data"}
                labelPlacement="start"
                value="performance_data"
              />
            </div>
          </div>

          <div className="modal-add-composit_body_form_wrap-btns">
            <Button
              type={TypesButton["modal-outline"]}
              handleClick={() => handleCloseModal()}
              text="Cancel"
            />
            <Button
              type={TypesButton.modal}
              text="Save"
              handleClick={() => handleSave()}
            />
          </div>
        </form>
        {isCreatedProduct && (
          <div className="modal-add-composit_body_form_success-text">
            Composit successfully created
          </div>
        )}
        {isEditedProduct && composit !== null && (
          <div className="modal-add-composit_body_form_success-text">
            Composit successfully edited
          </div>
        )}
        {error !== null && (
          <div className="modal-add-composit_body_form_error-text">{error}</div>
        )}
      </DialogContent>
    </BootstrapDialog>
  );
};

export default ModalAddComposit;
