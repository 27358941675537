import { menuRoutes, menuRoutesTech } from "constants/";

import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";

import { useAppSelector } from "app/hook";

import "./main-menu.scss";

const item = {
  fontFamily: "Arial",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "18px",
  cursor: "pointer",
  color: "#FFFFFF",
  paddingLeft: "30px",
};

const item_active = {
  fontFamily: "Arial",
  fontWeight: "700",
  fontSize: "18px",
  lineHeight: "21px",
  color: "#55BBBE",
};

const sub_item = {
  fontFamily: "Arial",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "18px",
  cursor: "pointer",
  color: "#2F2C2C",
  paddingLeft: "47px",
  background: "#CBE9EA",
  position: "relative",
  "&::before": {
    // eslint-disable-next-line
    content: `""`,
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    height: "1px",
    background: "#55BBBE",
  },
};

const MainMenu = () => {
  const { accountType } = useAppSelector((state) => state.loginUser);

  const location = useLocation();
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState("/");

  useEffect(() => {
    if (accountType === "ADMINISTRATOR") {
      menuRoutes.includes(location.pathname)
        ? setActiveKey(location.pathname)
        : setActiveKey("/");
    } else {
      menuRoutesTech.includes(location.pathname)
        ? setActiveKey(location.pathname)
        : setActiveKey("/filler");
    }
  }, [accountType, location]);

  return (
    <Paper
      className="main-menu-wrap"
      sx={{
        borderRadius: "0",
        boxShadow: "none",
      }}
    >
      {accountType === "ADMINISTRATOR" && (
        <MenuList
          className="main-menu"
          sx={{
            paddingTop: "0px",
            paddingBottom: "0px",
          }}
        >
          <MenuItem
            sx={
              activeKey === "/"
                ? { ...item, ...item_active }
                : { ...item, background: "#64C4C8" }
            }
            onClick={() => {
              setActiveKey("/");
              navigate("/");
            }}
          >
            Users management
          </MenuItem>
          <MenuItem
            sx={
              activeKey === "/2" ||
              activeKey === "/filler" ||
              activeKey === "/primer" ||
              activeKey === "/composit" ||
              activeKey === "/history"
                ? { ...item, ...item_active }
                : {
                    ...item,
                    background: activeKey === "/" ? "#64C4C8" : "#4EB9BD",
                  }
            }
            onClick={() => setActiveKey("/2")}
          >
            Products management
          </MenuItem>
          {(activeKey === "/2" ||
            activeKey === "/filler" ||
            activeKey === "/primer" ||
            activeKey === "/composit" ||
            activeKey === "/history") && (
            <MenuList
              className="sub-menu"
              sx={{
                paddingTop: "0px",
                paddingBottom: "0px",
              }}
            >
              <MenuItem
                sx={sub_item}
                onClick={() => {
                  setActiveKey("/filler");
                  navigate("/filler");
                }}
              >
                {activeKey === "/filler" ? (
                  <span className="active-sub-item">Filler</span>
                ) : (
                  "Filler"
                )}
              </MenuItem>
              <MenuItem
                sx={sub_item}
                onClick={() => {
                  setActiveKey("/primer");
                  navigate("/primer");
                }}
              >
                {activeKey === "/primer" ? (
                  <span className="active-sub-item">Primer</span>
                ) : (
                  "Primer"
                )}
              </MenuItem>
              <MenuItem
                sx={sub_item}
                onClick={() => {
                  setActiveKey("/composit");
                  navigate("/composit");
                }}
              >
                {activeKey === "/composit" ? (
                  <span className="active-sub-item">Composite</span>
                ) : (
                  "Composite"
                )}
              </MenuItem>
              <MenuItem
                sx={sub_item}
                onClick={() => {
                  setActiveKey("/history");
                  navigate("/history");
                }}
              >
                {activeKey === "/history" ? (
                  <span className="active-sub-item">History of changes</span>
                ) : (
                  "History of products"
                )}
              </MenuItem>
            </MenuList>
          )}
          <MenuItem
            sx={
              activeKey === "/content"
                ? { ...item, ...item_active }
                : {
                    ...item,
                    background:
                      activeKey === "/" || activeKey === "/2"
                        ? "#4EB9BD"
                        : "#32A5A6",
                  }
            }
            onClick={() => {
              setActiveKey("/content");
              navigate("/content");
            }}
          >
            Content management
          </MenuItem>
          <MenuItem
            sx={
              activeKey === "/projects" || activeKey === "/proposals"
                ? { ...item, ...item_active }
                : {
                    ...item,
                    background: activeKey === "/5" ? "#00ABAC" : "#32A5A6",
                  }
            }
            onClick={() => {
              setActiveKey("/projects");
              navigate("/projects");
            }}
          >
            Technical proposals and
            <br />
            projects of the distributor
          </MenuItem>
          <MenuItem
            sx={
              activeKey === "/5" ||
              activeKey === "/general" ||
              activeKey === "/technical" ||
              activeKey === "/marketing"
                ? { ...item, ...item_active }
                : {
                    ...item,
                    background: "#088080",
                  }
            }
            onClick={() => {
              setActiveKey("/5");
            }}
          >
            Statistics
          </MenuItem>
          {(activeKey === "/5" ||
            activeKey === "/general" ||
            activeKey === "/technical" ||
            activeKey === "/marketing") && (
            <MenuList
              className="sub-menu"
              sx={{
                paddingTop: "0px",
                paddingBottom: "0px",
              }}
            >
              <MenuItem
                sx={sub_item}
                onClick={() => {
                  setActiveKey("/general");
                  navigate("/general");
                }}
              >
                {activeKey === "/general" ? (
                  <span className="active-sub-item">General</span>
                ) : (
                  "General"
                )}
              </MenuItem>
              <MenuItem
                sx={sub_item}
                onClick={() => {
                  setActiveKey("/technical");
                  navigate("/technical");
                }}
              >
                {activeKey === "/technical" ? (
                  <span className="active-sub-item">Technical</span>
                ) : (
                  "Technical"
                )}
              </MenuItem>
              <MenuItem
                sx={sub_item}
                onClick={() => {
                  setActiveKey("/marketing");
                  navigate("/marketing");
                }}
              >
                {activeKey === "/marketing" ? (
                  <span className="active-sub-item">Marketing</span>
                ) : (
                  "Marketing"
                )}
              </MenuItem>
            </MenuList>
          )}
        </MenuList>
      )}
      {accountType === "TECHNICAL_ADMINISTRATOR" && (
        <MenuList
          className="main-menu"
          sx={{
            paddingTop: "0px",
            paddingBottom: "0px",
          }}
        >
          <MenuItem
            sx={
              activeKey === "/2" ||
              activeKey === "/filler" ||
              activeKey === "/primer" ||
              activeKey === "/composit" ||
              activeKey === "/history"
                ? { ...item, ...item_active }
                : { ...item, background: "#64C4C8" }
            }
            onClick={() => setActiveKey("/2")}
          >
            Products management
          </MenuItem>
          {(activeKey === "/2" ||
            activeKey === "/filler" ||
            activeKey === "/primer" ||
            activeKey === "/composit" ||
            activeKey === "/history") && (
            <MenuList
              className="sub-menu"
              sx={{
                paddingTop: "0px",
                paddingBottom: "0px",
              }}
            >
              <MenuItem
                sx={sub_item}
                onClick={() => {
                  setActiveKey("/filler");
                  navigate("/filler");
                }}
              >
                {activeKey === "/filler" ? (
                  <span className="active-sub-item">Filler</span>
                ) : (
                  "Filler"
                )}
              </MenuItem>
              <MenuItem
                sx={sub_item}
                onClick={() => {
                  setActiveKey("/primer");
                  navigate("/primer");
                }}
              >
                {activeKey === "/primer" ? (
                  <span className="active-sub-item">Primer</span>
                ) : (
                  "Primer"
                )}
              </MenuItem>
              <MenuItem
                sx={sub_item}
                onClick={() => {
                  setActiveKey("/composit");
                  navigate("/composit");
                }}
              >
                {activeKey === "/composit" ? (
                  <span className="active-sub-item">Composite</span>
                ) : (
                  "Composite"
                )}
              </MenuItem>
              <MenuItem
                sx={sub_item}
                onClick={() => {
                  setActiveKey("/history");
                  navigate("/history");
                }}
              >
                {activeKey === "/history" ? (
                  <span className="active-sub-item">History of changes</span>
                ) : (
                  "History of products"
                )}
              </MenuItem>
            </MenuList>
          )}
          <MenuItem
            sx={
              activeKey === "/5" ||
              activeKey === "/general" ||
              activeKey === "/technical" ||
              activeKey === "/marketing"
                ? { ...item, ...item_active }
                : {
                    ...item,
                    background: activeKey === "/" ? "#64C4C8" : "#4EB9BD",
                  }
            }
            onClick={() => {
              setActiveKey("/5");
            }}
          >
            Statistics
          </MenuItem>
          {(activeKey === "/5" ||
            activeKey === "/general" ||
            activeKey === "/technical" ||
            activeKey === "/marketing") && (
            <MenuList
              className="sub-menu"
              sx={{
                paddingTop: "0px",
                paddingBottom: "0px",
              }}
            >
              <MenuItem
                sx={sub_item}
                onClick={() => {
                  setActiveKey("/general");
                  navigate("/general");
                }}
              >
                {activeKey === "/general" ? (
                  <span className="active-sub-item">General</span>
                ) : (
                  "General"
                )}
              </MenuItem>
              <MenuItem
                sx={sub_item}
                onClick={() => {
                  setActiveKey("/technical");
                  navigate("/technical");
                }}
              >
                {activeKey === "/technical" ? (
                  <span className="active-sub-item">Technical</span>
                ) : (
                  "Technical"
                )}
              </MenuItem>
              <MenuItem
                sx={sub_item}
                onClick={() => {
                  setActiveKey("/marketing");
                  navigate("/marketing");
                }}
              >
                {activeKey === "/marketing" ? (
                  <span className="active-sub-item">Marketing</span>
                ) : (
                  "Marketing"
                )}
              </MenuItem>
            </MenuList>
          )}
        </MenuList>
      )}
    </Paper>
  );
};

export default MainMenu;
