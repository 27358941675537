type ISvgProps = {
  isHover?: boolean;
};

const HistorySvg = ({ isHover = false }: ISvgProps) => {
  if (isHover) {
    return (
      <svg
        className="hover-on"
        width="17"
        height="14"
        viewBox="0 0 17 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.75 0.25C6.0225 0.25 3 3.2725 3 7H0.75L3.6675 9.9175L3.72 10.0225L6.75 7H4.5C4.5 4.0975
            6.8475 1.75 9.75 1.75C12.6525 1.75 15 4.0975 15 7C15 9.9025 12.6525 12.25 9.75 12.25C8.3025
            12.25 6.99 11.6575 6.045 10.705L4.98 11.77C6.2025 12.9925 7.8825 13.75 9.75 13.75C13.4775
            13.75 16.5 10.7275 16.5 7C16.5 3.2725 13.4775 0.25 9.75 0.25ZM9 4V7.75L12.1875 9.64L12.765
            8.68L10.125 7.1125V4H9Z"
          fill="#3B61A5"
        />
      </svg>
    );
  }
  return (
    <svg
      className="hover-off"
      width="17"
      height="14"
      viewBox="0 0 17 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.75 0.25C6.0225 0.25 3 3.2725 3 7H0.75L3.6675 9.9175L3.72 10.0225L6.75 7H4.5C4.5 4.0975
        6.8475 1.75 9.75 1.75C12.6525 1.75 15 4.0975 15 7C15 9.9025 12.6525 12.25 9.75 12.25C8.3025
        12.25 6.99 11.6575 6.045 10.705L4.98 11.77C6.2025 12.9925 7.8825 13.75 9.75 13.75C13.4775
        13.75 16.5 10.7275 16.5 7C16.5 3.2725 13.4775 0.25 9.75 0.25ZM9 4V7.75L12.1875 9.64L12.765
        8.68L10.125 7.1125V4H9Z"
        fill="#94A1A2"
      />
    </svg>
  );
};

export default HistorySvg;
