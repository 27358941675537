type ISvgProps = {
    isHover?: boolean;
  };
  
const EditSvg = ({ isHover = false }: ISvgProps) => {
  if (isHover) {
    return (
      <svg
        className="hover-on"
        width="17" height="17"
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M15.545 13.7307C15.0077 13.7307 14.5735 14.165 14.5735 14.7022V17.7539C14.5735 18.2903
          14.1382 18.7255 13.6019 18.7255H2.91468C2.37836 18.7255 1.94313 18.2902 1.94313 17.7539V5.12362C1.94313
          4.5873 2.3784 4.15208 2.91468 4.15208H8.55946C9.09672 4.15208 9.531 3.71779 9.531 3.18053C9.531 2.64327
          9.09672 2.20898 8.55946 2.20898H2.91468C1.30771 2.20891 0 3.51666 0 5.12362V17.7539C0 19.3609 1.30771
          20.6686 2.91468 20.6686H13.6019C15.2088 20.6686 16.5165 19.3619 16.5165 17.7539V14.7023C16.5166
          14.165 16.0823 13.7307 15.545 13.7307Z" fill="#3B61A5"
        />
        <path d="M18.9149 2.08519C18.2096 1.37985 17.2885 1.02423 16.3607 1.00482C15.3842 0.983452 14.402 1.33708
          13.6636 2.07644L7.08611 8.65394C6.1262 9.61775 5.59766 10.8963 5.59766 12.2545V14.4308C5.59766 14.9681
          6.03195 15.4024 6.5692 15.4024H8.74549C10.1038 15.4024 11.3823 14.8738 12.3471 13.913L18.9158
          7.3442C20.3635 5.89567 20.3635 3.53477 18.9149 2.08519ZM10.9743 12.5383C10.3787 13.1319 9.58595 13.4593
          8.74557 13.4593H7.54079V12.2546C7.54079 11.4142 7.8682 10.6214 8.46085 10.0268L12.7969 5.69067L15.3094
          8.20313L10.9743 12.5383ZM17.5411 5.97145L16.6832 6.82936L14.1708 4.31691L15.0286 3.45903C15.7223 2.76533
          16.8493 2.76729 17.5411 3.45903C18.2329 4.15172 18.2329 5.27873 17.5411 5.97145Z" fill="#3B61A5"
        />
      </svg>
    );
  };
  return (
    <svg
      className="hover-off"
      width="17" height="17"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15.545 13.7307C15.0077 13.7307 14.5735 14.165 14.5735 14.7022V17.7539C14.5735 18.2903 14.1382 18.7255
        13.6019 18.7255H2.91468C2.37836 18.7255 1.94313 18.2902 1.94313 17.7539V5.12362C1.94313 4.5873 2.3784 4.15208
        2.91468 4.15208H8.55946C9.09672 4.15208 9.531 3.71779 9.531 3.18053C9.531 2.64327 9.09672 2.20898 8.55946
        2.20898H2.91468C1.30771 2.20891 0 3.51666 0 5.12362V17.7539C0 19.3609 1.30771 20.6686 2.91468
        20.6686H13.6019C15.2088 20.6686 16.5165 19.3619 16.5165 17.7539V14.7023C16.5166 14.165 16.0823 13.7307
        15.545 13.7307Z" fill="#94A1A2"
      />
      <path d="M18.9149 2.08519C18.2096 1.37985 17.2885 1.02423 16.3607 1.00482C15.3842 0.983452 14.402 1.33708
        13.6636 2.07644L7.08611 8.65394C6.1262 9.61775 5.59766 10.8963 5.59766 12.2545V14.4308C5.59766 14.9681
        6.03195 15.4024 6.5692 15.4024H8.74549C10.1038 15.4024 11.3823 14.8738 12.3471 13.913L18.9158
        7.3442C20.3635 5.89567 20.3635 3.53477 18.9149 2.08519ZM10.9743 12.5383C10.3787 13.1319 9.58595 13.4593
        8.74557 13.4593H7.54079V12.2546C7.54079 11.4142 7.8682 10.6214 8.46085 10.0268L12.7969 5.69067L15.3094
        8.20313L10.9743 12.5383ZM17.5411 5.97145L16.6832 6.82936L14.1708 4.31691L15.0286 3.45903C15.7223 2.76533
        16.8493 2.76729 17.5411 3.45903C18.2329 4.15172 18.2329 5.27873 17.5411 5.97145Z" fill="#94A1A2"
      />
    </svg>
  );
};

export default EditSvg;
