import { Statistics } from "features/statistics";

const Technical = () => {
  return (
    <div>
      <Statistics type="technical" />
    </div>
  );
};

export default Technical;
