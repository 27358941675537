import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getFetchProp } from "helpers/getFetchProp";
import { setCookie, getCookie, deleteCookie } from "helpers/cookies";

import { apiUrl } from "../../constants";

export type LoginUserState = {
  accountType: string;
  continent: string;
  email: string;
  error: string | null;
  isSendCode: boolean;
  isSetNewPassword: boolean;
  loading: boolean;
  location: string;
  name: string;
  phone: string;
  refreshToken: string;
  roles: string[];
  token: string;
  userId: string;
  saleManagerName: null | string;
};

type LoginUserInput = {
  email: string;
  password: string;
};

type ChangePasswordInput = {
  code: string;
  email: string;
  password: string;
};

const initialState: LoginUserState = {
  accountType: "",
  continent: "",
  email: "",
  error: null,
  isSendCode: false,
  isSetNewPassword: false,
  loading: false,
  location: "",
  name: "",
  phone: "",
  refreshToken: getCookie("refresh_token") || "",
  roles: [""],
  token: getCookie("token") || "",
  userId: "",
  saleManagerName: null,
};

export const authLogin = createAsyncThunk(
  "loginAdminPanel",
  async (
    { email, password }: LoginUserInput,
    { rejectWithValue, dispatch, getState }
  ) => {
    const res = await fetch(`${apiUrl}/rea/v1.0/api/auth/login_admin`, {
      ...getFetchProp({
        method: "POST",
        contentType: "application/json",
        body: JSON.stringify({
          email: `${email}`,
          password: `${password}`,
        }),
      }),
    });

    return res.json();
  }
);

export const authLogout = createAsyncThunk(
  "logoutUser",
  async (_, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    const res = await fetch(`${apiUrl}/rea/v1.0/api/auth/logout`, {
      ...getFetchProp({
        method: "POST",
        token: loginUser.token,
      }),
    });

    return new Promise<void>((resolve, reject) => {
      if (res.ok && res.status === 200) {
        resolve();
      } else {
        reject(new Error("error"));
      }
    });
  }
);

export const authRestore = createAsyncThunk(
  "sendRestoreCode",
  async (email: string) => {
    const res = await fetch(
      `${apiUrl}/rea/v1.0/api/auth/restore_code?mail=${email}`,
      {
        ...getFetchProp({
          method: "POST",
        }),
      }
    );

    return new Promise<void>((resolve, reject) => {
      if (res.ok && res.status === 200) {
        resolve();
      } else {
        reject(new Error("error"));
      }
    });
  }
);

export const authChangePassword = createAsyncThunk(
  "change password",
  async (
    { code, email, password }: ChangePasswordInput,
    { rejectWithValue, dispatch, getState }
  ) => {
    const res = await fetch(
      `${apiUrl}/rea/v1.0/api/auth/change_password?code=${code}`,
      {
        ...getFetchProp({
          method: "POST",
          contentType: "application/json",
          body: JSON.stringify({
            email: `${email}`,
            password: `${password}`,
          }),
        }),
      }
    );

    return new Promise<void>((resolve, reject) => {
      if (res.ok && res.status === 200) {
        resolve();
      } else {
        reject(new Error("error"));
      }
    });
  }
);

export const authRefreshToken = createAsyncThunk(
  "refresh token",
  async (_, { rejectWithValue, dispatch, getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    const res = await fetch(`${apiUrl}/rea/v1.0/api/auth/refresh_token`, {
      ...getFetchProp({
        method: "POST",
        token: loginUser.refreshToken,
      }),
    });

    return new Promise<any>((resolve, reject) => {
      if (res.ok && res.status === 200) {
        resolve(res.json());
      } else {
        reject(new Error("error"));
      }
    });
  }
);

const loginUserSlice = createSlice({
  name: "login user",
  initialState,
  reducers: {
    cleanError(state) {
      state.error = null;
    },
    resetSendCode(state) {
      state.isSendCode = false;
      state.isSetNewPassword = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authLogin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(authLogin.fulfilled, (state, action) => {
        if (
          action.payload.status !== 401 &&
          (action.payload.account_type === "ADMINISTRATOR" ||
            action.payload.account_type === "TECHNICAL_ADMINISTRATOR")
        ) {
          setCookie("token", action.payload.token, { "max-age": 43200 });
          setCookie("refresh_token", action.payload.refresh_token, {
            "max-age": 7884000,
          });
          state.accountType = action.payload.account_type;
          state.continent = action.payload.continent;
          state.email = action.payload.email;
          state.loading = false;
          state.error = null;
          state.location = action.payload.location;
          state.name = action.payload.name;
          state.phone = action.payload.phone;
          state.refreshToken = action.payload.refresh_token;
          state.roles = action.payload.roles;
          state.token = action.payload.token;
          state.userId = action.payload.user_id;
          state.saleManagerName = action.payload.sale_manager_name;
        }

        if (action.payload.status === 401) {
          state.loading = false;
          state.error = action.payload.error;
        }
      })
      .addCase(authLogin.rejected, (state) => {
        state.loading = false;
        state.error = "Login or password incorrect!";
      })
      .addCase(authLogout.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(authLogout.fulfilled, (state) => {
        deleteCookie("token");
        deleteCookie("refresh_token");
        state.accountType = "";
        state.continent = "";
        state.email = "";
        state.error = null;
        state.isSetNewPassword = false;
        state.loading = false;
        state.location = "";
        state.name = "";
        state.phone = "";
        state.refreshToken = "";
        state.roles = [""];
        state.token = "";
        state.userId = "";
        state.saleManagerName = null;
      })
      .addCase(authLogout.rejected, (state) => {
        deleteCookie("token");
        deleteCookie("refresh_token");
        state.accountType = "";
        state.continent = "";
        state.email = "";
        state.error = "Logout incorrect!";
        state.isSetNewPassword = false;
        state.loading = false;
        state.location = "";
        state.name = "";
        state.phone = "";
        state.refreshToken = "";
        state.roles = [""];
        state.token = "";
        state.userId = "";
        state.saleManagerName = null;
      })
      .addCase(authRestore.pending, (state) => {
        state.isSendCode = false;
        state.loading = true;
        state.error = null;
      })
      .addCase(authRestore.fulfilled, (state, action) => {
        state.isSendCode = true;
        state.loading = false;
        state.error = null;
      })
      .addCase(authRestore.rejected, (state) => {
        state.loading = false;
        state.error = "Login incorrect!";
      })
      .addCase(authChangePassword.pending, (state) => {
        state.isSetNewPassword = false;
        state.loading = true;
        state.error = null;
      })
      .addCase(authChangePassword.fulfilled, (state, action) => {
        state.isSetNewPassword = true;
        state.loading = false;
        state.error = null;
      })
      .addCase(authChangePassword.rejected, (state) => {
        state.loading = false;
        state.error = "Error! Change password";
      })
      .addCase(authRefreshToken.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(authRefreshToken.fulfilled, (state, action) => {
        if (
          action.payload.status !== 401 &&
          (action.payload.account_type === "ADMINISTRATOR" ||
            action.payload.account_type === "TECHNICAL_ADMINISTRATOR")
        ) {
          setCookie("token", action.payload.token, { "max-age": 43200 });
          setCookie("refresh_token", action.payload.refresh_token, {
            "max-age": 7884000,
          });
          state.accountType = action.payload.account_type;
          state.continent = action.payload.continent;
          state.email = action.payload.email;
          state.loading = false;
          state.error = null;
          state.location = action.payload.location;
          state.name = action.payload.name;
          state.phone = action.payload.phone;
          state.refreshToken = action.payload.refresh_token;
          state.roles = action.payload.roles;
          state.token = action.payload.token;
          state.userId = action.payload.user_id;
          state.saleManagerName = action.payload.sale_manager_name;
        }

        if (
          action.payload.status === 401 ||
          action.payload.account_type !== "ADMINISTRATOR"
        ) {
          deleteCookie("token");
          deleteCookie("refresh_token");
          state.token = "";
          state.refreshToken = "";
          state.accountType = "";
          state.continent = "";
          state.email = "";
          state.error = action.payload.error || "";
          state.isSetNewPassword = false;
          state.loading = false;
          state.location = "";
          state.name = "";
          state.phone = "";
          state.roles = [""];
          state.userId = "";
          state.saleManagerName = null;
          state.loading = false;
        }
      })
      .addCase(authRefreshToken.rejected, (state) => {
        deleteCookie("token");
        deleteCookie("refresh_token");
        state.token = "";
        state.refreshToken = "";
        state.loading = false;
        // state.error = "Error! Refresh token";
      });
  },
});

export const { cleanError, resetSendCode } = loginUserSlice.actions;

export default loginUserSlice.reducer;
