import { useState } from "react";

import { useAppDispatch, useAppSelector } from "app/hook";

import {
  authChangePassword,
  authLogin,
  authRestore,
  cleanError,
} from "./auth-slice";

import ModalLogin from "./modal-login";

type LoginErrors = {
  email?: string;
  password?: string;
  newPassword?: string;
  confirmPassword?: string;
  code?: string;
};

const Auth = () => {
  const loginUser = useAppSelector((state) => state.loginUser);
  const [form, setForm] = useState({ email: loginUser.email, password: "" });
  const [errors, setErrors] = useState<LoginErrors>({});
  const [isRestore, setIsRestore] = useState(false);
  const [isRestoreSend, setIsRestoreSend] = useState(false);

  if (
    loginUser.error !== null &&
    !isRestore &&
    !isRestoreSend &&
    errors.password === undefined
  ) {
    setErrors({ ...errors, password: loginUser.error });
  }

  if (
    loginUser.error !== null &&
    isRestoreSend &&
    loginUser.isSendCode &&
    errors.confirmPassword === undefined
  ) {
    setErrors({ confirmPassword: loginUser.error });
  }

  if (
    loginUser.error !== null &&
    isRestoreSend &&
    !loginUser.isSendCode &&
    errors.email === undefined
  ) {
    setErrors({ email: loginUser.error });
  }

  const dispatch = useAppDispatch();

  const cleanErrors = () => {
    setErrors({});
    dispatch(cleanError());
  };

  const sendLogin = ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    setForm({ email, password });

    const formErrors = validateForm({ email, password });

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      setErrors({});
      dispatch(authLogin({ email, password }));
    }
  };

  const sendRestore = ({ email }: { email: string }) => {
    setForm({ email, password: form.password });

    const formErrors = validateForm({ email });

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      setErrors({});
      dispatch(authRestore(email));
      setIsRestoreSend(true);
    }
  };

  const sendSave = ({
    code,
    email,
    newPassword,
    confirmPassword,
  }: {
    code: string;
    email: string;
    newPassword: string;
    confirmPassword: string;
  }) => {
    const formErrors = validateFormNewPassword({
      code,
      newPassword,
      confirmPassword,
    });

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      setErrors({});
      dispatch(authChangePassword({ code, email, password: newPassword }));
    }
  };

  const validateForm = ({
    email,
    password,
  }: {
    email: string;
    password?: string;
  }) => {
    const newErrors: LoginErrors = {};

    if (email === "") newErrors.email = "Please enter email";
    else {
      const check = email.toLowerCase().match(
        // eslint-disable-next-line max-len
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      if (check === null) newErrors.email = "Please enter email";
    }
    if (password !== undefined && password === "")
      newErrors.password = "Please enter password";

    return newErrors;
  };

  const validateFormNewPassword = ({
    code,
    newPassword,
    confirmPassword,
  }: {
    code: string;
    newPassword: string;
    confirmPassword: string;
  }) => {
    const newErrors: LoginErrors = {};

    if (code === "") newErrors.code = "Please enter code";
    if (newPassword === "") newErrors.newPassword = "Please enter new password";
    if (confirmPassword === "")
      newErrors.confirmPassword = "Please enter confirm password";
    if (confirmPassword !== "" && confirmPassword !== newPassword)
      newErrors.confirmPassword = "Confirm password do not match new password";

    return newErrors;
  };

  return (
    <ModalLogin
      errors={errors}
      cleanErrors={cleanErrors}
      form={form}
      loading={loginUser.loading}
      sendLogin={sendLogin}
      sendRestore={sendRestore}
      isRestore={isRestore}
      setIsRestore={setIsRestore}
      isRestoreSend={isRestoreSend}
      setIsRestoreSend={setIsRestoreSend}
      sendSave={sendSave}
      isSetNewPassword={loginUser.isSetNewPassword}
      isSendCode={loginUser.isSendCode}
    />
  );
};

export default Auth;
