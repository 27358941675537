import { ProductsManagement } from "features/products-management";

const Primer = () => {
  return (
    <div>
      <ProductsManagement type="primer" />
    </div>
  );
};

export default Primer;
