import { Statistics } from "features/statistics";

const General = () => {
  return (
    <div>
      <Statistics type="general" />
    </div>
  );
};

export default General;
