import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";

import { useAppDispatch, useAppSelector } from "app/hook";
import { TypesButton } from "types";

import { Button } from "components/library";

import {
  cleanError,
  contentEditMissingInformation,
  getMissingInformation,
  resetEditMissingInformation,
} from "features/content-management/content-management-slice";

import "./missing-information.scss";

type MissingInformationErrors = {
  value?: string;
};

const MissingInformation = () => {
  const { missingText, loading, error, isEditedMissingText } = useAppSelector(
    (state) => state.contentManagement
  );

  const { email, userId } = useAppSelector((state) => state.loginUser);

  const [value, setValue] = useState<string | null>(missingText);
  const [errors, setErrors] = useState<MissingInformationErrors>({});

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (userId !== "" && email !== "" && !loading && missingText === null) {
      dispatch(getMissingInformation());
    }
  }, [dispatch, email, userId, missingText, loading]);

  useEffect(() => {
    if (missingText !== null && value === null && !loading) {
      setValue(missingText);
    }
  }, [missingText, value, loading]);

  const validateForm = () => {
    const newErrors: MissingInformationErrors = {};

    if (!value || value === "") newErrors.value = "Please enter missing text";

    return newErrors;
  };

  const handleCancel = () => {
    setValue(missingText || "");
    dispatch(cleanError());
    dispatch(resetEditMissingInformation());
  };

  const handleSave = () => {
    const formErrors = validateForm();

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      dispatch(cleanError());
      dispatch(resetEditMissingInformation());
      dispatch(contentEditMissingInformation(value || ""));
      setErrors({});
    }
  };

  return (
    <div className="missing-information">
      <form className="missing-information_form" autoComplete="off">
        <TextField
          autoFocus
          multiline
          minRows={10}
          maxRows={10}
          inputProps={{ maxLength: 1000 }}
          id="missing_text"
          value={value || ""}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          required
          error={errors.value ? true : false}
          helperText={errors.value}
          sx={{
            width: "100%",
            marginTop: "0px",
            marginBottom: "0px",
          }}
        />
        <div className="missing-information_form_length">
          {value?.length} / 1000
        </div>
        <div className="missing-information_form_wrap-btns">
          <Button
            type={TypesButton["modal-outline"]}
            handleClick={() => handleCancel()}
            text="Cancel"
          />
          <Button
            type={TypesButton.modal}
            text="Save"
            handleClick={() => handleSave()}
          />
        </div>
        {isEditedMissingText && (
          <div className="missing-information_form_success-text">
            Missing information successfully edited
          </div>
        )}
        {error !== null && (
          <div className="missing-information_form_error-text">{error}</div>
        )}
      </form>
    </div>
  );
};

export default MissingInformation;
