import { useState, useEffect } from "react";
import {
  Button,
  ButtonGroup,
  FormControlLabel,
  MenuItem,
  Box,
  Typography,
  Select,
} from "@mui/material";

import { useAppSelector, useAppDispatch } from "app/hook";

import { TypesButton } from "types";

import { Button as ButtonCustom } from "components/library";

import {
  getGeoZonesStatistic,
  getMarketingStatistic,
  getMarketingStatisticDetails,
  getMarketingStatisticReport,
  getStatuses,
  getUsersStatistic,
} from "./statistics-slice";

import { DoughnutChartMarketing } from "./components/charts";

import "./marketing.scss";

const MarketingStatistic = () => {
  const {
    listUsersStatistic,
    marketingStatistic,
    geoZones,
    statuses,
    marketingStatisticDetails,
  } = useAppSelector((state) => state.statistics);

  const [usersActiveButton, setUsersActiveButton] = useState<string>("ALL");
  const [user, setUser] = useState("");
  const [geoZone, setGeoZone] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [selectedProduct, setSelectedProduct] = useState<string>("");

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (marketingStatistic === null) {
      dispatch(getMarketingStatistic({ userType: "ALL" }));
    }

    if (geoZones.length === 0) {
      dispatch(getGeoZonesStatistic());
    }

    if (statuses.length === 0) {
      dispatch(getStatuses());
    }

    if (listUsersStatistic.length === 0) {
      dispatch(getUsersStatistic());
    }
  }, [
    dispatch,
    marketingStatistic,
    geoZones.length,
    listUsersStatistic.length,
    statuses.length,
  ]);

  const changeUsersActiveButton = (newBtnActive: string) => {
    if (newBtnActive !== usersActiveButton) {
      // dispatch(getMarketingStatistic({ userType: newBtnActive }));
      setUsersActiveButton(newBtnActive);
    }
  };

  const changeSelectProduct = (newProduct: string) => {
    if (newProduct !== selectedProduct) {
      setSelectedProduct(newProduct);

      const params: {
        userType: string;
        compositeType: string;
        geoZone?: string;
        status?: string;
        userId?: string;
      } = {
        userType: usersActiveButton,
        compositeType: newProduct,
      };

      if (geoZone !== "") {
        params.geoZone = geoZone;
      }
      if (status !== "") {
        params.status = status;
      }
      if (user !== "") {
        params.userId = user;
      }

      dispatch(getMarketingStatisticDetails(params));
    }
  };

  const clickApplyBtn = () => {
    const params: {
      userType: string;
      geoZone?: string;
      status?: string;
      userId?: string;
    } = {
      userType: usersActiveButton,
    };

    if (geoZone !== "") {
      params.geoZone = geoZone;
    }
    if (status !== "") {
      params.status = status;
    }
    if (user !== "") {
      params.userId = user;
    }

    dispatch(getMarketingStatistic(params));

    setSelectedProduct("");
  };

  const clickExportBtn = () => {
    dispatch(getMarketingStatisticReport());
  };

  const classNameRoot = "marketing-statistic";

  return (
    <div className={`${classNameRoot}`}>
      <Box
        className="marketing-statistic_filter-control"
        // sx={{ width: "100%", minHeight: "359px", mt: "110px" }}
      >
        <Box className="title">
          <Typography component="span" className="main-title">
            Marketing Statistics
          </Typography>
          <Typography component="span">
            Filter data to see correct results
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            mt: "50px",
            ml: "30px",
            mr: "31px",
            justifyContent: "space-between",
          }}
        >
          <Box className="user__role" sx={{ flexWrap: "wrap", width: "35%" }}>
            <Typography component="p" sx={{ mb: "8px" }}>
              User role
            </Typography>
            <ButtonGroup
              className="btn-group"
              sx={{ width: "100%" }}
              variant="contained"
            >
              <Button
                className={`${
                  usersActiveButton === "ALL" ? "btn-active" : ""
                } `}
                onClick={() => changeUsersActiveButton("ALL")}
                sx={{ width: "40%" }}
              >
                All
              </Button>
              <Button
                className={`${
                  usersActiveButton === "_3X_USERS" ? "btn-active" : ""
                } `}
                onClick={() => {
                  changeUsersActiveButton("_3X_USERS");
                  if (user !== "") {
                    const userCurrent = listUsersStatistic.filter(
                      (item) => item.id === parseInt(user)
                    );

                    if (userCurrent[0].is_distributor === true) {
                      setUser("");
                    }
                  }
                }}
                sx={{ width: "80%" }}
              >
                3X Users
              </Button>
              <Button
                className={`${
                  usersActiveButton === "DISTRIBUTORS" ? "btn-active" : ""
                } `}
                onClick={() => {
                  changeUsersActiveButton("DISTRIBUTORS");
                  if (user !== "") {
                    const userCurrent = listUsersStatistic.filter(
                      (item) => item.id === parseInt(user)
                    );

                    if (userCurrent[0].is_distributor === false) {
                      setUser("");
                    }
                  }
                }}
                sx={{ width: "80%" }}
              >
                Distributors
              </Button>
            </ButtonGroup>
          </Box>

          <Box component="form" className="form">
            <FormControlLabel
              sx={{ width: "29%", mr: "30px", maxWidth: "29%" }}
              label={"Geozone"}
              className="user__form-control"
              control={
                <Select
                  sx={{ width: "100%", maxWidth: "100%" }}
                  displayEmpty={true}
                  value={geoZone}
                  onChange={(e) => {
                    setGeoZone(e.target.value);
                    setUser("");
                  }}
                >
                  <MenuItem value="" key={null}>
                    Select geozone
                  </MenuItem>

                  {geoZones.map((item) => {
                    return (
                      <MenuItem value={item} key={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              }
            />

            <FormControlLabel
              sx={{ width: "50%", maxWidth: "50%" }}
              label={"User"}
              className="user__form-control"
              control={
                <Select
                  sx={{ width: "100%", maxWidth: "100%" }}
                  displayEmpty={true}
                  value={user}
                  onChange={(e) => setUser(e.target.value)}
                >
                  <MenuItem value="" key={null}>
                    Select user
                  </MenuItem>

                  {listUsersStatistic
                    .filter((item) => {
                      if (
                        usersActiveButton === "DISTRIBUTORS" &&
                        item.is_distributor !== true
                      ) {
                        return false;
                      }

                      if (
                        usersActiveButton === "_3X_USERS" &&
                        item.is_distributor !== false
                      ) {
                        return false;
                      }

                      if (geoZone !== "") {
                        return item.geo_zone === geoZone;
                      }

                      return true;
                    })
                    .map((item) => {
                      return (
                        <MenuItem value={item.id} key={item.id}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              }
            />

            <FormControlLabel
              sx={{ width: "29%", mr: "30px", maxWidth: "29%" }}
              label={"Status of the technical proposal"}
              className="user__form-control"
              control={
                <Select
                  sx={{ width: "100%", maxWidth: "100%" }}
                  displayEmpty={true}
                  value={status}
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                >
                  <MenuItem value="" key={null}>
                    Select status
                  </MenuItem>

                  {statuses.map((item) => {
                    return (
                      <MenuItem value={item} key={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              }
            />
          </Box>
        </Box>
        <Box className="box-row">
          <ButtonCustom
            type={TypesButton.outline}
            text="Export the report in XLS"
            handleClick={() => clickExportBtn()}
            size={275}
          />
          <ButtonCustom
            type={TypesButton.primary}
            text="Apply filter settings"
            handleClick={() => clickApplyBtn()}
            size={275}
          />
        </Box>
      </Box>

      <div className="composites-with-type">
        <div className="composites">
          {marketingStatistic !== null &&
            marketingStatistic.result.length === 0 && (
              <div className="text-empty">
                There are no results for this filter data
              </div>
            )}
          {marketingStatistic !== null &&
            marketingStatistic.result.length > 0 && (
              <DoughnutChartMarketing
                title="Products"
                width={500}
                height={500}
                marketingStatistic={marketingStatistic.result}
                total={marketingStatistic.total}
              />
            )}

          {marketingStatistic !== null &&
            marketingStatistic.result.length > 0 && (
              <div className="marketing-statistic_form">
                <FormControlLabel
                  label={"Choose product"}
                  labelPlacement="start"
                  className="marketing-statistic_form_control"
                  control={
                    <Select
                      sx={{ width: "100%", maxWidth: "100%" }}
                      displayEmpty={true}
                      value={selectedProduct}
                      onChange={(e) => {
                        changeSelectProduct(e.target.value);
                      }}
                    >
                      <MenuItem value="" key="null">
                        Choose product
                      </MenuItem>

                      {marketingStatistic.result.map((item) => {
                        return (
                          <MenuItem value={item.name} key={item.name}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  }
                />
              </div>
            )}
        </div>
        <div className="composites">
          {selectedProduct !== "" &&
            marketingStatisticDetails !== null &&
            marketingStatisticDetails.resin.length === 0 &&
            marketingStatisticDetails.tape_type.length === 0 &&
            marketingStatisticDetails.width_type.length === 0 && (
              <div className="text-empty">
                There are no results for this filter data
              </div>
            )}

          {selectedProduct !== "" &&
            marketingStatisticDetails !== null &&
            marketingStatisticDetails?.resin &&
            marketingStatisticDetails.resin.length > 0 && (
              <DoughnutChartMarketing
                title="Resin"
                width={300}
                height={300}
                marketingStatisticDetails={marketingStatisticDetails.resin}
              />
            )}
        </div>
        <div className="composites">
          {selectedProduct !== "" &&
            marketingStatisticDetails !== null &&
            marketingStatisticDetails?.tape_type &&
            marketingStatisticDetails.tape_type.length > 0 && (
              <DoughnutChartMarketing
                title="Tape type"
                width={300}
                height={300}
                marketingStatisticDetails={marketingStatisticDetails.tape_type}
              />
            )}
        </div>
        <div className="composites">
          {selectedProduct !== "" &&
            marketingStatisticDetails !== null &&
            marketingStatisticDetails?.width_type &&
            marketingStatisticDetails.width_type.length > 0 && (
              <DoughnutChartMarketing
                title="Width type"
                width={300}
                height={300}
                marketingStatisticDetails={marketingStatisticDetails.width_type}
              />
            )}
        </div>
      </div>
    </div>
  );
};

export default MarketingStatistic;
