import { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Switch from "@mui/material/Switch";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";

import { useAppDispatch, useAppSelector } from "app/hook";
import { TypesButton } from "types";
import {
  ModalConfirm,
  Button,
  // CopySvg,
  EditSvg,
  DeleteSvg,
} from "components/library";
import Recovery from "assets/images/recovery.svg";

import { getDate } from "helpers/getDate";

import {
  getComposits,
  getFillers,
  getPrimers,
} from "features/products-management/products-management-slice";

import {
  getGeoZones,
  getPermissions,
  getUsersConfirmed,
  getUsersNotConfirmed,
  getUsersRejected,
  userReject,
  userRecovery,
  userEditSwitchStatus,
} from "./users-management-slice";

import ModalAddUser from "./components/modal-add-user";

import "./users-management.scss";

const UsersManagement = () => {
  const {
    listUsers,
    listUsersTotal,
    newRequests,
    newRequestsTotal,
    histories,
    historiesTotal,
    geoZones,
    permissions,
    loading,
    // error
  } = useAppSelector((state) => state.usersManagement);

  const { email, userId } = useAppSelector((state) => state.loginUser);

  const {
    listComposits,
    listFillers,
    listPrimers,
    loading: loadingP,
    // error
  } = useAppSelector((state) => state.productsManagement);

  const [value, setValue] = useState("one");
  const [showModalAddUser, setShowModalAddUser] = useState(false);
  const [showModalConfirmReject, setShowModalConfirmReject] = useState(false);
  const [showModalConfirmRecovery, setShowModalConfirmRecovery] =
    useState(false);
  const [typeOperation, setTypeOperation] = useState("new");
  const [selectedUser, setSelectedUser] = useState<{
    id: number;
    name: string;
    phone: string;
    email: string;
    location: string;
    company: string;
    geozone: string;
    role: string;
  } | null>(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (userId !== "" && email !== "" && !loading && listUsers === null) {
      dispatch(getUsersConfirmed());
    }
  }, [dispatch, email, userId, listUsers, loading]);

  useEffect(() => {
    if (userId !== "" && email !== "" && !loading && newRequests === null) {
      dispatch(getUsersNotConfirmed());
    }
  }, [dispatch, email, userId, newRequests, loading]);

  useEffect(() => {
    if (userId !== "" && email !== "" && !loading && histories === null) {
      dispatch(getUsersRejected());
    }
  }, [dispatch, email, userId, histories, loading]);

  useEffect(() => {
    if (userId !== "" && email !== "" && !loading && geoZones === null) {
      dispatch(getGeoZones());
    }
  }, [dispatch, email, userId, geoZones, loading]);

  useEffect(() => {
    if (userId !== "" && email !== "" && !loading && permissions === null) {
      dispatch(getPermissions());
    }
  }, [dispatch, email, userId, permissions, loading]);

  useEffect(() => {
    if (userId !== "" && email !== "" && !loadingP && listComposits === null) {
      dispatch(getComposits());
    }
  }, [dispatch, email, userId, listComposits, loadingP]);

  useEffect(() => {
    if (userId !== "" && email !== "" && !loadingP && listFillers === null) {
      dispatch(getFillers());
    }
  }, [dispatch, email, userId, listFillers, loadingP]);

  useEffect(() => {
    if (userId !== "" && email !== "" && !loadingP && listPrimers === null) {
      dispatch(getPrimers());
    }
  }, [dispatch, email, userId, listPrimers, loadingP]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const columnsUsers: GridColDef[] = [
    {
      field: "",
      width: 105,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: any) => {
        return (
          <>
            <IconButton
              className="action-btn hover-btn"
              onClick={() => {
                setSelectedUser(params.row);
                setTypeOperation("edit");
                setShowModalAddUser(true);
              }}
            >
              <EditSvg />
              <EditSvg isHover />
            </IconButton>
            {/* <IconButton
              className="action-btn hover-btn"
              onClick={() => {}}
            >
              <CopySvg />
              <CopySvg isHover />
            </IconButton> */}
            <IconButton
              className="action-btn hover-btn"
              onClick={() => {
                setSelectedUser(params.row);
                setShowModalConfirmReject(true);
              }}
            >
              <DeleteSvg />
              <DeleteSvg isHover />
            </IconButton>
          </>
        );
      },
    },
    {
      field: "N",
      headerName: "№",
      width: 50,
      renderCell: (params: any) => {
        return params.api.getRowIndex(params.row.id) + 1;
      },
    },
    { field: "name", headerName: "Name", width: 250 },
    { field: "phone", headerName: "Phone", width: 150 },
    { field: "email", headerName: "Email", width: 300 },
    {
      field: "role",
      headerName: "Role",
      width: 150,
    },
    {
      field: "company",
      headerName: "Company",
      width: 200,
    },
    {
      field: "geozone",
      headerName: "Geozone",
      width: 200,
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      renderCell: (params: any) => {
        return (
          <>
            <Switch
              checked={params.row.status}
              onChange={(e) => {
                dispatch(
                  userEditSwitchStatus({
                    id: params.row.id,
                    status: e.target.checked,
                  })
                );
              }}
            />
          </>
        );
      },
    },
    {
      field: "confirmed_at",
      headerName: "Validated on",
      width: 100,
      renderCell: (params: any) => {
        if (params.row.confirmed_at !== null) {
          return <>{getDate(params.row.confirmed_at)}</>;
        }
        return <></>;
      },
    },
    {
      field: "visited_at",
      headerName: "Last connection",
      width: 100,
      renderCell: (params: any) => {
        if (params.row.visited_at !== null) {
          return <>{getDate(params.row.visited_at)}</>;
        }
        return <></>;
      },
    },
  ];

  const columnsRequests: GridColDef[] = [
    {
      field: "",
      width: 220,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: any) => {
        return (
          <div className="btn-wrapp">
            <Button
              type={TypesButton.accept}
              text="accept"
              handleClick={() => {
                setSelectedUser(params.row);
                setTypeOperation("accept");
                setShowModalAddUser(true);
              }}
            />
            <Button
              type={TypesButton.reject}
              text="reject"
              handleClick={() => {
                setSelectedUser(params.row);
                setShowModalConfirmReject(true);
              }}
            />
          </div>
        );
      },
    },
    {
      field: "N",
      headerName: "№",
      width: 50,
      renderCell: (params: any) => {
        return params.api.getRowIndex(params.row.id) + 1;
      },
    },
    { field: "name", headerName: "Distributor", width: 250 },
    { field: "email", headerName: "Email", width: 300 },
    {
      field: "company",
      headerName: "Company",
      width: 200,
    },
    {
      field: "geozone",
      headerName: "Geozone",
      width: 200,
    },
    {
      field: "created_at",
      headerName: "Sent request",
      width: 100,
      renderCell: (params: any) => {
        if (params.row.created_at !== null) {
          return <>{getDate(params.row.created_at)}</>;
        }
        return <></>;
      },
    },
  ];

  const columnsRejects: GridColDef[] = [
    {
      field: "Operation",
      headerName: "Recovery",
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: any) => {
        return (
          <>
            <IconButton
              onClick={() => {
                setSelectedUser(params.row);
                setShowModalConfirmRecovery(true);
              }}
            >
              <img src={Recovery} alt="recovery user" />
            </IconButton>
          </>
        );
      },
    },
    {
      field: "N",
      headerName: "№",
      width: 50,
      renderCell: (params: any) => {
        return params.api.getRowIndex(params.row.id) + 1;
      },
    },
    { field: "name", headerName: "Name", width: 250 },
    { field: "email", headerName: "Email", width: 300 },
    {
      field: "company",
      headerName: "Company",
      width: 200,
    },
    {
      field: "geozone",
      headerName: "Geozone",
      width: 200,
    },
    {
      field: "confirmed_at",
      headerName: "Last update",
      width: 100,
      renderCell: (params: any) => {
        if (params.row.confirmed_at !== null) {
          return <>{getDate(params.row.confirmed_at)}</>;
        }
        return <></>;
      },
    },
  ];

  const getColumns = () => {
    if (value === "one") {
      return columnsUsers;
    }

    if (value === "two") {
      return columnsRequests;
    }

    return columnsRejects;
  };

  const getRows = (value: string) => {
    if (value === "one") {
      return listUsers !== null ? listUsers : [];
    }

    if (value === "two") {
      return newRequests !== null ? newRequests : [];
    }

    if (value === "three") {
      return histories !== null ? histories : [];
    }

    return [];
  };

  return (
    <div className="users-management">
      <div className="users-management_head">
        <div>Users</div>
        <div>
          <Button
            type={TypesButton.primary}
            text="Add a new user"
            handleClick={() => {
              setSelectedUser(null);
              setShowModalAddUser(true);
            }}
            size={155}
            icon={<AddIcon fontSize="small" />}
          />
        </div>
      </div>
      <TabContext value={value}>
        <Box sx={{ width: "100%", background: "#fff", borderRadius: "8px" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="primary"
            indicatorColor="primary"
            aria-label="tabs users"
            variant="fullWidth"
          >
            <Tab value="one" label={`List of users (${listUsersTotal})`} />
            <Tab value="two" label={`New requests (${newRequestsTotal})`} />
            <Tab value="three" label={`History (${historiesTotal})`} />
          </Tabs>
          <TabPanel value="one" sx={{ padding: "0" }}>
            <div style={{ height: "65vh", width: "100%" }}>
              <DataGrid
                rows={getRows("one")}
                columns={getColumns()}
                rowsPerPageOptions={[10, 20, 50, 100]}
                // checkboxSelection
                loading={loading}
                components={{
                  Toolbar: GridToolbar,
                }}
                onRowDoubleClick={(selectedRow) => {
                  setSelectedUser(selectedRow.row);
                  setTypeOperation("edit");
                  setShowModalAddUser(true);
                }}
              />
            </div>
          </TabPanel>
          <TabPanel value="two" sx={{ padding: "0" }}>
            <div style={{ height: "65vh", width: "100%" }}>
              <DataGrid
                rows={getRows("two")}
                columns={getColumns()}
                rowsPerPageOptions={[10, 20, 50, 100]}
                // checkboxSelection
                loading={loading}
                components={{
                  Toolbar: GridToolbar,
                }}
                onRowDoubleClick={(selectedRow) => {
                  setSelectedUser(selectedRow.row);
                  setTypeOperation("accept");
                  setShowModalAddUser(true);
                }}
              />
            </div>
          </TabPanel>
          <TabPanel value="three" sx={{ padding: "0" }}>
            <div style={{ height: "65vh", width: "100%" }}>
              <DataGrid
                rows={getRows("three")}
                columns={getColumns()}
                rowsPerPageOptions={[10, 20, 50, 100]}
                // checkboxSelection
                loading={loading}
                components={{
                  Toolbar: GridToolbar,
                }}
                onRowDoubleClick={(selectedRow) => {
                  setSelectedUser(selectedRow.row);
                  setShowModalConfirmRecovery(true);
                }}
              />
            </div>
          </TabPanel>
        </Box>
      </TabContext>
      {showModalAddUser && (
        <ModalAddUser
          show={showModalAddUser}
          user={selectedUser}
          handleClose={() => {
            setShowModalAddUser(false);
            setTypeOperation("new");
          }}
          type={typeOperation}
        />
      )}
      {showModalConfirmReject && selectedUser !== null && (
        <ModalConfirm
          show={showModalConfirmReject}
          textQuestion={`Are you sure that you want to reject user ${selectedUser?.name} - ${selectedUser?.email} ?`}
          handleCancel={() => setShowModalConfirmReject(false)}
          handleYes={() => {
            dispatch(userReject(selectedUser.id));
            setShowModalConfirmReject(false);
          }}
        />
      )}
      {showModalConfirmRecovery && selectedUser !== null && (
        <ModalConfirm
          show={showModalConfirmRecovery}
          textQuestion={`Are you sure that you want to recovery user ${selectedUser?.name} - ${selectedUser?.email} ?`}
          handleCancel={() => setShowModalConfirmRecovery(false)}
          handleYes={() => {
            dispatch(userRecovery(selectedUser.id));
            setShowModalConfirmRecovery(false);
          }}
        />
      )}
    </div>
  );
};

export default UsersManagement;
