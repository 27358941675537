import { useState } from "react";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import DialogContent from "@mui/material/DialogContent";

import { useAppDispatch } from "app/hook";

import { BootstrapDialog } from "components/library";

import { resetSendCode } from "./auth-slice";

import "./modal-login.scss";

type ModalLoginProps = {
  form: { email: string; password: string };
  loading: boolean;
  sendLogin: Function;
  sendRestore: Function;
  errors: {
    email?: string;
    password?: string;
    newPassword?: string;
    confirmPassword?: string;
    code?: string;
  };
  cleanErrors: Function;
  isRestore: boolean;
  setIsRestore: Function;
  isRestoreSend: boolean;
  setIsRestoreSend: Function;
  sendSave: Function;
  isSetNewPassword: boolean;
  isSendCode: boolean;
};

const ModalLogin = ({
  form,
  loading,
  sendLogin,
  sendRestore,
  errors,
  cleanErrors,
  isRestore,
  setIsRestore,
  isRestoreSend,
  setIsRestoreSend,
  sendSave,
  isSetNewPassword,
  isSendCode,
}: ModalLoginProps) => {
  const [email, setEmail] = useState(form.email);
  const [password, setPassword] = useState(form.password);

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [code, setCode] = useState("");

  const dispatch = useAppDispatch();

  const handleLogin = () => {
    sendLogin({ email, password });
  };

  const handleRestore = () => {
    sendRestore({ email });
  };

  const handleSave = () => {
    sendSave({ code, email, newPassword, confirmPassword });
  };

  return (
    <BootstrapDialog
      aria-labelledby="customized-dialog-title"
      open={true}
      className="modal-login"
    >
      <DialogContent className="modal-login_body">
        <div className="modal-login_body_left"></div>
        <div className="modal-login_body_right">
          {!isRestore && (
            <form className="modal-login_body_form" autoComplete="off">
              <FormControl>
                <div>
                  <TextField
                    autoFocus
                    id="email"
                    type="email"
                    placeholder="Login"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    required
                    error={errors.email ? true : false}
                    helperText={errors.email}
                    sx={{
                      marginTop: "0px",
                      marginBottom: "0px",
                    }}
                  />

                  <TextField
                    error={errors.password ? true : false}
                    id="password"
                    autoComplete="off"
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    required
                    helperText={errors.password}
                    sx={{
                      marginTop: "20px",
                      marginBottom: "36px",
                    }}
                  />
                </div>
              </FormControl>

              <LoadingButton
                className="btn-next"
                loading={loading}
                onClick={() => handleLogin()}
                disabled={loading}
              >
                Log in Admin panel
              </LoadingButton>
            </form>
          )}
          {!isRestore && (
            <div className="modal-login_wrap-restore">
              <Button
                variant="text"
                className="modal-login_btn-restore"
                onClick={() => {
                  setIsRestore(true);
                  cleanErrors();
                }}
              >
                Do you want to restore your password?
              </Button>
            </div>
          )}
          {isRestore && !isSendCode && (
            <form className="modal-login_body_form-restore" autoComplete="off">
              <FormControl>
                <div className="modal-login_body_form-restore_wrap">
                  <FormLabel>
                    Please enter your email address to get a code to restore you
                    password
                  </FormLabel>
                  <TextField
                    autoFocus
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    required
                    error={errors.email ? true : false}
                    helperText={errors.email}
                    sx={{
                      marginTop: "0px",
                      marginBottom: "0px",
                    }}
                  />
                </div>
              </FormControl>
              <div className="modal-login_body_form-restore_wrap-btns">
                <LoadingButton
                  className="btn-next"
                  loading={loading}
                  loadingPosition="start"
                  onClick={() => handleRestore()}
                  disabled={loading}
                >
                  Restore
                </LoadingButton>
                <Button
                  className="btn-prev"
                  onClick={() => {
                    setIsRestore(false);
                    setIsRestoreSend(false);
                    cleanErrors();
                  }}
                >
                  Back to log in
                </Button>
              </div>
            </form>
          )}
          {isSendCode && isRestoreSend && (
            <form
              className="modal-login_body_form-change-password"
              autoComplete="change-password"
            >
              <FormControl>
                <div className="modal-login_body_form-change-password_code-wrap">
                  <FormLabel>
                    Please check your email inbox to get a code to create a new
                    password
                  </FormLabel>
                  <TextField
                    autoFocus
                    autoComplete="code"
                    type="text"
                    placeholder="Code"
                    value={code}
                    onChange={(e) => {
                      setCode(e.target.value);
                    }}
                    required
                    error={errors.code ? true : false}
                    helperText={errors.code}
                    sx={{
                      marginTop: "0px",
                      marginBottom: "0px",
                    }}
                  />
                </div>
              </FormControl>
              <FormControl>
                <div className="formFieldNewPassword_wrap">
                  <FormLabel>Make a new password</FormLabel>
                  <TextField
                    autoComplete="new-password"
                    type="password"
                    placeholder="New password"
                    value={newPassword}
                    onChange={(e) => {
                      setNewPassword(e.target.value);
                    }}
                    required
                    error={errors.newPassword ? true : false}
                    helperText={errors.newPassword}
                    sx={{
                      marginTop: "0px",
                      marginBottom: "0px",
                    }}
                  />
                </div>
              </FormControl>
              <FormControl>
                <div className="modal-login_body_form-change-password_confirm-password-wrap">
                  <TextField
                    autoComplete="off"
                    type="password"
                    placeholder="Confirm password"
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                    }}
                    required
                    error={errors.confirmPassword ? true : false}
                    helperText={errors.confirmPassword}
                    sx={{
                      marginTop: "0px",
                      marginBottom: "0px",
                    }}
                  />
                </div>
              </FormControl>
              <div className="modal-login_body_form-restore_wrap-btns">
                <LoadingButton
                  className="btn-next"
                  loading={loading}
                  loadingPosition="start"
                  onClick={() => handleSave()}
                  disabled={loading}
                >
                  Save
                </LoadingButton>
                <Button
                  className="btn-prev"
                  onClick={() => {
                    setIsRestore(false);
                    setIsRestoreSend(false);
                    cleanErrors();
                    dispatch(resetSendCode());
                  }}
                >
                  Back to log in
                </Button>
              </div>
              {isSetNewPassword && (
                <div className="modal-login_body_form-change-password_success-text">
                  Password has been successfully changed
                </div>
              )}
            </form>
          )}
        </div>
      </DialogContent>
    </BootstrapDialog>
  );
};

export default ModalLogin;
