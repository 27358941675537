import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";

import { Button } from "components/library";
import { TypesButton } from "types";

import {
  BendManagement,
  DiameterManagement,
  GradeManagement,
} from "./components";

import "./pipe-information.scss";

type PipeInformationProps = {
  handleBack: Function;
};

export default function PipeInformation({ handleBack }: PipeInformationProps) {
  return (
    <div className="pipe-information">
      <div className="pipe-information_title">
        <div>Pipe information</div>
        <Button
          type={TypesButton.primary}
          text="Back to Content Management"
          handleClick={() => {
            handleBack(null);
          }}
          size={250}
        />
      </div>
      <div className="pipe-information_body">
        <Accordion className="pipe-information_body_accordion">
          <AccordionSummary
            expandIcon={
              <ArrowForwardIosSharpIcon
                sx={{ fontSize: "48px", padding: "12px" }}
              />
            }
            aria-controls="grade-content"
            id="grade-header"
            className="pipe-information_body_accordion_summary"
          >
            <Typography className="pipe-information_body_accordion_summary_title">
              Grade
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <GradeManagement />
          </AccordionDetails>
        </Accordion>
        <Accordion className="pipe-information_body_accordion">
          <AccordionSummary
            expandIcon={
              <ArrowForwardIosSharpIcon
                sx={{ fontSize: "48px", padding: "12px" }}
              />
            }
            aria-controls="pipe-content"
            id="pipe-header"
            className="pipe-information_body_accordion_summary"
          >
            <Typography className="pipe-information_body_accordion_summary_title">
              Pipe dimensions
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <DiameterManagement />
          </AccordionDetails>
        </Accordion>
        <Accordion className="pipe-information_body_accordion">
          <AccordionSummary
            expandIcon={
              <ArrowForwardIosSharpIcon
                sx={{ fontSize: "48px", padding: "12px" }}
              />
            }
            aria-controls="pipe2-content"
            id="pipe2-header"
            className="pipe-information_body_accordion_summary"
          >
            <Typography className="pipe-information_body_accordion_summary_title">
              Pipe dimensions stainless steel
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <DiameterManagement type="STEINLESS_STEEL" />
          </AccordionDetails>
        </Accordion>
        <Accordion className="pipe-information_body_accordion">
          <AccordionSummary
            expandIcon={
              <ArrowForwardIosSharpIcon
                sx={{ fontSize: "48px", padding: "12px" }}
              />
            }
            aria-controls="bend-content"
            id="bend-header"
            className="pipe-information_body_accordion_summary"
          >
            <Typography className="pipe-information_body_accordion_summary_title">
              Bend dimensions
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <BendManagement />
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}
