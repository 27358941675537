import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";

import { useAppDispatch, useAppSelector } from "app/hook";
import { TypesButton } from "types";
import { ModalConfirm, Button, EditSvg, DeleteSvg } from "components/library";

import {
  contentDeleteDiameterSteel,
  contentDeleteDiameterSteinlessSteel,
  DiameterSteel,
  DiameterStSteel,
  getDiametersSteel,
  getDiametersSteinlessSteel,
} from "features/content-management/content-management-slice";

import ModalAddDiameter from "./components/modal-add-diameter";

const DiameterManagement = ({ type = "STEEL" }) => {
  const { listDiametersSteel, listDiametersSteinlessSteel, loading } =
    useAppSelector((state) => state.contentManagement);

  const { email, userId } = useAppSelector((state) => state.loginUser);

  const [showModalAdd, setShowModalAdd] = useState(false);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [selectedDiameterSteel, setSelectedDiameterSteel] =
    useState<DiameterSteel | null>(null);
  const [selectedDiameterStSteel, setSelectedDiameterStSteel] =
    useState<DiameterStSteel | null>(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (userId !== "" && email !== "" && !loading) {
      if (type === "STEEL" && listDiametersSteel === null) {
        dispatch(getDiametersSteel());
      }
      if (type === "STEINLESS_STEEL" && listDiametersSteinlessSteel === null) {
        dispatch(getDiametersSteinlessSteel());
      }
    }
  }, [
    dispatch,
    email,
    userId,
    listDiametersSteel,
    listDiametersSteinlessSteel,
    loading,
    type,
  ]);

  const columnsDiameterSteel: GridColDef[] = [
    {
      field: "",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: any) => {
        return (
          <>
            <IconButton
              className="action-btn hover-btn"
              onClick={() => {
                setSelectedDiameterSteel(params.row);
                setShowModalAdd(true);
              }}
            >
              <EditSvg />
              <EditSvg isHover />
            </IconButton>
            <IconButton
              className="action-btn hover-btn"
              onClick={() => {
                setSelectedDiameterSteel(params.row);
                setShowModalConfirm(true);
              }}
            >
              <DeleteSvg />
              <DeleteSvg isHover />
            </IconButton>
          </>
        );
      },
    },

    {
      field: "N",
      headerName: "№",
      width: 50,
      renderCell: (params: any) => {
        return params.api.getRowIndex(params.row.id) + 1;
      },
    },
    { field: "nominal_diameter", headerName: "Dn(inch)", width: 100 },
    { field: "real_diameter", headerName: "Dext(mm)", width: 200 },
    { field: "schedule__5", headerName: "5" },
    { field: "schedule__10", headerName: "10" },
    { field: "schedule__20", headerName: "20" },
    { field: "schedule__30", headerName: "30" },
    { field: "schedule__40", headerName: "40" },
    { field: "schedule__60", headerName: "60" },
    { field: "schedule__80", headerName: "80" },
    { field: "schedule_100", headerName: "100" },
    { field: "schedule_120", headerName: "120" },
    { field: "schedule_140", headerName: "140" },
    { field: "schedule_160", headerName: "160" },
    { field: "schedule_std", headerName: "STD" },
    { field: "schedule_xs", headerName: "XS" },
    { field: "schedule_xxs", headerName: "XXS" },
  ];

  const columnsDiameterStSteel: GridColDef[] = [
    {
      field: "",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: any) => {
        return (
          <>
            <IconButton
              className="action-btn hover-btn"
              onClick={() => {
                setSelectedDiameterStSteel(params.row);
                setShowModalAdd(true);
              }}
            >
              <EditSvg />
              <EditSvg isHover />
            </IconButton>
            <IconButton
              className="action-btn hover-btn"
              onClick={() => {
                setSelectedDiameterStSteel(params.row);
                setShowModalConfirm(true);
              }}
            >
              <DeleteSvg />
              <DeleteSvg isHover />
            </IconButton>
          </>
        );
      },
    },

    {
      field: "N",
      headerName: "№",
      width: 100,
      renderCell: (params: any) => {
        return params.api.getRowIndex(params.row.id) + 1;
      },
    },
    { field: "nominal_diameter", headerName: "Dn(inch)", width: 200 },
    { field: "real_diameter", headerName: "Dext(mm)", width: 250 },
    { field: "schedule__5", headerName: "5S" },
    { field: "schedule__10", headerName: "10S" },
    { field: "schedule__40", headerName: "40S" },
    { field: "schedule__80", headerName: "80S" },
  ];

  return (
    <div className="diameter-management">
      <div className="diameter-management_head">
        <div>
          <Button
            type={TypesButton.primary}
            text="Add a new dimensions"
            handleClick={() => {
              type === "STEEL"
                ? setSelectedDiameterSteel(null)
                : setSelectedDiameterStSteel(null);
              setShowModalAdd(true);
            }}
            size={205}
            icon={<AddIcon fontSize="small" />}
          />
        </div>
      </div>
      <Box>
        <div style={{ height: "65vh", width: "100%" }}>
          <DataGrid
            rows={
              type === "STEEL"
                ? listDiametersSteel || []
                : listDiametersSteinlessSteel || []
            }
            columns={
              type === "STEEL" ? columnsDiameterSteel : columnsDiameterStSteel
            }
            rowsPerPageOptions={[10, 20, 50, 100]}
            // checkboxSelection
            loading={loading}
            components={{
              Toolbar: GridToolbar,
            }}
            onRowDoubleClick={(selectedRow) => {
              type === "STEEL"
                ? setSelectedDiameterSteel(selectedRow.row)
                : setSelectedDiameterStSteel(selectedRow.row);
              setShowModalAdd(true);
            }}
          />
        </div>
      </Box>
      {showModalAdd && (
        <ModalAddDiameter
          show={showModalAdd}
          diameterSteel={selectedDiameterSteel}
          diameterStSteel={selectedDiameterStSteel}
          handleClose={setShowModalAdd}
          type={type}
        />
      )}
      {showModalConfirm && selectedDiameterSteel !== null && (
        <ModalConfirm
          show={showModalConfirm}
          // eslint-disable-next-line max-len
          textQuestion={`Are you sure that you want to delete diameter - ${selectedDiameterSteel?.id} ${selectedDiameterSteel?.real_diameter}?`}
          handleCancel={() => setShowModalConfirm(false)}
          handleYes={() => {
            dispatch(
              contentDeleteDiameterSteel(selectedDiameterSteel?.id || -1)
            );
            setShowModalConfirm(false);
          }}
        />
      )}
      {showModalConfirm && selectedDiameterStSteel !== null && (
        <ModalConfirm
          show={showModalConfirm}
          // eslint-disable-next-line max-len
          textQuestion={`Are you sure that you want to delete diameter - ${selectedDiameterStSteel?.id} ${selectedDiameterStSteel?.real_diameter}?`}
          handleCancel={() => setShowModalConfirm(false)}
          handleYes={() => {
            dispatch(
              contentDeleteDiameterSteinlessSteel(
                selectedDiameterStSteel?.id || -1
              )
            );
            setShowModalConfirm(false);
          }}
        />
      )}
    </div>
  );
};

export default DiameterManagement;
