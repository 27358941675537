import { ProductsManagement } from "features/products-management";

const Filler = () => {
  return (
    <div>
      <ProductsManagement type="filler" />
    </div>
  );
};

export default Filler;
