type IDownloadProps = {
    isHover?: boolean;
  };
  
const DownloadSvg = ({ isHover = false }: IDownloadProps) => {
  if (isHover) {
    return (
      <svg
        width="14"
        height="17"
        viewBox="0 0 14 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="hover-on"
      >
        <path d="M14 6H10V0H4V6H0L7 13L14 6ZM6 8V2H8V8H9.17L7 10.17L4.83 8H6ZM0 15H14V17H0V15Z" fill="#3B61A5"/>
      </svg>
    );
  };
  return (
    <svg
      width="14"
      height="17"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="hover-off"
    >
      <path d="M14 6H10V0H4V6H0L7 13L14 6ZM6 8V2H8V8H9.17L7 10.17L4.83 8H6ZM0 15H14V17H0V15Z" fill="#94A1A2"/>
    </svg>
  );
};

export default DownloadSvg;
