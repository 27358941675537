import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getFetchProp } from "helpers/getFetchProp";

import { LoginUserState } from "features/auth/auth-slice";

import { apiUrl } from "../../constants";

type Grade = {
  id: number;
  grade: string;
  smys: number;
  mby: number;
};

type DiameterSteel = {
  id: number;
  nominal_diameter: number;
  real_diameter: number;
  schedule_100: number | null;
  schedule_120: number | null;
  schedule_140: number | null;
  schedule_160: number | null;
  schedule__10: number | null;
  schedule__20: number | null;
  schedule__30: number | null;
  schedule__40: number | null;
  schedule__5: number | null;
  schedule__60: number | null;
  schedule__80: number | null;
  schedule_std: number | null;
  schedule_xs: number | null;
  schedule_xxs: number | null;
};

type DiameterStSteel = {
  id: number;
  nominal_diameter: number;
  real_diameter: number;
  schedule__10: number | null;
  schedule__40: number | null;
  schedule__5: number | null;
  schedule__80: number | null;
};

type Bend = {
  id: number;
  pipe_diameter: number;
  bend_diameter: number;
};

type Reason = {
  id: number;
  name: string;
};

export type ContentManagementState = {
  listGrades: Grade[] | null;
  listGradesTotal: number;
  listDiametersSteel: DiameterSteel[] | null;
  listDiametersSteelTotal: number;
  listDiametersSteinlessSteel: DiameterStSteel[] | null;
  listDiametersSteinlessSteelTotal: number;
  listBends: Bend[] | null;
  listBendsTotal: number;
  listReasons: Reason[] | null;
  listReasonsTotal: number;
  isCreatedContent: boolean;
  isEditedContent: boolean;
  missingText: string | null;
  isEditedMissingText: boolean;
  generalText: string[] | null;
  isEditedGeneralText: boolean;
  error: string | null;
  loading: boolean;
  savingGeneralText: boolean;
};

const initialState: ContentManagementState = {
  listGrades: null,
  listGradesTotal: 0,
  listDiametersSteel: null,
  listDiametersSteelTotal: 0,
  listDiametersSteinlessSteel: null,
  listDiametersSteinlessSteelTotal: 0,
  listBends: null,
  listBendsTotal: 0,
  listReasons: null,
  listReasonsTotal: 0,
  isCreatedContent: false,
  isEditedContent: false,
  missingText: null,
  isEditedMissingText: false,
  generalText: null,
  isEditedGeneralText: false,
  error: null,
  loading: false,
  savingGeneralText: false,
};

export const getGrades = createAsyncThunk(
  "getAllGrades",
  async (_, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    const res = await fetch(`${apiUrl}/rea/v1.0/api/grade`, {
      ...getFetchProp({
        method: "GET",
        contentType: "application/json",
        token: loginUser.token,
      }),
    });

    return res.json();
  }
);

export const getDiametersSteel = createAsyncThunk(
  "getDiameterSteelList",
  async (_, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };

    const res = await fetch(
      `${apiUrl}/rea/v1.0/api/pipe_scheduler/full/steel`,
      {
        ...getFetchProp({
          method: "GET",
          contentType: "application/json",
          token: loginUser.token,
        }),
      }
    );

    return res.json();
  }
);

export const getDiametersSteinlessSteel = createAsyncThunk(
  "getDiameterSteinlessSteelList",
  async (_, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };

    const res = await fetch(
      `${apiUrl}/rea/v1.0/api/pipe_scheduler/full/st_steel`,
      {
        ...getFetchProp({
          method: "GET",
          contentType: "application/json",
          token: loginUser.token,
        }),
      }
    );

    return res.json();
  }
);

export const getBends = createAsyncThunk(
  "getAllBends",
  async (_, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    const res = await fetch(`${apiUrl}/rea/v1.0/api/bend/diameter/full`, {
      ...getFetchProp({
        method: "GET",
        contentType: "application/json",
        token: loginUser.token,
      }),
    });

    return res.json();
  }
);

export const getReasons = createAsyncThunk(
  "getAllReasons",
  async (_, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    const res = await fetch(
      `${apiUrl}/rea/v1.0/api/tech_proposal/rejected_reason`,
      {
        ...getFetchProp({
          method: "GET",
          contentType: "application/json",
          token: loginUser.token,
        }),
      }
    );

    return res.json();
  }
);

export const getMissingInformation = createAsyncThunk(
  "getMissingInformation",
  async (_, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    const res = await fetch(
      `${apiUrl}/rea/v1.0/api/description/missing_information`,
      {
        ...getFetchProp({
          token: loginUser.token,
        }),
      }
    );

    return res.text();
  }
);

export const getGeneralInformation = createAsyncThunk(
  "getGeneralInformation",
  async (_, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    const res = await fetch(`${apiUrl}/rea/v1.0/api/description/condition`, {
      ...getFetchProp({
        token: loginUser.token,
      }),
    });

    return res.json();
  }
);

interface ContentCreateEditGradeInput extends Omit<Grade, "id"> {
  id: number | null;
}

export const contentCreateEditGrade = createAsyncThunk(
  "create_edit_Grade",
  async (
    { id, grade, smys, mby }: ContentCreateEditGradeInput,
    { getState }
  ) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    if (loginUser.accountType === "ADMINISTRATOR" && loginUser.token) {
      const res = await fetch(`${apiUrl}/rea/v1.0/api/grade`, {
        ...getFetchProp({
          method: "POST",
          contentType: "application/json",
          token: loginUser.token,
          body: JSON.stringify([
            {
              id,
              grade,
              smys,
              mby,
            },
          ]),
        }),
      });

      return res.json();
    }
  }
);

export const contentDeleteGrade = createAsyncThunk(
  "content_delete_Grade",
  async (id: number, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    if (loginUser.accountType === "ADMINISTRATOR" && loginUser.token) {
      const res = await fetch(`${apiUrl}/rea/v1.0/api/grade?id=${id}`, {
        ...getFetchProp({
          method: "DELETE",
          contentType: "application/json",
          token: loginUser.token,
        }),
      });

      return new Promise<any>((resolve, reject) => {
        if (res.ok && res.status === 200) {
          resolve({ id });
        } else {
          reject(new Error("error"));
        }
      });
    }
  }
);

interface ContentCreateEditDiameterSteelInput
  extends Omit<DiameterSteel, "id"> {
  id: number | null;
}

export const contentCreateEditDiameterSteel = createAsyncThunk(
  "create_edit_Diameter_Steel",
  async (
    {
      id,
      real_diameter,
      nominal_diameter,
      schedule_100,
      schedule_120,
      schedule_140,
      schedule_160,
      schedule__10,
      schedule__20,
      schedule__30,
      schedule__40,
      schedule__5,
      schedule__60,
      schedule__80,
      schedule_std,
      schedule_xs,
      schedule_xxs,
    }: ContentCreateEditDiameterSteelInput,
    { getState }
  ) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    if (loginUser.accountType === "ADMINISTRATOR" && loginUser.token) {
      const res = await fetch(
        `${apiUrl}/rea/v1.0/api/pipe_scheduler/full/steel`,
        {
          ...getFetchProp({
            method: "POST",
            contentType: "application/json",
            token: loginUser.token,
            body: JSON.stringify({
              id,
              real_diameter,
              nominal_diameter,
              schedule_100,
              schedule_120,
              schedule_140,
              schedule_160,
              schedule__10,
              schedule__20,
              schedule__30,
              schedule__40,
              schedule__5,
              schedule__60,
              schedule__80,
              schedule_std,
              schedule_xs,
              schedule_xxs,
            }),
          }),
        }
      );

      return res.json();
    }
  }
);

export const contentDeleteDiameterSteel = createAsyncThunk(
  "content_delete_DiameterSteel",
  async (id: number, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    if (loginUser.accountType === "ADMINISTRATOR" && loginUser.token) {
      const res = await fetch(
        `${apiUrl}/rea/v1.0/api/pipe_scheduler/full/steel?id=${id}`,
        {
          ...getFetchProp({
            method: "DELETE",
            contentType: "application/json",
            token: loginUser.token,
          }),
        }
      );

      return new Promise<any>((resolve, reject) => {
        if (res.ok && res.status === 200) {
          resolve({ id });
        } else {
          reject(new Error("error"));
        }
      });
    }
  }
);

interface ContentCreateEditDiameterStSteelInput
  extends Omit<DiameterStSteel, "id"> {
  id: number | null;
}

export const contentCreateEditDiameterSteinlessSteel = createAsyncThunk(
  "create_edit_Diameter_SteinlessSteel",
  async (
    {
      id,
      real_diameter,
      nominal_diameter,
      schedule__10,
      schedule__40,
      schedule__5,
      schedule__80,
    }: ContentCreateEditDiameterStSteelInput,
    { getState }
  ) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    if (loginUser.accountType === "ADMINISTRATOR" && loginUser.token) {
      const res = await fetch(
        `${apiUrl}/rea/v1.0/api/pipe_scheduler/full/st_steel`,
        {
          ...getFetchProp({
            method: "POST",
            contentType: "application/json",
            token: loginUser.token,
            body: JSON.stringify({
              id,
              real_diameter,
              nominal_diameter,
              schedule__10,
              schedule__40,
              schedule__5,
              schedule__80,
            }),
          }),
        }
      );

      return res.json();
    }
  }
);

export const contentDeleteDiameterSteinlessSteel = createAsyncThunk(
  "content_delete_DiameterSteinlessSteel",
  async (id: number, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    if (loginUser.accountType === "ADMINISTRATOR" && loginUser.token) {
      const res = await fetch(
        `${apiUrl}/rea/v1.0/api/pipe_scheduler/full/st_steel?id=${id}`,
        {
          ...getFetchProp({
            method: "DELETE",
            contentType: "application/json",
            token: loginUser.token,
          }),
        }
      );

      return new Promise<any>((resolve, reject) => {
        if (res.ok && res.status === 200) {
          resolve({ id });
        } else {
          reject(new Error("error"));
        }
      });
    }
  }
);

interface ContentCreateEditBendInput extends Omit<Bend, "id"> {
  id: number | null;
}

export const contentCreateEditBend = createAsyncThunk(
  "create_edit_Bend",
  async (
    { id, pipe_diameter, bend_diameter }: ContentCreateEditBendInput,
    { getState }
  ) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    if (loginUser.accountType === "ADMINISTRATOR" && loginUser.token) {
      const res = await fetch(`${apiUrl}/rea/v1.0/api/bend/diameter/full`, {
        ...getFetchProp({
          method: "POST",
          contentType: "application/json",
          token: loginUser.token,
          body: JSON.stringify({
            id,
            pipe_diameter,
            bend_diameter,
          }),
        }),
      });

      return res.json();
    }
  }
);

export const contentEditMissingInformation = createAsyncThunk(
  "setMissingInformation",
  async (text: string, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    if (loginUser.accountType === "ADMINISTRATOR" && loginUser.token) {
      const res = await fetch(
        `${apiUrl}/rea/v1.0/api/description/missing_information`,
        {
          ...getFetchProp({
            method: "PUT",
            contentType: "application/json",
            token: loginUser.token,
            body: JSON.stringify([
              {
                country: "UK",
                language: "en",
                value: [text],
              },
            ]),
          }),
        }
      );

      return new Promise<any>((resolve, reject) => {
        if (res.ok && res.status === 200) {
          resolve({ status: 200 });
        } else {
          reject(new Error("error"));
        }
      });
    }
  }
);

export const contentEditGeneralInformation = createAsyncThunk(
  "setGeneralInformation",
  async (text: string[], { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    if (loginUser.accountType === "ADMINISTRATOR" && loginUser.token) {
      const res = await fetch(`${apiUrl}/rea/v1.0/api/description/condition`, {
        ...getFetchProp({
          method: "PUT",
          contentType: "application/json",
          token: loginUser.token,
          body: JSON.stringify([
            {
              country: "UK",
              language: "en",
              value: [...text],
            },
          ]),
        }),
      });

      return new Promise<any>((resolve, reject) => {
        if (res.ok && res.status === 200) {
          resolve({ status: 200, text });
        } else {
          reject(new Error("error"));
        }
      });
    }
  }
);

interface ContentCreateEditReasonInput extends Omit<Reason, "id"> {
  id: number | null;
}

export const contentCreateEditReason = createAsyncThunk(
  "create_edit_Reason",
  async ({ id, name }: ContentCreateEditReasonInput, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    if (loginUser.accountType === "ADMINISTRATOR" && loginUser.token) {
      const res = await fetch(
        `${apiUrl}/rea/v1.0/api/tech_proposal/rejected_reason?name=${name}`,
        {
          ...getFetchProp({
            method: "POST",
            contentType: "application/json",
            token: loginUser.token,
            // body: JSON.stringify([
            //   {
            //     id,
            //     name,
            //   },
            // ]),
          }),
        }
      );

      return res.json();
    }
  }
);

export const contentDeleteReason = createAsyncThunk(
  "content_delete_Reason",
  async (id: number, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    if (loginUser.accountType === "ADMINISTRATOR" && loginUser.token) {
      const res = await fetch(
        `${apiUrl}/rea/v1.0/api/tech_proposal/rejected_reason?id=${id}`,
        {
          ...getFetchProp({
            method: "DELETE",
            contentType: "application/json",
            token: loginUser.token,
          }),
        }
      );

      return new Promise<any>((resolve, reject) => {
        if (res.ok && res.status === 200) {
          resolve({ id });
        } else {
          reject(new Error("error"));
        }
      });
    }
  }
);

const contentManagementSlice = createSlice({
  name: "content management",
  initialState,
  reducers: {
    cleanError(state) {
      state.error = null;
    },
    resetEditMissingInformation(state) {
      state.isEditedMissingText = false;
    },
    resetEditGeneralInformation(state) {
      state.isEditedGeneralText = false;
    },
    resetCreatedContent(state) {
      state.isCreatedContent = false;
      state.isEditedContent = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGrades.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getGrades.fulfilled, (state, action) => {
        state.listGrades = action.payload;
        state.listGradesTotal = action.payload.length;
        state.loading = false;
        state.error = null;
      })
      .addCase(getGrades.rejected, (state) => {
        state.loading = false;
        state.error = "Request get grades is fail!";
      })
      .addCase(getDiametersSteel.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getDiametersSteel.fulfilled, (state, action) => {
        state.listDiametersSteel = action.payload;
        state.listDiametersSteelTotal = action.payload.length;
        state.loading = false;
        state.error = null;
      })
      .addCase(getDiametersSteel.rejected, (state) => {
        state.loading = false;
        state.error = "Request get diameters Steel is fail!";
      })
      .addCase(getDiametersSteinlessSteel.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getDiametersSteinlessSteel.fulfilled, (state, action) => {
        state.listDiametersSteinlessSteel = action.payload;
        state.listDiametersSteinlessSteelTotal = action.payload.length;
        state.loading = false;
        state.error = null;
      })
      .addCase(getDiametersSteinlessSteel.rejected, (state) => {
        state.loading = false;
        state.error = "Request get diameters Steinless Steel is fail!";
      })
      .addCase(getBends.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getBends.fulfilled, (state, action) => {
        state.listBends = action.payload;
        state.listBendsTotal = action.payload.length;
        state.loading = false;
        state.error = null;
      })
      .addCase(getBends.rejected, (state) => {
        state.loading = false;
        state.error = "Request get bend diameters is fail!";
      })
      .addCase(getMissingInformation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getMissingInformation.fulfilled, (state, action) => {
        state.missingText = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getMissingInformation.rejected, (state) => {
        state.loading = false;
        state.missingText = "";
        state.error = "Request get missing information is fail!";
      })
      .addCase(getGeneralInformation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getGeneralInformation.fulfilled, (state, action) => {
        state.generalText = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getGeneralInformation.rejected, (state) => {
        state.loading = false;
        state.missingText = "";
        state.error = "Request get general information is fail!";
      })
      .addCase(getReasons.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getReasons.fulfilled, (state, action) => {
        state.listReasons = action.payload;
        state.listReasonsTotal = action.payload.length;
        state.loading = false;
        state.error = null;
      })
      .addCase(getReasons.rejected, (state) => {
        state.loading = false;
        state.error = "Request get reasons is fail!";
      })
      .addCase(contentCreateEditGrade.pending, (state) => {
        state.isCreatedContent = false;
        state.loading = true;
        state.error = null;
      })
      .addCase(contentCreateEditGrade.fulfilled, (state, action) => {
        if (
          Array.isArray(action.payload) &&
          action.payload.length === 1 &&
          action.payload[0].id !== null
        ) {
          state.error = null;
          if (state.listGrades !== null) {
            const newList = [...state.listGrades];
            const ind = newList.findIndex(
              (item) => item.id === action.payload[0].id
            );
            if (ind > -1) {
              newList[ind] = {
                ...action.payload[0],
              };
              state.listGrades = [...newList];
              state.isEditedContent = true;
            } else {
              state.listGrades = [...state.listGrades, ...action.payload];
              state.listGradesTotal = state.listGrades.length;
              state.isCreatedContent = true;
            }
          } else {
            state.listGrades = action.payload;
            state.listGradesTotal = 1;
            state.isCreatedContent = true;
          }
        }
        if (
          action.payload.status &&
          action.payload.status > 0 &&
          action.payload.error
        ) {
          state.error = action.payload.error;
        }

        state.loading = false;
      })
      .addCase(contentCreateEditGrade.rejected, (state) => {
        state.loading = false;
        state.error = "Request create/edit content grade is fail!";
      })
      .addCase(contentDeleteGrade.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(contentDeleteGrade.fulfilled, (state, action) => {
        if (action.payload && action.payload.id > 0) {
          state.error = null;

          if (state.listGrades !== null) {
            state.listGrades = state.listGrades.filter(
              (item) => item.id !== action.payload.id
            );
          }
        }

        state.loading = false;
      })
      .addCase(contentDeleteGrade.rejected, (state) => {
        state.loading = false;
        state.error = "Request delete content grade is fail!";
      })
      .addCase(contentCreateEditDiameterSteel.pending, (state) => {
        state.isCreatedContent = false;
        state.loading = true;
        state.error = null;
      })
      .addCase(contentCreateEditDiameterSteel.fulfilled, (state, action) => {
        if (action.payload.id !== null && action.payload.id > 0) {
          state.error = null;
          if (state.listDiametersSteel !== null) {
            const newList = [...state.listDiametersSteel];
            const ind = newList.findIndex(
              (item) => item.id === action.payload.id
            );
            if (ind > -1) {
              newList[ind] = {
                ...action.payload,
              };
              state.listDiametersSteel = [...newList];
              state.isEditedContent = true;
            } else {
              newList.push(action.payload);
              state.listDiametersSteel = [...newList];
              state.listDiametersSteelTotal = state.listDiametersSteel.length;
              state.isCreatedContent = true;
            }
          } else {
            state.listDiametersSteel = [action.payload];
            state.listDiametersSteelTotal = 1;
            state.isCreatedContent = true;
          }
        }
        if (
          action.payload.status &&
          action.payload.status > 0 &&
          action.payload.error
        ) {
          state.error = action.payload.error;
        }

        state.loading = false;
      })
      .addCase(contentCreateEditDiameterSteel.rejected, (state) => {
        state.loading = false;
        state.error = "Request create/edit content diameter steel is fail!";
      })
      .addCase(contentDeleteDiameterSteel.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(contentDeleteDiameterSteel.fulfilled, (state, action) => {
        if (action.payload && action.payload.id > 0) {
          state.error = null;

          if (state.listDiametersSteel !== null) {
            state.listDiametersSteel = state.listDiametersSteel.filter(
              (item) => item.id !== action.payload.id
            );
          }
        }

        state.loading = false;
      })
      .addCase(contentDeleteDiameterSteel.rejected, (state) => {
        state.loading = false;
        state.error = "Request delete content diameter steel is fail!";
      })
      .addCase(contentCreateEditDiameterSteinlessSteel.pending, (state) => {
        state.isCreatedContent = false;
        state.loading = true;
        state.error = null;
      })
      .addCase(
        contentCreateEditDiameterSteinlessSteel.fulfilled,
        (state, action) => {
          if (action.payload.id !== null && action.payload.id > 0) {
            state.error = null;
            if (state.listDiametersSteinlessSteel !== null) {
              const newList = [...state.listDiametersSteinlessSteel];
              const ind = newList.findIndex(
                (item) => item.id === action.payload.id
              );
              if (ind > -1) {
                newList[ind] = {
                  ...action.payload,
                };
                state.listDiametersSteinlessSteel = [...newList];
                state.isEditedContent = true;
              } else {
                newList.push(action.payload);
                state.listDiametersSteinlessSteel = [...newList];
                state.listDiametersSteinlessSteelTotal =
                  state.listDiametersSteinlessSteel.length;
                state.isCreatedContent = true;
              }
            } else {
              state.listDiametersSteinlessSteel = [action.payload];
              state.listDiametersSteinlessSteelTotal = 1;
              state.isCreatedContent = true;
            }
          }
          if (
            action.payload.status &&
            action.payload.status > 0 &&
            action.payload.error
          ) {
            state.error = action.payload.error;
          }

          state.loading = false;
        }
      )
      .addCase(contentCreateEditDiameterSteinlessSteel.rejected, (state) => {
        state.loading = false;
        state.error =
          "Request create/edit content diameter steinless steel is fail!";
      })
      .addCase(contentDeleteDiameterSteinlessSteel.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        contentDeleteDiameterSteinlessSteel.fulfilled,
        (state, action) => {
          if (action.payload && action.payload.id > 0) {
            state.error = null;

            if (state.listDiametersSteinlessSteel !== null) {
              state.listDiametersSteinlessSteel =
                state.listDiametersSteinlessSteel.filter(
                  (item) => item.id !== action.payload.id
                );
            }
          }

          state.loading = false;
        }
      )
      .addCase(contentDeleteDiameterSteinlessSteel.rejected, (state) => {
        state.loading = false;
        state.error =
          "Request delete content diameter steinless steel is fail!";
      })
      .addCase(contentCreateEditBend.pending, (state) => {
        state.isCreatedContent = false;
        state.loading = true;
        state.error = null;
      })
      .addCase(contentCreateEditBend.fulfilled, (state, action) => {
        if (
          Array.isArray(action.payload) &&
          action.payload.length === 1 &&
          action.payload[0].id !== null
        ) {
          state.error = null;
          if (state.listBends !== null) {
            const newList = [...state.listBends];
            const ind = newList.findIndex(
              (item) => item.id === action.payload[0].id
            );
            if (ind > -1) {
              newList[ind] = {
                ...action.payload[0],
              };
              state.listBends = [...newList];
              state.isEditedContent = true;
            } else {
              state.listBends = [...state.listBends, ...action.payload];
              state.listBendsTotal = state.listBends.length;
              state.isCreatedContent = true;
            }
          } else {
            state.listBends = action.payload;
            state.listBendsTotal = 1;
            state.isCreatedContent = true;
          }
        }
        if (
          action.payload.status &&
          action.payload.status > 0 &&
          action.payload.error
        ) {
          state.error = action.payload.error;
        }

        state.loading = false;
      })
      .addCase(contentCreateEditBend.rejected, (state) => {
        state.loading = false;
        state.error = "Request create/edit content bend diameter is fail!";
      })
      .addCase(contentEditMissingInformation.pending, (state) => {
        state.isEditedMissingText = false;
        state.loading = true;
        state.error = null;
      })
      .addCase(contentEditMissingInformation.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.error = null;
          state.isEditedMissingText = true;
        }
        if (
          action.payload.status &&
          action.payload.status > 0 &&
          action.payload.error
        ) {
          state.error = action.payload.error;
        }

        state.loading = false;
      })
      .addCase(contentEditMissingInformation.rejected, (state) => {
        state.loading = false;
        state.error = "Request edit content missing text is fail!";
      })
      .addCase(contentEditGeneralInformation.pending, (state) => {
        state.isEditedGeneralText = false;
        state.loading = true;
        state.savingGeneralText = true;
        state.error = null;
      })
      .addCase(contentEditGeneralInformation.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.error = null;
          state.isEditedGeneralText = true;
          state.generalText = action.payload.text;
        }
        if (
          action.payload.status &&
          action.payload.status > 0 &&
          action.payload.error
        ) {
          state.error = action.payload.error;
        }

        state.loading = false;
        state.savingGeneralText = false;
      })
      .addCase(contentEditGeneralInformation.rejected, (state) => {
        state.loading = false;
        state.savingGeneralText = false;
        state.error = "Request edit content general text is fail!";
      })
      .addCase(contentCreateEditReason.pending, (state) => {
        state.isCreatedContent = false;
        state.loading = true;
        state.error = null;
      })
      .addCase(contentCreateEditReason.fulfilled, (state, action) => {
        if (action.payload.id !== null && action.payload.id > 0) {
          state.error = null;
          if (state.listReasons !== null) {
            const newList = [...state.listReasons];
            const ind = newList.findIndex(
              (item) => item.id === action.payload.id
            );
            if (ind > -1) {
              newList[ind] = {
                ...action.payload,
              };
              state.listReasons = [...newList];
              state.isEditedContent = true;
            } else {
              newList.push(action.payload);
              state.listReasons = [...newList];
              state.listReasonsTotal = state.listReasons.length;
              state.isCreatedContent = true;
            }
          } else {
            state.listReasons = [action.payload];
            state.listReasonsTotal = 1;
            state.isCreatedContent = true;
          }
        }
        if (
          action.payload.status &&
          action.payload.status > 0 &&
          action.payload.error
        ) {
          state.error = action.payload.error;
        }

        state.loading = false;
      })
      .addCase(contentCreateEditReason.rejected, (state) => {
        state.loading = false;
        state.error = "Request create/edit content reason is fail!";
      })
      .addCase(contentDeleteReason.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(contentDeleteReason.fulfilled, (state, action) => {
        if (action.payload && action.payload.id > 0) {
          state.error = null;

          if (state.listReasons !== null) {
            state.listReasons = state.listReasons.filter(
              (item) => item.id !== action.payload.id
            );
          }
        }

        state.loading = false;
      })
      .addCase(contentDeleteReason.rejected, (state) => {
        state.loading = false;
        state.error = "Request delete content reason is fail!";
      });
  },
});

export const {
  cleanError,
  resetEditGeneralInformation,
  resetEditMissingInformation,
  resetCreatedContent,
} = contentManagementSlice.actions;

export type { Bend, DiameterSteel, DiameterStSteel, Grade, Reason };

export default contentManagementSlice.reducer;
