enum TypesButton {
  primary,
  secondary,
  outline,
  link,
  accept,
  reject,
  modal,
  "modal-outline",
  popup,
  "popup-outline",
}

enum Permissions {
  CALCULATION_FACTORS,
  LIFETIME,
  LIMITED_WRAPPING_SPACE,
  MAWP,
  NETWORK_PRESSURIZED_VESSEL,
  NETWORK_STORAGE_TANK,
  OPTIONAL_DESIGN_CONSIDERATION,
  PIPE_TYPE_TEE,
}

export { Permissions, TypesButton };
