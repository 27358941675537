export const scoringByUser = (
  endDate: string,
  startDate: string,
  id: number
) => {
  let str = "";

  const queryParams = [];
  if (endDate !== "") {
    queryParams.push(`end_at=${endDate}`);
  }
  if (startDate !== "") {
    queryParams.push(`start_at=${startDate}`);
  }

  if (queryParams.length > 0) {
    str += `end_at=${endDate}&start_at=${startDate}&user_id=${id}`;
  } else {
    str += `user_id=${id}`;
  }
  return str;
};

export const corelationDataURL = (
  endDate: string,
  geozone: string,
  startDate: string,
  activeButton: string
) => {
  let str = "";

  const queryParams = [];

  if (endDate !== "") {
    queryParams.push(`end_at=${endDate}`);
  }
  if (geozone !== "") {
    queryParams.push(`geo_zone=${geozone}`);
  }
  if (startDate !== "") {
    queryParams.push(`start_at=${startDate}`);
  }

  if (queryParams.length > 0) {
    str += `end_at=${endDate}&start_at=${startDate}&user_type=${activeButton}`;
  } else {
    str += `user_type=${activeButton}`;
  }

  return str;
};

export const geoFilter = (
  endDate: string,
  geozone: string,
  startDate: string,
  activeButton: string
) => {
  let str = "";

  const queryParams = [];

  if (endDate !== "") {
    queryParams.push(`end_at=${endDate}`);
  }
  if (geozone !== "") {
    queryParams.push(`geo_zone=${geozone}`);
  }
  if (startDate !== "") {
    queryParams.push(`start_at=${startDate}`);
  }

  if (queryParams.length > 0) {
    str += `${queryParams.join("&")}`;
  }

  if (activeButton !== "") {
    str += `&user_type=${activeButton}`;
  }

  return str;
};

export const dataFilterUrl = (
  startDate: string,
  endDate: string,
  geozone: string | null,
  location: string | null,
  tapeRef: string | null,
  product: string | null,
  resinRef: string | null,
  fillerRef: string | null,
  primerRef: string | null,
  userType: string
) => {
  let str = "";

  const queryParams = [];

  if (
    endDate !== "" ||
    fillerRef !== null ||
    geozone !== null ||
    location !== null ||
    primerRef !== null ||
    product !== null ||
    resinRef !== null ||
    startDate !== "" ||
    tapeRef !== null ||
    userType !== ""
  ) {
    if (endDate !== "") {
      queryParams.push(`end_at=${endDate}`);
    }
    if (fillerRef !== null) {
      queryParams.push(`filler_ref=${fillerRef}`);
    }
    if (geozone !== null) {
      queryParams.push(`geo_zone=${geozone}`);
    }
    if (location !== null) {
      queryParams.push(`location=${location}`);
    }
    if (primerRef !== null) {
      queryParams.push(`primer_ref=${primerRef}`);
    }
    if (product !== null) {
      queryParams.push(`product=${product}`);
    }
    if (resinRef !== null) {
      queryParams.push(`resin_ref=${resinRef}`);
    }
    if (startDate !== "") {
      queryParams.push(`start_at=${startDate}`);
    }
    if (tapeRef !== null) {
      queryParams.push(`tape_ref=${tapeRef}`);
    }
  }

  if (queryParams.length > 0) {
    str += `${queryParams.join("&")}`;
  }

  if (userType !== "") {
    str += `&user_type=${userType}`;
  }

  return str;
};
