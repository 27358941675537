import { FC, useState } from "react";
import {
  Button,
  ButtonGroup,
  FormControlLabel,
  Box,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";

import { useAppDispatch, useAppSelector } from "app/hook";

import { geoFilter } from "helpers/createUrl";

import { TypesButton } from "types";

import { Button as ButtonCustom } from "components/library";

import { usersData } from "../../../../constants";

import { getFiltredUsers } from "../../statistics-slice";

import CustomDate from "../date-range-picker/datePicker";

import "../dataFilter/dataFilter.scss";

const UsersFilter: FC = () => {
  const {
    geoZones,
    numberOfUsers,
    numberOfConnectedUsers,
    persentOfConnectedUsers,
  } = useAppSelector((state) => state.statistics);

  const [geozone, setGeoZone] = useState<string>("");
  const [usersActiveButton, setUsersActiveButton] = useState<string>("ALL");
  const [dates, setDates] = useState<string[]>(["", ""]);
  const dispatch = useAppDispatch();

  const usersItemValue = (id: string, value: number | string) => {
    if (id === "users_number") {
      value = numberOfUsers;
    } else if (id === "connected_users") {
      value = numberOfConnectedUsers;
    } else {
      value = persentOfConnectedUsers.toFixed(1) + "%";
    }

    return value;
  };

  const onApplyUsersFilter = () =>
    dispatch(
      getFiltredUsers(geoFilter(dates[1], geozone, dates[0], usersActiveButton))
    );

  return (
    <>
      <Box
        className="users__filter--control"
        sx={{ width: "100%", minHeight: "359px" }}
      >
        <Box className="title">
          <Typography component="span" className="main__title">
            Users
          </Typography>
          <Typography component="span">
            Filter data to see correct results
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            mt: "50px",
            ml: "30px",
            mr: "31px",
            justifyContent: "space-between",
          }}
        >
          <Box className="user__role" sx={{ flexWrap: "wrap", width: "35%" }}>
            <Typography component="p" sx={{ mb: "8px" }}>
              User role
            </Typography>
            <ButtonGroup
              className="btn-group"
              sx={{ width: "100%" }}
              variant="contained"
            >
              <Button
                className={`${usersActiveButton === "ALL" ? "BtnActive" : ""} `}
                onClick={() => setUsersActiveButton("ALL")}
                sx={{ width: "40%" }}
              >
                All
              </Button>
              <Button
                className={`${
                  usersActiveButton === "_3X_USERS" ? "BtnActive" : ""
                } `}
                onClick={() => setUsersActiveButton("_3X_USERS")}
                sx={{ width: "80%", whiteSpace: "nowrap" }}
              >
                3X Users
              </Button>
              <Button
                className={`${
                  usersActiveButton === "DISTRIBUTORS" ? "BtnActive" : ""
                } `}
                onClick={() => setUsersActiveButton("DISTRIBUTORS")}
                sx={{ width: "80%", whiteSpace: "nowrap" }}
              >
                Distributors
              </Button>
            </ButtonGroup>
          </Box>
          <Box component="form" className="form">
            <FormControlLabel
              sx={{ width: "50%" }}
              label={"Geozone"}
              className="user__form-control"
              control={
                <Select
                  sx={{ width: "100%" }}
                  displayEmpty
                  renderValue={(value) =>
                    value?.length
                      ? Array.isArray(value)
                        ? value.join(", ")
                        : value
                      : "Enter Geozone"
                  }
                  value={geozone}
                  onChange={(e) => setGeoZone(e.target.value)}
                >
                  {geoZones !== null &&
                    geoZones.map((item) => {
                      return (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      );
                    })}
                </Select>
              }
            />

            <FormControlLabel
              sx={{ width: "50%", maxWidth: "50%" }}
              className="user__form-control"
              label={"Date"}
              control={<CustomDate setDates={setDates} />}
            />
          </Box>
        </Box>
        <Box className="box-row">
          <ButtonCustom
            type={TypesButton.primary}
            text="Apply filter settings"
            handleClick={() => onApplyUsersFilter()}
            size={275}
          />
        </Box>
      </Box>
      <Box className="statistic-total-values">
        {usersData.map((item) => {
          return (
            <Box
              className={`statistic-total-values_item ${item.css}
              ${item.image ? "" : "without-image"}`}
              key={item.id}
            >
              {item.image ? (
                <Box className="image-wrapp">
                  <img src={item.image} alt={item.id} />
                </Box>
              ) : (
                <Box className="empty"></Box>
              )}
              <Typography component="span" className="title">
                {item.text}
              </Typography>
              <Typography component="span" className="total-value">
                {(item.value = usersItemValue(item.id, item.value))}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </>
  );
};

export default UsersFilter;
