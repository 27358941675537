import { Outlet } from "react-router-dom";
import Container from "@mui/material/Container";

import { Header, MainMenu } from "components/layout";

import "./layout.scss";

const Layout = () => {
  return (
    <Container className="layout" disableGutters maxWidth={false}>
      <Header />
      <div className="layout-wrap">
        <aside className="layout-menu">
          <MainMenu />
        </aside>
        <main className="layout-main">
          <Outlet />
        </main>
      </div>
    </Container>
  );
};

export default Layout;
