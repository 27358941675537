import { useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";

import { useAppDispatch, useAppSelector } from "app/hook";
import { TypesButton } from "types";
import {
  BootstrapDialog,
  BootstrapDialogTitle,
  Button,
} from "components/library";

import {
  cleanError,
  DiameterSteel,
  DiameterStSteel,
  contentCreateEditDiameterSteel,
  contentCreateEditDiameterSteinlessSteel,
  resetCreatedContent,
} from "features/content-management/content-management-slice";

import "./modal-add-diameter.scss";

type ModalAddDiameterErrors = {
  real_diameter?: string;
  nominal_diameter?: string;
};

type ModalAddDiameterProps = {
  show: boolean;
  handleClose: Function;
  diameterSteel: DiameterSteel | null;
  diameterStSteel: DiameterStSteel | null;
  type: string;
};

const ModalAddDiameter = ({
  show,
  handleClose,
  diameterSteel,
  diameterStSteel,
  type,
}: ModalAddDiameterProps) => {
  const { error, isCreatedContent, isEditedContent } = useAppSelector(
    (state) => state.contentManagement
  );

  const [formSteel, setFormSteel] = useState(
    diameterSteel === null
      ? {
          id: null,
          real_diameter: 0,
          nominal_diameter: 0,
          schedule_100: null,
          schedule_120: null,
          schedule_140: null,
          schedule_160: null,
          schedule__10: null,
          schedule__20: null,
          schedule__30: null,
          schedule__40: null,
          schedule__5: null,
          schedule__60: null,
          schedule__80: null,
          schedule_std: null,
          schedule_xs: null,
          schedule_xxs: null,
        }
      : {
          id: diameterSteel.id,
          real_diameter: diameterSteel.real_diameter,
          nominal_diameter: diameterSteel.nominal_diameter,
          schedule_100: diameterSteel.schedule_100,
          schedule_120: diameterSteel.schedule_120,
          schedule_140: diameterSteel.schedule_140,
          schedule_160: diameterSteel.schedule_160,
          schedule__10: diameterSteel.schedule__10,
          schedule__20: diameterSteel.schedule__20,
          schedule__30: diameterSteel.schedule__30,
          schedule__40: diameterSteel.schedule__40,
          schedule__5: diameterSteel.schedule__5,
          schedule__60: diameterSteel.schedule__60,
          schedule__80: diameterSteel.schedule__80,
          schedule_std: diameterSteel.schedule_std,
          schedule_xs: diameterSteel.schedule_xs,
          schedule_xxs: diameterSteel.schedule_xxs,
        }
  );

  const [formStSteel, setFormStSteel] = useState(
    diameterStSteel === null
      ? {
          id: null,
          real_diameter: 0,
          nominal_diameter: 0,
          schedule__10: null,
          schedule__40: null,
          schedule__5: null,
          schedule__80: null,
        }
      : {
          id: diameterStSteel.id,
          real_diameter: diameterStSteel.real_diameter,
          nominal_diameter: diameterStSteel.nominal_diameter,
          schedule__10: diameterStSteel.schedule__10,
          schedule__40: diameterStSteel.schedule__40,
          schedule__5: diameterStSteel?.schedule__5,
          schedule__80: diameterStSteel?.schedule__80,
        }
  );

  const [errors, setErrors] = useState<ModalAddDiameterErrors>({});

  const dispatch = useAppDispatch();

  const setFieldSteel = (field: string, value: number) => {
    setFormSteel({
      ...formSteel,
      [field]: value,
    });

    if (!!errors[field as keyof ModalAddDiameterErrors]) {
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };

  const setFieldStSteel = (field: string, value: number) => {
    setFormStSteel({
      ...formStSteel,
      [field]: value,
    });

    if (!!errors[field as keyof ModalAddDiameterErrors]) {
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };

  const validateFormSteel = () => {
    const { nominal_diameter, real_diameter } = formSteel;
    const newErrors: ModalAddDiameterErrors = {};

    if (!nominal_diameter || nominal_diameter === 0)
      newErrors.nominal_diameter = "Please enter nominal diameter";
    if (!real_diameter || real_diameter === 0)
      newErrors.real_diameter = "Please enter real diameter";

    return newErrors;
  };

  const validateFormStSteel = () => {
    const { nominal_diameter, real_diameter } = formStSteel;
    const newErrors: ModalAddDiameterErrors = {};

    if (!nominal_diameter || nominal_diameter === 0)
      newErrors.nominal_diameter = "Please enter nominal diameter";
    if (!real_diameter || real_diameter === 0)
      newErrors.real_diameter = "Please enter real diameter";

    return newErrors;
  };

  const handleCloseModal = () => {
    handleClose(false);
    dispatch(cleanError());
    dispatch(resetCreatedContent());
  };

  const handleSave = () => {
    const formErrors =
      type === "STEEL" ? validateFormSteel() : validateFormStSteel();

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      if (type === "STEEL") {
        dispatch(contentCreateEditDiameterSteel({ ...formSteel }));
      }
      if (type === "STEINLESS_STEEL") {
        dispatch(contentCreateEditDiameterSteinlessSteel({ ...formStSteel }));
      }
      setErrors({});
    }
  };

  if (type === "STEEL") {
    return (
      <BootstrapDialog
        onClose={() => handleCloseModal()}
        aria-labelledby="customized-add-diameter"
        open={show}
        className="modal-add-diameter"
      >
        <BootstrapDialogTitle
          id="customized-add-diameter"
          onClose={() => handleCloseModal()}
        >
          {diameterSteel === null
            ? "Add new Pipe dimension"
            : "Edit pipe dimension"}
        </BootstrapDialogTitle>
        <DialogContent className="modal-add-diameter_body">
          <form className="modal-add-diameter_body_form" autoComplete="off">
            <div className="modal-add-diameter_body_form_row">
              <FormControlLabel
                className="modal-add-diameter_body_form_control-label"
                control={
                  <TextField
                    type="number"
                    // InputProps={{
                    //   inputProps: {
                    //     max: 1000,
                    //     min: 0,
                    //   },
                    // }}
                    error={errors.nominal_diameter ? true : false}
                    id="nominal_diameter"
                    value={formSteel.nominal_diameter}
                    onChange={(e) => {
                      setFieldSteel(
                        "nominal_diameter",
                        parseFloat(e.target.value)
                      );
                    }}
                    required
                    helperText={errors.nominal_diameter}
                    sx={{
                      marginTop: "0px",
                      marginBottom: "0px",
                    }}
                  />
                }
                label={"Nominal diameter"}
                labelPlacement="start"
                value="nominal_diameter"
              />
              <FormControlLabel
                className="modal-add-diameter_body_form_control-label"
                control={
                  <TextField
                    type="number"
                    // InputProps={{
                    //   inputProps: {
                    //     max: 100,
                    //     min: 0,
                    //   },
                    // }}
                    error={errors.real_diameter ? true : false}
                    id="real_diameter"
                    value={formSteel.real_diameter}
                    onChange={(e) => {
                      setFieldSteel(
                        "real_diameter",
                        parseFloat(e.target.value)
                      );
                    }}
                    required
                    helperText={errors.real_diameter}
                    sx={{
                      marginTop: "0px",
                      marginBottom: "0px",
                    }}
                  />
                }
                label={"Real diameter"}
                labelPlacement="start"
                value="real_diameter"
              />
            </div>
            <div className="modal-add-diameter_body_form_row">
              <FormControlLabel
                className="modal-add-diameter_body_form_control-label not-require"
                control={
                  <TextField
                    type="number"
                    // InputProps={{
                    //   inputProps: {
                    //     max: 100,
                    //     min: 0,
                    //   },
                    // }}
                    // error={errors.schedule__5 ? true : false}
                    id="schedule__5"
                    value={formSteel.schedule__5}
                    onChange={(e) => {
                      setFieldSteel("schedule__5", parseFloat(e.target.value));
                    }}
                    // helperText={errors.schedule__5}
                    sx={{
                      marginTop: "0px",
                      marginBottom: "0px",
                    }}
                  />
                }
                label={"5"}
                labelPlacement="start"
                value="schedule__5"
              />
              <FormControlLabel
                className="modal-add-diameter_body_form_control-label not-require"
                control={
                  <TextField
                    type="number"
                    // InputProps={{
                    //   inputProps: {
                    //     max: 100,
                    //     min: 0,
                    //   },
                    // }}
                    // error={errors.schedule__10 ? true : false}
                    id="schedule__10"
                    value={formSteel.schedule__10}
                    onChange={(e) => {
                      setFieldSteel("schedule__10", parseFloat(e.target.value));
                    }}
                    // helperText={errors.schedule__10}
                    sx={{
                      marginTop: "0px",
                      marginBottom: "0px",
                    }}
                  />
                }
                label={"10"}
                labelPlacement="start"
                value="schedule__10"
              />

              <FormControlLabel
                className="modal-add-diameter_body_form_control-label not-require"
                control={
                  <TextField
                    type="number"
                    // InputProps={{
                    //   inputProps: {
                    //     max: 100,
                    //     min: 0,
                    //   },
                    // }}
                    // error={errors.schedule__20 ? true : false}
                    id="schedule__20"
                    value={formSteel.schedule__20}
                    onChange={(e) => {
                      setFieldSteel("schedule__20", parseFloat(e.target.value));
                    }}
                    // helperText={errors.schedule__20}
                    sx={{
                      marginTop: "0px",
                      marginBottom: "0px",
                    }}
                  />
                }
                label={"20"}
                labelPlacement="start"
                value="schedule__20"
              />
              <FormControlLabel
                className="modal-add-diameter_body_form_control-label not-require"
                control={
                  <TextField
                    type="number"
                    // InputProps={{
                    //   inputProps: {
                    //     max: 100,
                    //     min: 0,
                    //   },
                    // }}
                    // error={errors.schedule__30 ? true : false}
                    id="schedule__30"
                    value={formSteel.schedule__30}
                    onChange={(e) => {
                      setFieldSteel("schedule__30", parseFloat(e.target.value));
                    }}
                    // helperText={errors.schedule__30}
                    sx={{
                      marginTop: "0px",
                      marginBottom: "0px",
                    }}
                  />
                }
                label={"30"}
                labelPlacement="start"
                value="schedule__30"
              />
            </div>

            <div className="modal-add-diameter_body_form_row">
              <FormControlLabel
                className="modal-add-diameter_body_form_control-label not-require"
                control={
                  <TextField
                    type="number"
                    // InputProps={{
                    //   inputProps: {
                    //     max: 100,
                    //     min: 0,
                    //   },
                    // }}
                    // error={errors.schedule__40 ? true : false}
                    id="schedule__40"
                    value={formSteel.schedule__40}
                    onChange={(e) => {
                      setFieldSteel("schedule__40", parseFloat(e.target.value));
                    }}
                    // helperText={errors.schedule__40}
                    sx={{
                      marginTop: "0px",
                      marginBottom: "0px",
                    }}
                  />
                }
                label={"40"}
                labelPlacement="start"
                value="schedule__40"
              />
              <FormControlLabel
                className="modal-add-diameter_body_form_control-label not-require"
                control={
                  <TextField
                    type="number"
                    // InputProps={{
                    //   inputProps: {
                    //     max: 100,
                    //     min: 0,
                    //   },
                    // }}
                    // error={errors.schedule__60 ? true : false}
                    id="schedule__60"
                    value={formSteel.schedule__60}
                    onChange={(e) => {
                      setFieldSteel("schedule__60", parseFloat(e.target.value));
                    }}
                    // helperText={errors.schedule__60}
                    sx={{
                      marginTop: "0px",
                      marginBottom: "0px",
                    }}
                  />
                }
                label={"60"}
                labelPlacement="start"
                value="schedule__60"
              />

              <FormControlLabel
                className="modal-add-diameter_body_form_control-label not-require"
                control={
                  <TextField
                    type="number"
                    // InputProps={{
                    //   inputProps: {
                    //     max: 100,
                    //     min: 0,
                    //   },
                    // }}
                    // error={errors.schedule__80 ? true : false}
                    id="schedule__80"
                    value={formSteel.schedule__80}
                    onChange={(e) => {
                      setFieldSteel("schedule__80", parseFloat(e.target.value));
                    }}
                    // helperText={errors.schedule__80}
                    sx={{
                      marginTop: "0px",
                      marginBottom: "0px",
                    }}
                  />
                }
                label={"80"}
                labelPlacement="start"
                value="schedule__80"
              />
              <FormControlLabel
                className="modal-add-diameter_body_form_control-label not-require"
                control={
                  <TextField
                    type="number"
                    // InputProps={{
                    //   inputProps: {
                    //     max: 100,
                    //     min: 0,
                    //   },
                    // }}
                    // error={errors.schedule_100 ? true : false}
                    id="schedule_100"
                    value={formSteel.schedule_100}
                    onChange={(e) => {
                      setFieldSteel("schedule_100", parseFloat(e.target.value));
                    }}
                    required
                    // helperText={errors.schedule_100}
                    sx={{
                      marginTop: "0px",
                      marginBottom: "0px",
                    }}
                  />
                }
                label={"100"}
                labelPlacement="start"
                value="schedule_100"
              />
            </div>
            <div className="modal-add-diameter_body_form_row">
              <FormControlLabel
                className="modal-add-diameter_body_form_control-label not-require"
                control={
                  <TextField
                    type="number"
                    // InputProps={{
                    //   inputProps: {
                    //     max: 100,
                    //     min: 0,
                    //   },
                    // }}
                    // error={errors.schedule_120 ? true : false}
                    id="schedule_120"
                    value={formSteel.schedule_120}
                    onChange={(e) => {
                      setFieldSteel("schedule_120", parseFloat(e.target.value));
                    }}
                    // helperText={errors.schedule_120}
                    sx={{
                      marginTop: "0px",
                      marginBottom: "0px",
                    }}
                  />
                }
                label={"120"}
                labelPlacement="start"
                value="schedule_120"
              />

              <FormControlLabel
                className="modal-add-diameter_body_form_control-label not-require"
                control={
                  <TextField
                    type="number"
                    // InputProps={{
                    //   inputProps: {
                    //     max: 100,
                    //     min: 0,
                    //   },
                    // }}
                    // error={errors.schedule_140 ? true : false}
                    id="schedule_140"
                    value={formSteel.schedule_140}
                    onChange={(e) => {
                      setFieldSteel("schedule_140", parseFloat(e.target.value));
                    }}
                    required
                    // helperText={errors.schedule_140}
                    sx={{
                      marginTop: "0px",
                      marginBottom: "0px",
                    }}
                  />
                }
                label={"140"}
                labelPlacement="start"
                value="schedule_140"
              />
              <FormControlLabel
                className="modal-add-diameter_body_form_control-label not-require"
                control={
                  <TextField
                    type="number"
                    // InputProps={{
                    //   inputProps: {
                    //     max: 100,
                    //     min: 0,
                    //   },
                    // }}
                    // error={errors.schedule_160 ? true : false}
                    id="schedule_160"
                    value={formSteel.schedule_160}
                    onChange={(e) => {
                      setFieldSteel("schedule_160", parseFloat(e.target.value));
                    }}
                    // helperText={errors.schedule_160}
                    sx={{
                      marginTop: "0px",
                      marginBottom: "0px",
                    }}
                  />
                }
                label={"160"}
                labelPlacement="start"
                value="schedule_160"
              />
            </div>

            <FormControlLabel
              className="modal-add-diameter_body_form_control-label not-require"
              control={
                <TextField
                  type="number"
                  // InputProps={{
                  //   inputProps: {
                  //     max: 100,
                  //     min: 0,
                  //   },
                  // }}
                  // error={errors.schedule_std ? true : false}
                  id="schedule_std"
                  value={formSteel.schedule_std}
                  onChange={(e) => {
                    setFieldSteel("schedule_std", parseFloat(e.target.value));
                  }}
                  // helperText={errors.schedule_std}
                  sx={{
                    marginTop: "0px",
                    marginBottom: "0px",
                  }}
                />
              }
              label={"STD"}
              labelPlacement="start"
              value="schedule_std"
            />
            <FormControlLabel
              className="modal-add-diameter_body_form_control-label not-require"
              control={
                <TextField
                  type="number"
                  // InputProps={{
                  //   inputProps: {
                  //     max: 100,
                  //     min: 0,
                  //   },
                  // }}
                  // error={errors.schedule_xs ? true : false}
                  id="schedule_xs"
                  value={formSteel.schedule_xs}
                  onChange={(e) => {
                    setFieldSteel("schedule_xs", parseFloat(e.target.value));
                  }}
                  // helperText={errors.schedule_xs}
                  sx={{
                    marginTop: "0px",
                    marginBottom: "0px",
                  }}
                />
              }
              label={"XS"}
              labelPlacement="start"
              value="schedule_xs"
            />

            <FormControlLabel
              className="modal-add-diameter_body_form_control-label not-require"
              control={
                <TextField
                  type="number"
                  // InputProps={{
                  //   inputProps: {
                  //     max: 100,
                  //     min: 0,
                  //   },
                  // }}
                  // error={errors.schedule_xxs ? true : false}
                  id="schedule_xxs"
                  value={formSteel.schedule_xxs}
                  onChange={(e) => {
                    setFieldSteel("schedule_xxs", parseFloat(e.target.value));
                  }}
                  // helperText={errors.schedule_xxs}
                  sx={{
                    marginTop: "0px",
                    marginBottom: "0px",
                  }}
                />
              }
              label={"XXS"}
              labelPlacement="start"
              value="schedule_xxs"
            />

            <div className="modal-add-diameter_body_form_wrap-btns">
              <Button
                type={TypesButton["modal-outline"]}
                handleClick={() => handleCloseModal()}
                text="Cancel"
              />
              <Button
                type={TypesButton.modal}
                text="Save"
                handleClick={() => handleSave()}
              />
            </div>
          </form>
          {isCreatedContent && (
            <div className="modal-add-diameter_body_form_success-text">
              Diameter successfully created
            </div>
          )}
          {isEditedContent && diameterSteel !== null && (
            <div className="modal-add-diameter_body_form_success-text">
              Diameter successfully edited
            </div>
          )}
          {error !== null && (
            <div className="modal-add-diameter_body_form_error-text">
              {error}
            </div>
          )}
        </DialogContent>
      </BootstrapDialog>
    );
  }

  return (
    <BootstrapDialog
      onClose={() => handleCloseModal()}
      aria-labelledby="customized-add-diameter"
      open={show}
      className="modal-add-diameter"
    >
      <BootstrapDialogTitle
        id="customized-add-diameter"
        onClose={() => handleCloseModal()}
      >
        {diameterStSteel === null
          ? "Add new Pipe dimensions	stainless steel"
          : "Edit Pipe dimensions	stainless steel"}
      </BootstrapDialogTitle>
      <DialogContent
        className="modal-add-diameter_body"
        style={{ height: "400px" }}
      >
        <form className="modal-add-diameter_body_form" autoComplete="off">
          <div className="modal-add-diameter_body_form_row">
            <FormControlLabel
              className="modal-add-diameter_body_form_control-label"
              control={
                <TextField
                  type="number"
                  // InputProps={{
                  //   inputProps: {
                  //     max: 1000,
                  //     min: 0,
                  //   },
                  // }}
                  error={errors.nominal_diameter ? true : false}
                  id="nominal_diameter"
                  value={formStSteel.nominal_diameter}
                  onChange={(e) => {
                    setFieldStSteel(
                      "nominal_diameter",
                      parseFloat(e.target.value)
                    );
                  }}
                  required
                  helperText={errors.nominal_diameter}
                  sx={{
                    marginTop: "0px",
                    marginBottom: "0px",
                  }}
                />
              }
              label={"Nominal diameter"}
              labelPlacement="start"
              value="nominal_diameter"
            />
            <FormControlLabel
              className="modal-add-diameter_body_form_control-label"
              control={
                <TextField
                  type="number"
                  // InputProps={{
                  //   inputProps: {
                  //     max: 100,
                  //     min: 0,
                  //   },
                  // }}
                  error={errors.real_diameter ? true : false}
                  id="real_diameter"
                  value={formStSteel.real_diameter}
                  onChange={(e) => {
                    setFieldStSteel(
                      "real_diameter",
                      parseFloat(e.target.value)
                    );
                  }}
                  required
                  helperText={errors.real_diameter}
                  sx={{
                    marginTop: "0px",
                    marginBottom: "0px",
                  }}
                />
              }
              label={"Real diameter"}
              labelPlacement="start"
              value="real_diameter"
            />
          </div>

          <FormControlLabel
            className="modal-add-diameter_body_form_control-label not-require"
            control={
              <TextField
                type="number"
                // InputProps={{
                //   inputProps: {
                //     max: 100,
                //     min: 0,
                //   },
                // }}
                // error={errors.schedule__5 ? true : false}
                id="schedule__5"
                value={formStSteel.schedule__5}
                onChange={(e) => {
                  setFieldStSteel("schedule__5", parseFloat(e.target.value));
                }}
                // helperText={errors.schedule__5}
                sx={{
                  marginTop: "0px",
                  marginBottom: "0px",
                }}
              />
            }
            label={"5"}
            labelPlacement="start"
            value="schedule__5"
          />
          <FormControlLabel
            className="modal-add-diameter_body_form_control-label not-require"
            control={
              <TextField
                type="number"
                // InputProps={{
                //   inputProps: {
                //     max: 100,
                //     min: 0,
                //   },
                // }}
                // error={errors.schedule__10 ? true : false}
                id="schedule__10"
                value={formStSteel.schedule__10}
                onChange={(e) => {
                  setFieldStSteel("schedule__10", parseFloat(e.target.value));
                }}
                // helperText={errors.schedule__10}
                sx={{
                  marginTop: "0px",
                  marginBottom: "0px",
                }}
              />
            }
            label={"10"}
            labelPlacement="start"
            value="schedule__10"
          />

          <FormControlLabel
            className="modal-add-diameter_body_form_control-label not-require"
            control={
              <TextField
                type="number"
                // InputProps={{
                //   inputProps: {
                //     max: 100,
                //     min: 0,
                //   },
                // }}
                // error={errors.schedule__40 ? true : false}
                id="schedule__40"
                value={formStSteel.schedule__40}
                onChange={(e) => {
                  setFieldStSteel("schedule__40", parseFloat(e.target.value));
                }}
                // helperText={errors.schedule__40}
                sx={{
                  marginTop: "0px",
                  marginBottom: "0px",
                }}
              />
            }
            label={"40"}
            labelPlacement="start"
            value="schedule__40"
          />
          <FormControlLabel
            className="modal-add-diameter_body_form_control-label not-require"
            control={
              <TextField
                type="number"
                // InputProps={{
                //   inputProps: {
                //     max: 100,
                //     min: 0,
                //   },
                // }}
                // error={errors.schedule__80 ? true : false}
                id="schedule__80"
                value={formStSteel.schedule__80}
                onChange={(e) => {
                  setFieldStSteel("schedule__80", parseFloat(e.target.value));
                }}
                // helperText={errors.schedule__80}
                sx={{
                  marginTop: "0px",
                  marginBottom: "0px",
                }}
              />
            }
            label={"80"}
            labelPlacement="start"
            value="schedule__80"
          />

          <div className="modal-add-diameter_body_form_wrap-btns">
            <Button
              type={TypesButton["modal-outline"]}
              handleClick={() => handleCloseModal()}
              text="Cancel"
            />
            <Button
              type={TypesButton.modal}
              text="Save"
              handleClick={() => handleSave()}
            />
          </div>
        </form>
        {isCreatedContent && (
          <div className="modal-add-diameter_body_form_success-text">
            Diameter successfully created
          </div>
        )}
        {isEditedContent && diameterStSteel !== null && (
          <div className="modal-add-diameter_body_form_success-text">
            Diameter successfully edited
          </div>
        )}
        {error !== null && (
          <div className="modal-add-diameter_body_form_error-text">{error}</div>
        )}
      </DialogContent>
    </BootstrapDialog>
  );
};

export default ModalAddDiameter;
