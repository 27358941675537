import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Container from "@mui/material/Container";

import { useAppDispatch, useAppSelector } from "app/hook";
import { authRefreshToken } from "features/auth/auth-slice";

import { Auth } from "features/auth";

import { Layout } from "components/layout";
import {
  Composit,
  Content,
  Filler,
  History,
  Home,
  Primer,
  Proposals,
  Projects,
  General,
  Technical,
  Marketing,
} from "pages";

import "styles/all.scss";

const AppLayout = () => {
  const {
    token: accessToken,
    refreshToken,
    loading,
    email,
    accountType,
  } = useAppSelector((state) => state.loginUser);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!loading && (!accessToken || email === "") && refreshToken) {
      dispatch(authRefreshToken());
    }
  }, [dispatch, accessToken, refreshToken, loading, email]);

  if ((!accessToken && !refreshToken) || !refreshToken) {
    return <Auth />;
  }

  return (
    <Container disableGutters maxWidth={false} className="preload">
      {accountType !== "ADMINISTRATOR" &&
        accountType !== "TECHNICAL_ADMINISTRATOR" && (
          <span className="loader">REA</span>
        )}
      {accountType === "ADMINISTRATOR" && (
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="composit" element={<Composit />} />
            <Route path="content" element={<Content />} />
            <Route path="filler" element={<Filler />} />
            <Route path="primer" element={<Primer />} />
            <Route path="history" element={<History />} />
            <Route path="proposals" element={<Proposals />} />
            <Route path="projects" element={<Projects />} />
            <Route path="general" element={<General />} />
            <Route path="technical" element={<Technical />} />
            <Route path="marketing" element={<Marketing />} />
            <Route path="*" element={<Home />} />
          </Route>
        </Routes>
      )}
      {accountType === "TECHNICAL_ADMINISTRATOR" && (
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Filler />} />
            <Route path="composit" element={<Composit />} />
            <Route path="filler" element={<Filler />} />
            <Route path="primer" element={<Primer />} />
            <Route path="history" element={<History />} />
            <Route path="general" element={<General />} />
            <Route path="technical" element={<Technical />} />
            <Route path="marketing" element={<Marketing />} />
            <Route path="*" element={<Filler />} />
          </Route>
        </Routes>
      )}
    </Container>
  );
};

export default AppLayout;
