import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

export interface Column {
  id: "id" | "name" | "action";
  label: string;
  hide?: boolean;
  minWidth?: number;
  align?: "right";
  //   format?: (value: number) => string;
  renderCell?: (params: any) => React.ReactNode;
}

type Row = {
  id: number | string;
  name: string;
  action: boolean;
};

type BasicTableProps = {
  columns: Column[];
  rows: Row[];
};

const BasicTable = ({ columns, rows }: BasicTableProps) => {
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 285 }}>
        <Table stickyHeader size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              {columns.map((column) => {
                if (!column.hide) {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  );
                }
                return null;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {columns.map((column) => {
                    if (!column.hide) {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.renderCell && typeof value === "boolean"
                            ? column.renderCell(row)
                            : value}
                        </TableCell>
                      );
                    }
                    return null;
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default BasicTable;
