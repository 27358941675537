import UserSvg from "assets/images/user.svg";
import PencilSvg from "assets/images/pencil.svg";
import EyedropperSvg from "assets/images/eyedropper.svg";
import LengthOfPatchSvg from "assets/images/length-of-patch.svg";
import PaymentSvg from "assets/images/payment.svg";
import QuantityOfResinSvg from "assets/images/quantity-of-resin.svg";
import TapeMeasureSvg from "assets/images/tape-measure.svg";
import SettingsSvg from "assets/images/settings.svg";

export const apiUrl = process.env.REACT_APP_API_URL;
export const menuRoutes = [
  "/",
  "/composit",
  "/filler",
  "/primer",
  "/history",
  "/content",
  "/proposals",
  "/projects",
  "/general",
  "/technical",
  "/marketing",
];

export const menuRoutesTech = [
  "/composit",
  "/filler",
  "/primer",
  "/history",
  "/general",
  "/technical",
  "/marketing",
];

export const TapeRef = [
  {
    id: 2,
    name: "NRI_SYNTHOGLASS_XT",
  },
  {
    id: 3,
    name: "NRI_THERMOWRAP",
  },
  {
    id: 4,
    name: "CARBONTECH_RENOWRAP_110",
  },
  {
    id: 5,
    name: "CARBONTECH_RENOWRAP_185",
  },
  {
    id: 6,
    name: "CARBONTECH_RENOWRAP_225",
  },
  {
    id: 7,
    name: "R4D",
  },
  {
    id: 8,
    name: "R2D",
  },
  {
    id: 9,
    name: "R1D",
  },
];

export const primer = [
  {
    id: 1,
    name: "P3X28",
    max_temp: 14,
  },
  {
    id: 2,
    name: "P3X30",
    max_temp: 65,
  },
  {
    id: 3,
    name: "P3X1",
    max_temp: 68,
  },
  {
    id: 4,
    name: "P3X400",
    max_temp: 400,
  },
];

export const usersData = [
  {
    id: "users_number",
    text: "Number of Users",
    value: 0,
    image: UserSvg,
    css: "item4",
  },
  {
    id: "connected_users",
    text: "Number of connected Users ",
    value: 0,
    image: UserSvg,
    css: "item1",
  },
  {
    id: "Percent_users",
    text: "Percent of connected Users % ",
    value: 0 + "%",
    image: UserSvg,
    css: "item7",
  },
];

export const statisticData = [
  {
    id: "distributors",
    text: "Number of created Projects",
    value: 0,
    image: UserSvg,
    css: "item1",
  },
  {
    id: "repaired_cases",
    text: "Number of repair cases",
    value: 0,
    image: PencilSvg,
    css: "item2",
  },
  {
    id: "validated_tech_proposals",
    text: "Number of validated tech proposals for all users ",
    value: 0,
    image: PaymentSvg,
    css: "item3",
  },
  {
    id: "repair_length",
    text: "Total  repair length (m) ",
    value: 0,
    image: SettingsSvg,
    css: "item4",
  },
  {
    id: "tape_length",
    text: "Total used tape length",
    value: 0,
    image: SettingsSvg,
    css: "item5",
  },
  {
    id: "total_number",
    text: "Total number of patches ",
    value: 0,
    image: TapeMeasureSvg,
    css: "item6",
  },
  {
    id: "quantity_resin",
    text: "Total used quantity of Resins (all done projects)",
    value: 0,
    image: QuantityOfResinSvg,
    css: "item7",
  },
  {
    id: "quantity_filler",
    text: "Total quantity of fillers (kg)",
    value: 0,
    image: EyedropperSvg,
    css: "item8",
  },
  {
    id: "quantity_primers",
    text: "Total quantity of primers",
    value: 0,
    image: LengthOfPatchSvg,
    css: "item9",
  },
];

export const pipeInformation = [
  { name: "Fluid", color: "green" },
  { name: "Grade", color: "red" },
  { name: "Network", color: "orange" },
  { name: "Connection", color: "light-blue" },
  { name: "Environment", color: "purple" },
];

export const percentsWithColors = [
  { name: "Oil", percent: 13, color: "#2AD4AC" },
  { name: "Gas", percent: 25, color: "#DA5674" },
  { name: "Water", percent: 76, color: "#F3A50C" },
  { name: "Chemical product", percent: 15, color: "#7B61FF" },
];

export const designConsiderationLifetime = [
  {
    name: "Impact",
    lifetimeName: "1 year",
    color: "#2AD4AC",
  },
  {
    name: "Cycling loading",
    lifetimeName: "2 years",
    color: "#F65761",
  },
  {
    name: "External loads",
    lifetimeName: "10 year",
    color: "#F3A50C",
  },
  {
    name: "Nothing",
    lifetimeName: "Nothing",
    color: "#7B61FF",
  },
];

export const sealingProcedure = [
  { name: "Online repair", color: "#2AD4AC" },
  { name: "Shotdown line repair", color: "#F65761" },
  { name: "No sealing procedure", color: "#7B61FF" },
  { name: "Nothing", color: "#F3A50C" },
];

export const sealingData = {
  labels: [
    "Online repair",
    "Shotdown line repair",
    "No sealing procedure",
    "Nothing",
  ],
  datasets: [
    {
      data: [13.2, 15.6, 22, 38.9],
      backgroundColor: ["#2AD4AC", "#F65761", "#7B61FF", "#F3A50C"],
    },
  ],
};

export const correlationValidatedLine = [0, 20, 0, 30, 0];

export const correlationSentLine = [0, 10, 0, 20, 0];

export const distribData = {
  labels: ["Successfulness", "Productivity", "Activeness"],
  datasets: [
    {
      data: [98, 70, 78],
      barPercentage: 0.3,
      backgroundColor: ["#2AD4AC", "#F65761", "#F3A50C"],
    },
  ],
  scales: {
    stepSize: 25,
  },
};

export const typeOfPipeData = {
  labels: ["Straight", "Bend", "Tee"],
  datasets: [
    {
      data: [56.82, 66.41, 71.02],
      backgroundColor: "#E786D7",
    },
    {
      data: [105.87, 22.45, 122.22],
      backgroundColor: "#7F7FD5",
    },
  ],
};

export const tempratureData = {
  labels: [
    "T ≤ 50°C",
    "50°C < T < 95°C",
    "95°C < T < 150°C",
    "150°C < T < 250°C",
  ],
  datasets: [
    {
      data: [156.82, 166.41, 171.02, 187.3],
      backgroundColor: "#2AD4AC",
    },
    {
      data: [105.87, 122.45, 112.22, 201.2],
      backgroundColor: "#226CAB",
    },
  ],
};

export const productsColors = [
  "#2AD4AC",
  "#F3A50C",
  "#F65761",
  "#7B61FF",
  "#239758",
  "#7F7FD5",
  "#E786D8",
  "#589BDE",
  "#226CAB",
  "#56BADA",
  "#DA5674",
  "#3A236A",
  "#AD9FFF",
  "#00ABAC",
  "#DA68E3",
];

export const productsPatchColors = [
  "#00BC90",
  "#D68D00",
  "#C2424A",
  "#6750DB",
  "#1C7444",
  "#6060A9",
  "#BF62B1",
  "#4477A9",
  "#185181",
  "#41899F",
  "#B44760",
  "#4D2F8C",
  "#D1C9FF",
  "#008E8F",
  "#AD52B5",
];
