import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  registerables,
  ChartOptions,
  ChartData,
} from "chart.js";

ChartJS.register(...registerables);

export type datasets = {
  data: Array<number>;
  backgroundColor: string | Array<string>;
  borderRadius?: number;
  barPercentage?: number;
  stepSize?: number;
};

export type barData = {
  labels: Array<string>;
  datasets: Array<datasets>;
  scales?: {
    stepSize: number;
  };
};

export type BarChartProps = {
  width: number;
  height: number;
  barData: barData;
};

const BarChart: React.FC<BarChartProps> = ({ width, height, barData }) => {
  const datasets = barData.datasets.map((item) => {
    return {
      data: item.data,
      backgroundColor: item.backgroundColor,
      borderRadius: 5,
      barPercentage: 0.6,
      categoryPercentage: item.barPercentage,
    };
  });
  const data: ChartData<"bar"> = {
    labels: barData.labels,
    datasets: datasets,
  };

  const options: ChartOptions<"bar"> = {
    scales: {
      y: {
        max: 100,

        ticks: {
          stepSize: barData.scales?.stepSize,
        },

        display: true,

        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },

    responsive: true,
  };

  return <Bar data={data} options={options} width={width} height={height} />;
};

export default BarChart;
