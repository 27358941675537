import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Switch from "@mui/material/Switch";
import Checkbox from "@mui/material/Checkbox";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";

import { useAppDispatch, useAppSelector } from "app/hook";
import { TypesButton } from "types";
import {
  ModalConfirm,
  Button,
  EditSvg,
  HistorySvg,
  DeleteSvg,
  CopySvg,
} from "components/library";

import {
  Composit,
  Filler,
  getComposits,
  getFillers,
  getPrimers,
  Primer,
  productDeleteComposit,
  productDeleteFiller,
  productDeletePrimer,
  productEditSwitchComposit,
  productEditSwitchCompositStatus,
  productEditSwitchFiller,
  productEditSwitchFillerStatus,
  productEditSwitchPrimer,
  productEditSwitchPrimerStatus,
  productGroupDelete,
} from "./products-management-slice";

import ModalAddComposit from "./components/modal-add-composit";
import ModalAddFiller from "./components/modal-add-filler";
import ModalAddPrimer from "./components/modal-add-primer";
import ModalHistory from "./components/modal-history";

import "./products-management.scss";

type ProductsManagementProps = {
  type: string;
};

const ProductsManagement = ({ type }: ProductsManagementProps) => {
  const { listComposits, listFillers, listPrimers, loading } = useAppSelector(
    (state) => state.productsManagement
  );

  const { email, userId } = useAppSelector((state) => state.loginUser);

  const [showModalAddProduct, setShowModalAddProduct] = useState(false);
  const [showCopyProduct, setShowCopyProduct] = useState(false);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [showModalHistory, setShowModalHistory] = useState(false);
  const [selectedComposit, setSelectedComposit] = useState<Composit | null>(
    null
  );
  const [selectedFiller, setSelectedFiller] = useState<Filler | null>(null);
  const [selectedPrimer, setSelectedPrimer] = useState<Primer | null>(null);
  const [selectedHistory, setSelectedHistory] = useState<{
    id: number;
    type: string;
    name: string;
  } | null>(null);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      userId !== "" &&
      email !== "" &&
      !loading &&
      listComposits === null &&
      type === "composit"
    ) {
      dispatch(getComposits());
    }
  }, [dispatch, email, userId, listComposits, loading, type]);

  useEffect(() => {
    if (
      userId !== "" &&
      email !== "" &&
      !loading &&
      listFillers === null &&
      type === "filler"
    ) {
      dispatch(getFillers());
    }
  }, [dispatch, email, userId, listFillers, loading, type]);

  useEffect(() => {
    if (
      userId !== "" &&
      email !== "" &&
      !loading &&
      listPrimers === null &&
      type === "primer"
    ) {
      dispatch(getPrimers());
    }
  }, [dispatch, email, userId, listPrimers, loading, type]);

  const columnsComposit: GridColDef[] = [
    {
      field: "ACTION",
      sortable: false,
      width: 130,
      disableColumnMenu: true,
      renderCell: (params: any) => {
        return (
          <>
            <IconButton
              className="action-btn hover-btn"
              onClick={() => {
                setSelectedComposit(params.row);
                setShowModalAddProduct(true);
              }}
            >
              <EditSvg />
              <EditSvg isHover />
            </IconButton>
            <IconButton
              className="action-btn hover-btn"
              onClick={() => {
                setSelectedHistory({
                  type: "composit",
                  id: params.row.id,
                  name: params.row.trade_name,
                });
                setShowModalHistory(true);
              }}
            >
              <HistorySvg />
              <HistorySvg isHover />
            </IconButton>
            <IconButton
              className="action-btn hover-btn"
              onClick={() => {
                setSelectedComposit(params.row);
                setShowCopyProduct(true);
                setShowModalAddProduct(true);
              }}
            >
              <CopySvg />
              <CopySvg isHover />
            </IconButton>
            <IconButton
              className="action-btn hover-btn"
              onClick={() => {
                setSelectedComposit(params.row);
                setShowModalConfirm(true);
              }}
            >
              <DeleteSvg />
              <DeleteSvg isHover />
            </IconButton>
          </>
        );
      },
    },
    {
      field: "id",
      headerName: "№",
      width: 75,
      renderCell: (params: any) => {
        return (
          <div>
            {params.api.getRowIndex(params.row.id) + 1}
            <Checkbox />
          </div>
        );
      },
    },

    { field: "trade_name", headerName: "Trade name", width: 250 },
    {
      field: "composite_type",
      headerName: "Type of composite",
      width: 100,
    },
    {
      field: "resin_name",
      headerName: "Resin",
      width: 100,
    },
    {
      field: "size",
      headerName: "Size of tape",
      width: 100,
      renderCell: (params: any) => {
        return (
          <>
            {params.row.composite_length}/{params.row.composite_width}
          </>
        );
      },
    },
    {
      field: "tape_type",
      headerName: "Type of tape",
      width: 100,
    },
    {
      field: "suitable_residual_or_internal_corrosion",
      headerName:
        "Suitable for residual metal<1mm or internal corrosion with metal loss > 80%",
      sortable: false,
      width: 100,
      renderCell: (params: any) => {
        return (
          <>
            <Switch
              checked={params.row.suitable_residual_or_internal_corrosion}
              onChange={(e) => {
                dispatch(
                  productEditSwitchComposit({
                    ...params.row,
                    suitable_residual_or_internal_corrosion: e.target.checked,
                  })
                );
              }}
            />
          </>
        );
      },
    },
    {
      field: "suitable_subsea",
      headerName: "Suitable subsea",
      sortable: false,
      width: 100,
      renderCell: (params: any) => {
        return (
          <>
            <Switch
              checked={params.row.suitable_subsea}
              onChange={(e) => {
                dispatch(
                  productEditSwitchComposit({
                    ...params.row,
                    suitable_subsea: e.target.checked,
                  })
                );
              }}
            />
          </>
        );
      },
    },
    {
      field: "minimum_operating_temperature",
      headerName: "Temp_mini_pipe_C",
      width: 100,
    },
    {
      field: "maximum_operating_temperature",
      headerName: "Temp_maxi_pipe_C",
      width: 100,
    },
    {
      field: "minimum_installation_temperature",
      headerName: "Temp_mini_install_C",
      width: 100,
    },
    {
      field: "maximum_installation_temperature",
      headerName: "Temp_maxi_install_C",
      width: 100,
    },
    {
      field: "tg_c",
      headerName: "Tg",
      width: 50,
    },
    {
      field: "e_c",
      headerName: "El : Laminate longitudinal modulus (Mpa)",
      width: 100,
    },
    {
      field: "e_a",
      headerName: "Et : Laminate transverse modulus (Mpa)",
      width: 100,
    },
    {
      field: "nu_lt",
      headerName: "Nult : Laminate longitudinal Poisson's number (Mpa)",
      width: 100,
    },
    {
      field: "sheer_modulus",
      headerName: "Glt : Shear modulus of the composite Laminate (Mpa)",
      width: 100,
    },
    {
      field: "sigma_lt",
      headerName:
        "Slt : Lower confidence limit of the long-term strength determined by performance testing (N/m2)",
      width: 100,
    },
    {
      field: "composite_thickness",
      headerName: "Thickness of the composite (mm)",
      width: 100,
    },
    {
      field: "nb_resin_tape",
      headerName: "Number of buckets of resin per tape",
      width: 100,
    },
    {
      field: "status",
      headerName: "Status active/inactive",
      sortable: false,
      width: 100,
      renderCell: (params: any) => {
        return (
          <>
            <Switch
              checked={params.row.status}
              onChange={(e) => {
                dispatch(
                  productEditSwitchCompositStatus({
                    id: params.row.id,
                    status: e.target.checked,
                  })
                );
              }}
            />
          </>
        );
      },
    },
    {
      field: "display_for_distributor",
      headerName: "Display for distrib",
      sortable: false,
      width: 100,
      renderCell: (params: any) => {
        return (
          <>
            <Switch
              checked={!!params.row.display_for_distributor}
              onChange={(e) => {
                dispatch(
                  productEditSwitchComposit({
                    ...params.row,
                    display_for_distributor: e.target.checked,
                  })
                );
              }}
            />
          </>
        );
      },
    },
    {
      hide: true,
      field: "epsilon_lt",
      headerName:
        "Epsilonlt : lower confidence limit of the long-term strain determined by performance testing",
      width: 100,
    },
    {
      hide: true,
      field: "alpha_a",
      headerName:
        "Alpha_a : Repair laminate thermal expansion coefficient, axial direction (10-6 x mm/mm.C)",
      width: 100,
    },
    {
      hide: true,
      field: "alpha_c",
      headerName:
        "Alpha_c : Repair laminate thermal expansion coefficient, circumferential direction (10-6 x mm/mm.C)",
      width: 100,
    },

    {
      hide: true,
      field: "g_lcl_1",
      headerName: "Gamma_lcl1: Energy release rate",
      width: 100,
    },
    {
      hide: true,
      field: "g_lcl_2",
      headerName: "Gamma_lcl2: Energy release rate",
      width: 100,
    },
    {
      hide: true,
      field: "g_lcl_3",
      headerName: "Gamma_lcl3: Energy release rate",
      width: 100,
    },
    {
      hide: true,
      field: "tau",
      headerName: "Tau : Lap shear strength",
      width: 100,
    },
    {
      hide: true,
      field: "t_test",
      headerName: "Ttest",
      width: 50,
    },
    {
      hide: true,
      field: "t_amb",
      headerName: "T_amb",
      width: 50,
    },
    {
      hide: true,
      field: "tg_c",
      headerName: "Tg",
      width: 50,
    },
    {
      hide: true,
      field: "performance_data",
      headerName: "Performance data",
      width: 100,
    },
    {
      hide: true,
      field: "color_hexadecimal",
      headerName: "Color hexadecimal",
      width: 100,
    },
  ];

  const columnsFiller: GridColDef[] = [
    {
      field: "ACTION",
      sortable: false,
      width: 130,
      disableColumnMenu: true,
      renderCell: (params: any) => {
        return (
          <>
            <IconButton
              className="action-btn hover-btn"
              onClick={() => {
                setSelectedFiller(params.row);
                setShowModalAddProduct(true);
              }}
            >
              <EditSvg />
              <EditSvg isHover />
            </IconButton>
            <IconButton
              className="action-btn hover-btn"
              onClick={() => {
                setSelectedHistory({
                  type: "filler",
                  id: params.row.id,
                  name: params.row.name,
                });
                setShowModalHistory(true);
              }}
            >
              <HistorySvg />
              <HistorySvg isHover />
            </IconButton>
            <IconButton
              className="action-btn hover-btn"
              onClick={() => {
                setSelectedFiller(params.row);
                setShowCopyProduct(true);
                setShowModalAddProduct(true);
              }}
            >
              <CopySvg />
              <CopySvg isHover />
            </IconButton>
            <IconButton
              className="action-btn hover-btn"
              onClick={() => {
                setSelectedFiller(params.row);
                setShowModalConfirm(true);
              }}
            >
              <DeleteSvg />
              <DeleteSvg isHover />
            </IconButton>
          </>
        );
      },
    },

    {
      field: "N",
      headerName: "№",
      width: 75,
      renderCell: (params: any) => {
        return (
          <div>
            {params.api.getRowIndex(params.row.id) + 1}
            <Checkbox />
          </div>
        );
      },
    },
    { field: "name", headerName: "Name", width: 150 },
    { field: "max_temp", headerName: "Max pipe temp", width: 100 },
    {
      field: "status",
      headerName: "Status active/inactive",
      sortable: false,
      width: 100,
      renderCell: (params: any) => {
        return (
          <>
            <Switch
              checked={params.row.status}
              onChange={(e) => {
                dispatch(
                  productEditSwitchFillerStatus({
                    id: params.row.id,
                    status: e.target.checked,
                  })
                );
              }}
            />
          </>
        );
      },
    },
    {
      field: "display_for_distributor",
      headerName: "Display for distrib",
      sortable: false,
      width: 100,
      renderCell: (params: any) => {
        return (
          <>
            <Switch
              checked={!!params.row.display_for_distributor}
              onChange={(e) => {
                dispatch(
                  productEditSwitchFiller({
                    ...params.row,
                    display_for_distributor: e.target.checked,
                  })
                );
              }}
            />
          </>
        );
      },
    },
    {
      field: "defect_zone",
      headerName: "Defect zone",
      width: 100,
    },
    {
      field: "suitable_for_leaks",
      headerName: "SuitableForLeaks",
      sortable: false,
      width: 100,
      renderCell: (params: any) => {
        return (
          <>
            <Switch
              checked={params.row.suitable_for_leaks}
              onChange={(e) => {
                dispatch(
                  productEditSwitchFiller({
                    ...params.row,
                    suitable_for_leaks: e.target.checked,
                  })
                );
              }}
            />
          </>
        );
      },
    },
    {
      field: "density",
      headerName: "Density",
      width: 75,
    },
    {
      field: "is_subsea_env",
      headerName: "Is Subsea",
      sortable: false,
      width: 100,
      renderCell: (params: any) => {
        return (
          <>
            <Switch
              checked={params.row.is_subsea_env}
              onChange={(e) => {
                dispatch(
                  productEditSwitchFiller({
                    ...params.row,
                    is_subsea_env: e.target.checked,
                  })
                );
              }}
            />
          </>
        );
      },
    },
  ];

  const columnsPrimer: GridColDef[] = [
    {
      field: "ACTION",
      sortable: false,
      width: 130,
      disableColumnMenu: true,
      renderCell: (params: any) => {
        return (
          <>
            <IconButton
              className="action-btn hover-btn"
              onClick={() => {
                setSelectedPrimer(params.row);
                setShowModalAddProduct(true);
              }}
            >
              <EditSvg />
              <EditSvg isHover />
            </IconButton>
            <IconButton
              className="action-btn hover-btn"
              onClick={() => {
                setSelectedHistory({
                  type: "primer",
                  id: params.row.id,
                  name: params.row.name,
                });
                setShowModalHistory(true);
              }}
            >
              <HistorySvg />
              <HistorySvg isHover />
            </IconButton>
            <IconButton
              className="action-btn hover-btn"
              onClick={() => {
                setSelectedPrimer(params.row);
                setShowCopyProduct(true);
                setShowModalAddProduct(true);
              }}
            >
              <CopySvg />
              <CopySvg isHover />
            </IconButton>
            <IconButton
              className="action-btn hover-btn"
              onClick={() => {
                setSelectedPrimer(params.row);
                setShowModalConfirm(true);
              }}
            >
              <DeleteSvg />
              <DeleteSvg isHover />
            </IconButton>
          </>
        );
      },
    },

    {
      field: "N",
      headerName: "№",
      width: 75,
      renderCell: (params: any) => {
        return (
          <div>
            {params.api.getRowIndex(params.row.id) + 1}
            <Checkbox />
          </div>
        );
      },
    },
    { field: "name", headerName: "Name", width: 100 },
    { field: "min_temp", headerName: "Temp_min_pipe_C", width: 100 },
    {
      field: "status",
      headerName: "Status active/inactive",
      sortable: false,
      width: 100,
      renderCell: (params: any) => {
        return (
          <>
            <Switch
              checked={params.row.status}
              onChange={(e) => {
                dispatch(
                  productEditSwitchPrimerStatus({
                    id: params.row.id,
                    status: e.target.checked,
                  })
                );
              }}
            />
          </>
        );
      },
    },
    {
      field: "display_for_distributor",
      headerName: "Display for distrib",
      sortable: false,
      width: 100,
      renderCell: (params: any) => {
        return (
          <>
            <Switch
              checked={!!params.row.display_for_distributor}
              onChange={(e) => {
                dispatch(
                  productEditSwitchPrimer({
                    ...params.row,
                    display_for_distributor: e.target.checked,
                  })
                );
              }}
            />
          </>
        );
      },
    },
    { field: "max_temp", headerName: "Temp_max_pipe_C", width: 100 },
    { field: "min_temp_install", headerName: "Temp_min_install_C", width: 100 },
    { field: "max_temp_install", headerName: "Temp_max_install_C", width: 100 },
    { field: "consumption", headerName: "Consumption", width: 100 },
    { field: "weight_bucket", headerName: "Weight of bucket", width: 100 },
    {
      field: "is_subsea_env",
      headerName: "Is Subsea",
      sortable: false,
      width: 100,
      renderCell: (params: any) => {
        return (
          <>
            <Switch
              checked={params.row.is_subsea_env}
              onChange={(e) => {
                dispatch(
                  productEditSwitchPrimer({
                    ...params.row,
                    is_subsea_env: e.target.checked,
                  })
                );
              }}
            />
          </>
        );
      },
    },
  ];

  const getColumns = () => {
    if (type === "composit") {
      return columnsComposit;
    }

    if (type === "primer") {
      return columnsPrimer;
    }

    return columnsFiller;
  };

  const getRows = () => {
    if (type === "composit") {
      return listComposits !== null ? listComposits : [];
    }

    if (type === "filler") {
      return listFillers !== null ? listFillers : [];
    }

    if (type === "primer") {
      return listPrimers !== null ? listPrimers : [];
    }

    return [];
  };

  return (
    <div className="products-management">
      <div className="products-management_head">
        <div>Products</div>
        <div className="products-management_buttons">
          <IconButton
            className="delete-btn"
            onClick={() => {
              if (selectedRows.length > 0) {
                dispatch(
                  productGroupDelete({
                    nameProduct: type === "composit" ? "material" : type,
                    ids: selectedRows,
                  })
                );
                setSelectedRows([]);
              }
            }}
          >
            <DeleteSvg isHover />
          </IconButton>
          <Button
            type={TypesButton.primary}
            text="Add a new product"
            handleClick={() => {
              setSelectedComposit(null);
              setSelectedFiller(null);
              setSelectedPrimer(null);
              setShowModalAddProduct(true);
            }}
            size={178}
            icon={<AddIcon fontSize="small" />}
          />
        </div>
      </div>
      <Box>
        <div style={{ height: "65vh", width: "100%", marginTop: "40px" }}>
          {type === "composit" && (
            <div className="products-management_subtitle">
              List of composites
            </div>
          )}
          {type === "filler" && (
            <div className="products-management_subtitle">List of filler’s</div>
          )}
          {type === "primer" && (
            <div className="products-management_subtitle">List of primer’s</div>
          )}
          <DataGrid
            rows={getRows()}
            columns={getColumns()}
            rowsPerPageOptions={[10, 20, 50, 100]}
            // checkboxSelection
            loading={loading}
            components={{
              Toolbar: GridToolbar,
            }}
            onSelectionModelChange={(rowId) => {
              if (
                typeof rowId[0] === "number" &&
                selectedRows.includes(rowId[0])
              ) {
                setSelectedRows([
                  ...selectedRows.filter((item) => item !== rowId[0]),
                ]);
              }

              if (
                typeof rowId[0] === "number" &&
                !selectedRows.includes(rowId[0])
              ) {
                setSelectedRows([...selectedRows, rowId[0]]);
              }
            }}
            onRowDoubleClick={(selectedRow) => {
              if (type === "filler") {
                setSelectedFiller(selectedRow.row);
              }
              if (type === "primer") {
                setSelectedPrimer(selectedRow.row);
              }
              if (type === "composit") {
                setSelectedComposit(selectedRow.row);
              }

              if (
                selectedFiller !== null ||
                selectedPrimer !== null ||
                selectedComposit !== null
              ) {
                setShowModalAddProduct(true);
              }
            }}
          />
        </div>
      </Box>
      {showModalAddProduct && type === "filler" && (
        <ModalAddFiller
          show={showModalAddProduct}
          isCopy={showCopyProduct}
          filler={selectedFiller}
          handleClose={() => {
            setShowModalAddProduct(false);
            setShowCopyProduct(false);
          }}
        />
      )}
      {showModalAddProduct && type === "primer" && (
        <ModalAddPrimer
          show={showModalAddProduct}
          isCopy={showCopyProduct}
          primer={selectedPrimer}
          handleClose={() => {
            setShowModalAddProduct(false);
            setShowCopyProduct(false);
          }}
        />
      )}
      {showModalAddProduct && type === "composit" && (
        <ModalAddComposit
          show={showModalAddProduct}
          isCopy={showCopyProduct}
          composit={selectedComposit}
          handleClose={() => {
            setShowModalAddProduct(false);
            setShowCopyProduct(false);
          }}
        />
      )}
      {showModalHistory && (
        <ModalHistory
          show={showModalHistory}
          selectedMaterial={selectedHistory}
          handleClose={setShowModalHistory}
        />
      )}
      {showModalConfirm && selectedComposit !== null && (
        <ModalConfirm
          show={showModalConfirm}
          // eslint-disable-next-line max-len
          textQuestion={`Are you sure that you want to delete composit - ${selectedComposit?.id} ${selectedComposit?.trade_name}?`}
          handleCancel={() => setShowModalConfirm(false)}
          handleYes={() => {
            dispatch(productDeleteComposit(selectedComposit?.id || -1));
            setShowModalConfirm(false);
          }}
        />
      )}
      {showModalConfirm && selectedFiller !== null && (
        <ModalConfirm
          show={showModalConfirm}
          textQuestion={`Are you sure that you want to delete filler - ${selectedFiller?.id} ${selectedFiller?.name}?`}
          handleCancel={() => setShowModalConfirm(false)}
          handleYes={() => {
            dispatch(productDeleteFiller(selectedFiller?.id || -1));
            setShowModalConfirm(false);
          }}
        />
      )}
      {showModalConfirm && selectedPrimer !== null && (
        <ModalConfirm
          show={showModalConfirm}
          textQuestion={`Are you sure that you want to delete primer - ${selectedPrimer?.id} ${selectedPrimer?.name}?`}
          handleCancel={() => setShowModalConfirm(false)}
          handleYes={() => {
            dispatch(productDeletePrimer(selectedPrimer?.id || -1));
            setShowModalConfirm(false);
          }}
        />
      )}
    </div>
  );
};

export default ProductsManagement;
