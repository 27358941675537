import { memo, useCallback, useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "app/hook";
import { TypesButton } from "types";

import { Button } from "components/library";

import {
  cleanError,
  contentEditGeneralInformation,
  getGeneralInformation,
  resetEditGeneralInformation,
} from "features/content-management/content-management-slice";

import GeneralInformationText from "./general-information-text";

import "./general-information.scss";

type GeneralInformationErrors = {
  value?: string;
};

const GeneralInformation = () => {
  const {
    generalText,
    loading,
    error,
    isEditedGeneralText,
    savingGeneralText,
  } = useAppSelector((state) => state.contentManagement);

  const { email, userId } = useAppSelector((state) => state.loginUser);

  const [value, setValue] = useState<string[] | null>(generalText);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errors, setErrors] = useState<GeneralInformationErrors>({});
  const [reset, setReset] = useState(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (userId !== "" && email !== "" && !loading && generalText === null) {
      dispatch(getGeneralInformation());
    }
  }, [dispatch, email, userId, generalText, loading]);

  useEffect(() => {
    if (generalText !== null && value === null && !loading) {
      setValue(generalText);
    }
  }, [generalText, value, loading]);

  const validateForm = () => {
    const newErrors: GeneralInformationErrors = {};

    // if (!value || value === "") newErrors.value = "Please enter general text";

    return newErrors;
  };

  const handleCancel = () => {
    setReset(true);
    setTimeout(() => setReset(false), 50);
    dispatch(cleanError());
    dispatch(resetEditGeneralInformation());
  };

  const handleSave = () => {
    const formErrors = validateForm();

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      dispatch(cleanError());
      dispatch(resetEditGeneralInformation());
      dispatch(contentEditGeneralInformation(value || [""]));
      setErrors({});
    }
  };

  const setField = useCallback(
    (value: string[]) => {
      setValue(value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setValue]
  );

  return (
    <div className="general-information">
      <form className="general-information_form" autoComplete="off">
        {!reset && (
          <GeneralInformationText
            generalText={generalText || [""]}
            setField={setField}
            loading={savingGeneralText}
          />
        )}
        <div className="general-information_form_wrap-btns">
          <Button
            type={TypesButton["modal-outline"]}
            handleClick={() => handleCancel()}
            text="Cancel"
          />
          <Button
            type={TypesButton.modal}
            text="Save"
            handleClick={() => handleSave()}
          />
        </div>
        {isEditedGeneralText && (
          <div className="missing-information_form_success-text">
            General information successfully edited
          </div>
        )}
        {error !== null && (
          <div className="missing-information_form_error-text">{error}</div>
        )}
      </form>
    </div>
  );
};

export default memo(GeneralInformation);
