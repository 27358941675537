import { TechProposalsList } from "features/tech-proposals-and-projects";

const Proposals = () => {
  return (
    <div>
      <TechProposalsList />
    </div>
  );
};

export default Proposals;
