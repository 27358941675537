import { useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";

import { useAppDispatch, useAppSelector } from "app/hook";
import { TypesButton } from "types";
import {
  BootstrapDialog,
  BootstrapDialogTitle,
  Button,
} from "components/library";

import {
  cleanError,
  Filler,
  productCreateEditFiller,
  resetCreatedProduct,
} from "../products-management-slice";

import "./modal-add-filler.scss";

type ModalAddFillerErrors = {
  name?: string;
  max_temp?: string;
  density?: string;
};

type ModalAddFillerProps = {
  show: boolean;
  handleClose: Function;
  filler: Filler | null;
  isCopy: boolean;
};

const ModalAddFiller = ({
  show,
  handleClose,
  filler,
  isCopy,
}: ModalAddFillerProps) => {
  const { error, isCreatedProduct, isEditedProduct } = useAppSelector(
    (state) => state.productsManagement
  );

  const [form, setForm] = useState(
    filler === null
      ? {
          id: null,
          name: "",
          max_temp: 0,
          status: true,
          display_for_distributor: 0,
          is_subsea_env: false,
          suitable_for_leaks: false,
          density: 0,
          defect_zone: "",
        }
      : {
          id: filler.id,
          name: filler.name,
          max_temp: filler.max_temp,
          status: filler.status,
          display_for_distributor: filler.display_for_distributor,
          is_subsea_env: filler.is_subsea_env,
          suitable_for_leaks: filler.suitable_for_leaks,
          density: filler.density,
          defect_zone: filler.defect_zone || "",
        }
  );
  const [errors, setErrors] = useState<ModalAddFillerErrors>({});

  const dispatch = useAppDispatch();

  const setField = (field: string, value: number | string | boolean | null) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!!errors[field as keyof ModalAddFillerErrors]) {
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };

  const validateForm = () => {
    const { name, max_temp, density } = form;
    const newErrors: ModalAddFillerErrors = {};

    if (!name || name === "") newErrors.name = "Please enter name";
    if (!max_temp || max_temp === 0)
      newErrors.max_temp = "Please enter temperature Pipe Max";
    if (!density || density === 0) newErrors.density = "Please enter density";

    return newErrors;
  };

  const handleCloseModal = () => {
    handleClose();
    dispatch(cleanError());
    dispatch(resetCreatedProduct());
  };

  const handleSave = () => {
    const formErrors = validateForm();

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      if (isCopy) {
        dispatch(productCreateEditFiller({ ...form, id: null }));
      } else {
        dispatch(productCreateEditFiller({ ...form }));
      }
      setErrors({});
    }
  };

  return (
    <BootstrapDialog
      onClose={() => handleCloseModal()}
      aria-labelledby="customized-add-filler"
      open={show}
      className="modal-add-filler"
    >
      <BootstrapDialogTitle
        id="customized-add-filler"
        onClose={() => handleCloseModal()}
      >
        {filler === null && "Add a new filler"}
        {filler !== null && (isCopy ? "Copy filler" : "Edit filler")}
      </BootstrapDialogTitle>
      <DialogContent className="modal-add-filler_body">
        <form className="modal-add-filler_body_form" autoComplete="off">
          <FormControlLabel
            className="modal-add-filler_body_form_control-label-switch"
            control={
              <Switch
                checked={form.status}
                onChange={(e) => {
                  setField("status", e.target.checked);
                }}
              />
            }
            label={"Status"}
            labelPlacement="start"
            value="status"
          />

          <FormControlLabel
            className="modal-add-filler_body_form_control-label-switch"
            control={
              <Switch
                checked={!!form.display_for_distributor}
                onChange={(e) => {
                  setField("display_for_distributor", e.target.checked);
                }}
              />
            }
            label={"Display for distrib"}
            labelPlacement="start"
            value="display_for_distributor"
          />

          <FormControlLabel
            className="modal-add-filler_body_form_control-label-switch"
            control={
              <Switch
                checked={form.is_subsea_env}
                onChange={(e) => {
                  setField("is_subsea_env", e.target.checked);
                }}
              />
            }
            label={"Is subsea"}
            labelPlacement="start"
            value="is_subsea_env"
          />

          <FormControlLabel
            className="modal-add-filler_body_form_control-label"
            control={
              <TextField
                autoFocus
                id="name"
                value={form.name}
                onChange={(e) => {
                  setField("name", e.target.value);
                }}
                required
                error={errors.name ? true : false}
                helperText={errors.name}
                sx={{
                  marginTop: "0px",
                  marginBottom: "0px",
                }}
                disabled={filler !== null && !isCopy}
              />
            }
            label={"Name"}
            labelPlacement="start"
            value="name"
          />
          <FormControlLabel
            className="modal-add-filler_body_form_control-label"
            control={
              <TextField
                type="number"
                // InputProps={{
                //   inputProps: {
                //     max: 100,
                //     min: 0,
                //   },
                // }}
                error={errors.density ? true : false}
                id="density"
                value={form.density}
                onChange={(e) => {
                  setField("density", parseFloat(e.target.value));
                }}
                required
                helperText={errors.density}
                sx={{
                  marginTop: "0px",
                  marginBottom: "0px",
                }}
              />
            }
            label={"Density"}
            labelPlacement="start"
            value="density"
          />

          <FormControlLabel
            className="modal-add-filler_body_form_control-label"
            control={
              <TextField
                type="number"
                // InputProps={{
                //   inputProps: {
                //     max: 1000,
                //     min: 0,
                //   },
                // }}
                error={errors.max_temp ? true : false}
                id="temperaturePipeMax"
                value={form.max_temp}
                onChange={(e) => {
                  setField("max_temp", parseFloat(e.target.value));
                }}
                required
                helperText={errors.max_temp}
                sx={{
                  marginTop: "0px",
                  marginBottom: "0px",
                }}
              />
            }
            label={"Temperature pipe C max"}
            labelPlacement="start"
            value="max_temp"
          />

          <FormControlLabel
            className="modal-add-filler_body_form_control-label"
            control={
              <Select
                id="defectZone"
                value={form.defect_zone}
                onChange={(e) => {
                  setField("defect_zone", e.target.value || "");
                }}
              >
                <MenuItem key={""} value="">
                  EMPTY
                </MenuItem>
                <MenuItem key="INTERNAL" value="INTERNAL">
                  INTERNAL
                </MenuItem>
                <MenuItem key="EXTERNAL" value="EXTERNAL">
                  EXTERNAL
                </MenuItem>
              </Select>
            }
            label={"Defect zone"}
            labelPlacement="start"
            value="defect_zone"
          />

          <FormControlLabel
            className="modal-add-filler_body_form_control-label-switch"
            control={
              <Switch
                checked={form.suitable_for_leaks}
                onChange={(e) => {
                  setField("suitable_for_leaks", e.target.checked);
                }}
              />
            }
            label={"Suitable for leaks"}
            labelPlacement="start"
            value="suitable_for_leaks"
          />

          <div className="modal-add-filler_body_form_wrap-btns">
            <Button
              type={TypesButton["modal-outline"]}
              handleClick={() => handleCloseModal()}
              text="Cancel"
            />
            <Button
              type={TypesButton.modal}
              text="Save"
              handleClick={() => handleSave()}
            />
          </div>
        </form>
        {isCreatedProduct && (
          <div className="modal-add-filler_body_form_success-text">
            Filler successfully created
          </div>
        )}
        {isEditedProduct && filler !== null && (
          <div className="modal-add-filler_body_form_success-text">
            Filler successfully edited
          </div>
        )}
        {error !== null && (
          <div className="modal-add-filler_body_form_error-text">{error}</div>
        )}
      </DialogContent>
    </BootstrapDialog>
  );
};

export default ModalAddFiller;
