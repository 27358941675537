import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getFetchProp } from "helpers/getFetchProp";

import { LoginUserState } from "features/auth/auth-slice";

import { apiUrl } from "../../constants";

type User = {
  id: number;
  email: string;
  name: string;
  geozone: string;
  location: string;
  status: boolean;
  account_status: string;
};

type Request = {
  id: number;
  email: string;
  name: string;
  geozone: string;
  location: string;
};

type History = {
  id: number;
  email: string;
  name: string;
  geozone: string;
  location: string;
};

type GeoZone = {
  id: number;
  name: string;
};

type Permission = {
  id: number;
  name: string;
};

type DetailEditUser = {
  account_type: string;
  calculation_permissions: string[];
  email: string;
  geo_zone_id: number;
  is_lot_of_composite_allowed: boolean;
  is_lot_of_damage_allowed: boolean;
  location: string;
  material_ids: number[];
  filler_ids: number[];
  primer_ids: number[];
  name: string;
  phone?: string;
  user_id: number;
};

export type UsersManagementState = {
  listUsers: User[] | null;
  listUsersTotal: number;
  isCreatedUser: boolean;
  isEditedUser: boolean;
  newRequests: Request[] | null;
  newRequestsTotal: number;
  histories: History[] | null;
  historiesTotal: number;
  geoZones: GeoZone[] | null;
  permissions: Permission[] | null;
  detailEditUser: DetailEditUser | null;
  error: string | null;
  loading: boolean;
};

type UserCreateInput = {
  id: number | null;
  name: string;
  email: string;
  telephone: string;
  location: string;
  geozone: string;
  role: string;
  importManyDefects: boolean;
  importManyComposits: boolean;
  accessLevel: {
    permissions: string[] | null;
    composits: number[] | null;
    fillers: number[] | null;
    primers: number[] | null;
  };
};

// type ChangePasswordInput = {
//   code: string;
//   email: string;
//   password: string;
// };

const initialState: UsersManagementState = {
  listUsers: null,
  listUsersTotal: 0,
  isCreatedUser: false,
  isEditedUser: false,
  newRequests: null,
  newRequestsTotal: 0,
  histories: null,
  historiesTotal: 0,
  geoZones: null,
  permissions: null,
  detailEditUser: null,
  error: null,
  loading: false,
};

export const getUsersConfirmed = createAsyncThunk(
  "getAllConfirmedUsers",
  async (_, { rejectWithValue, dispatch, getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    const res = await fetch(`${apiUrl}/rea/v1.0/api/user/confirmed`, {
      ...getFetchProp({
        method: "GET",
        contentType: "application/json",
        token: loginUser.token,
      }),
    });

    return res.json();
  }
);

export const getUsersNotConfirmed = createAsyncThunk(
  "notConfirmedUsers",
  async (_, { rejectWithValue, dispatch, getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    const res = await fetch(`${apiUrl}/rea/v1.0/api/user/not_confirmed`, {
      ...getFetchProp({
        method: "GET",
        contentType: "application/json",
        token: loginUser.token,
      }),
    });

    return res.json();
  }
);

export const getUsersRejected = createAsyncThunk(
  "rejectedUsers",
  async (_, { rejectWithValue, dispatch, getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    const res = await fetch(`${apiUrl}/rea/v1.0/api/user/rejected`, {
      ...getFetchProp({
        method: "GET",
        contentType: "application/json",
        token: loginUser.token,
      }),
    });

    return res.json();
  }
);

export const getGeoZones = createAsyncThunk(
  "geozones",
  async (_, { rejectWithValue, dispatch, getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    const res = await fetch(`${apiUrl}/rea/v1.0/api/geo_zone`, {
      ...getFetchProp({
        method: "GET",
        contentType: "application/json",
        token: loginUser.token,
      }),
    });

    return res.json();
  }
);

export const getPermissions = createAsyncThunk(
  "permissions",
  async (_, { rejectWithValue, dispatch, getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    const res = await fetch(
      `${apiUrl}/rea/v1.0/api/description/calculation_permission`,
      {
        ...getFetchProp({
          method: "GET",
          contentType: "application/json",
          token: loginUser.token,
        }),
      }
    );

    return res.json();
  }
);

export const getUserDetail = createAsyncThunk(
  "getUserDetail",
  async (id: number, { rejectWithValue, dispatch, getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    const res = await fetch(
      `${apiUrl}/rea/v1.0/api/user/detail?user_id=${id}`,
      {
        ...getFetchProp({
          method: "GET",
          contentType: "application/json",
          token: loginUser.token,
        }),
      }
    );

    return res.json();
  }
);

export const userCreateAdmin = createAsyncThunk(
  "createAdmin",
  async (
    { id, name, telephone, email, location, geozone, role }: UserCreateInput,
    { getState }
  ) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    if (
      (role === "ADMINISTRATOR" || role === "TECHNICAL_ADMINISTRATOR") &&
      loginUser.token &&
      loginUser.accountType === "ADMINISTRATOR"
    ) {
      const res = await fetch(`${apiUrl}/rea/v1.0/api/user/register/admin`, {
        ...getFetchProp({
          method: "POST",
          contentType: "application/json",
          token: loginUser.token,
          body: JSON.stringify({
            id: id,
            continent: geozone,
            email: email,
            location: location,
            name: name,
            phone: telephone,
            is_super_admin: role === "ADMINISTRATOR" ? true : false,
          }),
        }),
      });

      return res.json();
    }
  }
);

export const userCreateSale = createAsyncThunk(
  "createSaleManager",
  async (
    {
      id,
      name,
      telephone,
      email,
      location,
      geozone,
      role,
      importManyDefects,
      importManyComposits,
      accessLevel,
    }: UserCreateInput,
    { getState }
  ) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    if (
      (role === "SALE_MANAGER" || role === "TECHNICAL_3X") &&
      loginUser.token &&
      loginUser.accountType === "ADMINISTRATOR"
    ) {
      const res = await fetch(
        `${apiUrl}/rea/v1.0/api/user/register/sale_manager`,
        {
          ...getFetchProp({
            method: "POST",
            contentType: "application/json",
            token: loginUser.token,
            body: JSON.stringify({
              id: id,
              calculation_permissions: accessLevel.permissions,
              continent: geozone,
              email: email,
              filler_ids: accessLevel.fillers,
              is_lot_of_composite_allowed: importManyComposits,
              is_lot_of_damage_allowed: importManyDefects,
              location: location,
              material_ids: accessLevel.composits,
              name: name,
              phone: telephone,
              primer_ids: accessLevel.primers,
              is_3x_technical: role === "TECHNICAL_3X" ? true : false,
              is_super_admin: false,
            }),
          }),
        }
      );

      return res.json();
    }
  }
);

export const userEdit = createAsyncThunk(
  "userEdit",
  async (
    {
      id,
      name,
      telephone,
      location,
      accessLevel,
      importManyComposits,
      importManyDefects,
    }: UserCreateInput,
    { getState }
  ) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    if (loginUser.accountType === "ADMINISTRATOR" && loginUser.token) {
      const res = await fetch(`${apiUrl}/rea/v1.0/api/user/edit`, {
        ...getFetchProp({
          method: "PUT",
          contentType: "application/json",
          token: loginUser.token,
          body: JSON.stringify({
            calculation_permissions: accessLevel.permissions,
            filler_ids: accessLevel.fillers,
            is_lot_of_composite_allowed: importManyComposits,
            is_lot_of_damage_allowed: importManyDefects,
            location: location,
            material_ids: accessLevel.composits,
            name: name,
            phone: telephone,
            primer_ids: accessLevel.primers,
            user_id: id,
          }),
        }),
      });

      return res.json();
    }
  }
);

type UserEditSwitchStatusInput = {
  id: number;
  status: boolean;
};

export const userEditSwitchStatus = createAsyncThunk(
  "edit_switch_User",
  async ({ id, status }: UserEditSwitchStatusInput, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    if (loginUser.accountType === "ADMINISTRATOR" && loginUser.token) {
      const res = await fetch(
        `${apiUrl}/rea/v1.0/api/user/status?id=${id}&status=${status}`,
        {
          ...getFetchProp({
            method: "PUT",
            contentType: "application/json",
            token: loginUser.token,
          }),
        }
      );

      return new Promise<any>((resolve, reject) => {
        if (res.ok && res.status === 200) {
          resolve({ id, status });
        } else {
          reject(new Error("error"));
        }
      });
    }
  }
);

export const userReject = createAsyncThunk(
  "userReject",
  async (id: number, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    if (loginUser.accountType === "ADMINISTRATOR" && loginUser.token) {
      const res = await fetch(`${apiUrl}/rea/v1.0/api/user/reject`, {
        ...getFetchProp({
          method: "PUT",
          contentType: "application/json",
          token: loginUser.token,
          body: JSON.stringify([id]),
        }),
      });

      return res.json();
    }
  }
);

export const userRecovery = createAsyncThunk(
  "userRecovery",
  async (id: number, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    if (loginUser.accountType === "ADMINISTRATOR" && loginUser.token) {
      const res = await fetch(`${apiUrl}/rea/v1.0/api/user/restore`, {
        ...getFetchProp({
          method: "PUT",
          contentType: "application/json",
          token: loginUser.token,
          body: JSON.stringify([id]),
        }),
      });

      return res.json();
    }
  }
);

export const userAccept = createAsyncThunk(
  "userAccept",
  async (
    {
      id,
      name,
      telephone,
      location,
      accessLevel,
      importManyComposits,
      importManyDefects,
    }: UserCreateInput,
    { getState }
  ) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    if (loginUser.accountType === "ADMINISTRATOR" && loginUser.token) {
      const res = await fetch(`${apiUrl}/rea/v1.0/api/user/edit`, {
        ...getFetchProp({
          method: "PUT",
          contentType: "application/json",
          token: loginUser.token,
          body: JSON.stringify({
            calculation_permissions: accessLevel.permissions,
            filler_ids: accessLevel.fillers,
            is_lot_of_composite_allowed: importManyComposits,
            is_lot_of_damage_allowed: importManyDefects,
            location: location,
            material_ids: accessLevel.composits,
            name: name,
            phone: telephone,
            primer_ids: accessLevel.primers,
            user_id: id,
          }),
        }),
      });

      return res.json();
    }
  }
);

const usersManagementSlice = createSlice({
  name: "users management",
  initialState,
  reducers: {
    cleanError(state) {
      state.error = null;
    },
    resetCreatedUser(state) {
      state.isCreatedUser = false;
      state.isEditedUser = false;
      state.detailEditUser = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsersConfirmed.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUsersConfirmed.fulfilled, (state, action) => {
        state.listUsers = action.payload;
        state.listUsersTotal = action.payload.length;
        state.loading = false;
        state.error = null;
      })
      .addCase(getUsersConfirmed.rejected, (state) => {
        state.loading = false;
        state.error = "Request get users confirmed is fail!";
      })
      .addCase(getUsersNotConfirmed.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUsersNotConfirmed.fulfilled, (state, action) => {
        state.newRequests = action.payload;
        state.newRequestsTotal = action.payload.length;
        state.loading = false;
        state.error = null;
      })
      .addCase(getUsersNotConfirmed.rejected, (state) => {
        state.loading = false;
        state.error = "Request get users not confirmed is fail!";
      })
      .addCase(getUsersRejected.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUsersRejected.fulfilled, (state, action) => {
        state.histories = action.payload;
        state.historiesTotal = action.payload.length;
        state.loading = false;
        state.error = null;
      })
      .addCase(getUsersRejected.rejected, (state) => {
        state.loading = false;
        state.error = "Request get users rejected is fail!";
      })
      .addCase(getGeoZones.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getGeoZones.fulfilled, (state, action) => {
        state.geoZones = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getGeoZones.rejected, (state) => {
        state.loading = false;
        state.error = "Request get geozones is fail!";
      })
      .addCase(getPermissions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPermissions.fulfilled, (state, action) => {
        state.permissions = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getPermissions.rejected, (state) => {
        state.loading = false;
        state.permissions = [];
        state.error = "Request get permissions is fail!";
      })
      .addCase(getUserDetail.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserDetail.fulfilled, (state, action) => {
        state.detailEditUser = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getUserDetail.rejected, (state) => {
        state.loading = false;
        state.error = "Request get detail user is fail!";
      })
      .addCase(userCreateAdmin.pending, (state) => {
        state.isCreatedUser = false;
        state.loading = true;
        state.error = null;
      })
      .addCase(userCreateAdmin.fulfilled, (state, action) => {
        if (action.payload.id && action.payload.id > 0) {
          state.isCreatedUser = true;
          state.error = null;
          if (state.listUsers !== null) {
            state.listUsers = [...state.listUsers, action.payload];
            state.listUsersTotal = state.listUsers.length;
          } else {
            state.listUsers = [action.payload];
            state.listUsersTotal = 1;
          }
        }
        if (
          action.payload.status &&
          action.payload.status > 0 &&
          action.payload.error
        ) {
          state.error = action.payload.error;
        }

        state.loading = false;
      })
      .addCase(userCreateAdmin.rejected, (state) => {
        state.loading = false;
        state.error = "Request create/edit user admin is fail!";
      })
      .addCase(userCreateSale.pending, (state) => {
        state.isCreatedUser = false;
        state.loading = true;
        state.error = null;
      })
      .addCase(userCreateSale.fulfilled, (state, action) => {
        if (action.payload.id && action.payload.id > 0) {
          state.isCreatedUser = true;
          state.error = null;
          if (state.listUsers !== null) {
            state.listUsers = [...state.listUsers, action.payload];
            state.listUsersTotal = state.listUsers.length;
          } else {
            state.listUsers = [action.payload];
            state.listUsersTotal = 1;
          }
        }
        if (
          action.payload.status &&
          action.payload.status > 0 &&
          action.payload.error
        ) {
          state.error = action.payload.error;
        }

        state.loading = false;
      })
      .addCase(userCreateSale.rejected, (state) => {
        state.loading = false;
        state.error = "Request create/edit user sale is fail!";
      })
      .addCase(userEdit.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.isEditedUser = false;
      })
      .addCase(userEdit.fulfilled, (state, action) => {
        if (action.payload.id && action.payload.id > 0) {
          state.error = null;
          state.isEditedUser = true;
          if (state.listUsers !== null) {
            const newList = [...state.listUsers];
            const ind = newList.findIndex(
              (item) => item.id === action.payload.id
            );
            if (ind > -1) {
              newList[ind] = {
                ...action.payload,
              };
            }
            state.listUsers = [...newList];
          }
        }
        if (
          action.payload.status &&
          action.payload.status > 0 &&
          action.payload.error
        ) {
          state.error = action.payload.error;
        }

        state.loading = false;
      })
      .addCase(userEdit.rejected, (state) => {
        state.loading = false;
        state.error = "Request edit user is fail!";
      })
      .addCase(userEditSwitchStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(userEditSwitchStatus.fulfilled, (state, action) => {
        if (action.payload && action.payload.id > 0) {
          state.error = null;
          if (state.listUsers !== null) {
            const newList = [...state.listUsers];
            const ind = newList.findIndex(
              (item) => item.id === action.payload.id
            );

            if (ind > -1) {
              newList[ind] = {
                ...newList[ind],
                status: action.payload.status,
              };
              state.listUsers = [...newList];
            }
          }
        }

        state.loading = false;
      })
      .addCase(userEditSwitchStatus.rejected, (state) => {
        state.loading = false;
        state.error = "Request edit status user is fail!";
      })
      .addCase(userReject.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(userReject.fulfilled, (state, action) => {
        if (Array.isArray(action.payload) && action.payload.length > 0) {
          state.error = null;

          action.payload.forEach((item: User) => {
            if (
              state.listUsers !== null &&
              state.listUsers.findIndex((user) => user.id === item.id) !== -1
            ) {
              state.listUsers = [
                ...state.listUsers.filter((user) => user.id !== item.id),
              ];

              state.listUsersTotal = state.listUsers.length;

              if (state.histories !== null) state.histories.push(item);
              else state.histories = [item];
              state.historiesTotal = state.histories.length;
            }

            if (
              state.newRequests !== null &&
              state.newRequests.findIndex((user) => user.id === item.id) !== -1
            ) {
              state.newRequests = [
                ...state.newRequests.filter((user) => user.id !== item.id),
              ];

              state.newRequestsTotal = state.newRequests.length;

              if (state.histories !== null) state.histories.push(item);
              else state.histories = [item];
              state.historiesTotal = state.histories.length;
            }
          });
        }

        if (
          action.payload.status &&
          action.payload.status > 0 &&
          action.payload.error
        ) {
          state.error = action.payload.error;
        }

        state.loading = false;
      })
      .addCase(userReject.rejected, (state) => {
        state.loading = false;
        state.error = "Request reject user is fail!";
      })
      .addCase(userAccept.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.isEditedUser = false;
      })
      .addCase(userAccept.fulfilled, (state, action) => {
        if (action.payload.id && action.payload.id > 0) {
          state.error = null;
          state.isEditedUser = true;

          if (
            state.newRequests !== null &&
            state.newRequests.findIndex(
              (user) => user.id === action.payload.id
            ) !== -1
          ) {
            state.newRequests = [
              ...state.newRequests.filter(
                (user) => user.id !== action.payload.id
              ),
            ];

            state.newRequestsTotal = state.newRequests.length;
          }

          if (state.listUsers !== null) state.listUsers.push(action.payload);
          else state.listUsers = [action.payload];
          state.listUsersTotal = state.listUsers.length;
        }

        if (
          action.payload.status &&
          action.payload.status > 0 &&
          action.payload.error
        ) {
          state.error = action.payload.error;
        }

        state.loading = false;
      })
      .addCase(userAccept.rejected, (state) => {
        state.loading = false;
        state.error = "Request edit accept user is fail!";
      })
      .addCase(userRecovery.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(userRecovery.fulfilled, (state, action) => {
        if (Array.isArray(action.payload) && action.payload.length > 0) {
          state.error = null;

          action.payload.forEach((item: User) => {
            if (
              state.histories !== null &&
              state.histories.findIndex((user) => user.id === item.id) !== -1
            ) {
              state.histories = [
                ...state.histories.filter((user) => user.id !== item.id),
              ];

              state.historiesTotal = state.histories.length;

              if (item.account_status === "NOT_CONFIRMED") {
                if (state.newRequests !== null) state.newRequests.push(item);
                else state.newRequests = [item];
                state.newRequestsTotal = state.newRequests.length;
              }

              if (item.account_status === "ACTIVE") {
                if (state.listUsers !== null) state.listUsers.push(item);
                else state.listUsers = [item];
                state.listUsersTotal = state.listUsers.length;
              }
            }
          });
        }

        if (
          action.payload.status &&
          action.payload.status > 0 &&
          action.payload.error
        ) {
          state.error = action.payload.error;
        }

        state.loading = false;
      })
      .addCase(userRecovery.rejected, (state) => {
        state.loading = false;
        state.error = "Request recovery user is fail!";
      });
  },
});

export const { cleanError, resetCreatedUser } = usersManagementSlice.actions;

export default usersManagementSlice.reducer;
