import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  registerables,
  ChartOptions,
  ChartData,
} from "chart.js";
import { Button } from "@mui/material";

import { productsColors } from "../../../../constants";

import "./bar-chart.scss";

ChartJS.register(...registerables);

export type datasets = {
  data: Array<number>;
  backgroundColor: string | Array<string>;
  borderRadius?: number;
  barPercentage?: number;
  stepSize?: number;
};

export type barData = {
  labels: Array<string>;
  datasets: Array<datasets>;
  scales?: {
    stepSize: number;
  };
};

type DataItem = {
  name: string;
  value: string;
};

export type BarChartProps = {
  title: string;
  width: number;
  height: number;
  barData: DataItem[];
  stepSize?: number;
  total?: number;
};

const BarChart: React.FC<BarChartProps> = ({
  width,
  height,
  barData,
  title,
  stepSize,
  total,
}) => {
  const labels: string[] = [];
  const dataSource: number[] = [];
  let backgroundColors: string[] = [];

  barData.map((item, index) => {
    labels.push(item.name);
    dataSource.push(parseFloat(item.value));
    backgroundColors.push(productsColors[index]);

    return item;
  });

  const datasets = {
    data: dataSource,
    backgroundColor: backgroundColors,
    borderRadius: 5,
    barPercentage: 1,
    categoryPercentage: 0.3,
  };

  const data: ChartData<"bar"> = {
    labels,
    datasets: [datasets],
  };

  const options: ChartOptions<"bar"> = {
    scales: {
      y: {
        max: Math.max(...dataSource) + Math.max(...dataSource) / 10,

        ticks: {
          stepSize: stepSize,
        },

        display: true,

        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    responsive: true,
  };
  const className = "bar-chart";

  return (
    <div className={className}>
      <div className={`${className}_title`}>{title}</div>
      {total !== undefined && (
        <div className={`${className}_title ${className}_total`}>
          Total technical proposals: {total}
        </div>
      )}
      <div className={`${className}_btn-wrap`}>
        {barData !== undefined &&
          barData.length > 0 &&
          barData.map((item, index) => {
            return (
              <Button
                style={{ border: `4px solid ${productsColors[index]}` }}
                onClick={() => {}}
                key={item.name}
              >
                {item.name}
              </Button>
            );
          })}
      </div>
      <Bar data={data} options={options} width={width} height={height} />
    </div>
  );
};

export default BarChart;
