import { Statistics } from "features/statistics";

const Marketing = () => {
  return (
    <div>
      <Statistics type="marketing" />
    </div>
  );
};

export default Marketing;
