import { BrowserRouter } from "react-router-dom";
import { StyledEngineProvider } from "@mui/material/styles";

import AppLayout from "./app-layout";

import "./styles/all.scss";

const App = () => {
  return (
    <StyledEngineProvider injectFirst>
      <BrowserRouter>
        <AppLayout />
      </BrowserRouter>
    </StyledEngineProvider>
  );
};

export default App;
