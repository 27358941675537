import { ProjectsList } from "features/tech-proposals-and-projects";

const Projects = () => {
  return (
    <div>
      <ProjectsList />
    </div>
  );
};

export default Projects;
