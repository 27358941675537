import { Button } from "@mui/material";

import { productsColors, productsPatchColors } from "../../../../constants";

import { Marketing } from "../../statistics-slice";

import DoughnutChart from "../doughnut-chart";

import "./doughnut-chart-marketing.scss";

type MarketingDetailsItem = {
  name: string;
  value: string;
};

type DoughnutChartMarketingProps = {
  width: number;
  height: number;
  marketingStatistic?: Marketing[];
  marketingStatisticDetails?: MarketingDetailsItem[];
  title: string;
  total?: number;
};

const DoughnutChartMarketing: React.FC<DoughnutChartMarketingProps> = ({
  width,
  height,
  marketingStatistic,
  marketingStatisticDetails,
  title,
  total,
}: DoughnutChartMarketingProps) => {
  let names: string[] = [];
  let totals: number[] = [];
  let backgroundColors: string[] = [];

  marketingStatistic?.map((item, index) => {
    if (parseFloat(`${item.patch}`) > 0) {
      names.push(item.name);
      totals.push(item.total - item.patch);
      backgroundColors.push(`${productsColors[index]}`);
      names.push(`${item.name} patch`);
      totals.push(item.patch);
      backgroundColors.push(`${productsPatchColors[index]}`);
    } else if (parseFloat(`${item.patch}`) === 0) {
      names.push(item.name);
      totals.push(item.total);
      backgroundColors.push(`${productsColors[index]}`);
    }

    return item;
  });

  marketingStatisticDetails?.map((item, index) => {
    names.push(item.name);
    totals.push(parseFloat(item.value));
    backgroundColors.push(`${productsColors[index]}`);

    return item;
  });

  const className = "doughnut-chart-marketing";

  return (
    <div className={className}>
      <div className={`${className}_title`}>{title}</div>
      {total !== undefined && (
        <div className={`${className}_title ${className}_total`}>
          Total technical proposals: {total}
        </div>
      )}
      <div className={`${className}_btn-wrap`}>
        {marketingStatistic !== undefined &&
          marketingStatistic.length > 0 &&
          marketingStatistic.map((item, index) => {
            return (
              <Button
                style={{ border: `4px solid ${productsColors[index]}` }}
                onClick={() => {}}
                key={item.name}
              >
                {item.name}
              </Button>
            );
          })}
        {marketingStatisticDetails !== undefined &&
          marketingStatisticDetails.length > 0 &&
          marketingStatisticDetails.map((item, index) => {
            return (
              <Button
                style={{ border: `4px solid ${productsColors[index]}` }}
                onClick={() => {}}
                key={item.name}
              >
                {item.name}
              </Button>
            );
          })}
      </div>

      <div className={`${className}_chart-wrap`}>
        <div className={`${className}_chart-wrap_title`}>
          Sent to the client
        </div>
        <div>
          {marketingStatistic !== undefined &&
            marketingStatistic.length > 0 && (
              <DoughnutChart
                width={width}
                height={height}
                inputData={{ names, totals, backgroundColors }}
              />
            )}

          {marketingStatisticDetails !== undefined &&
            marketingStatisticDetails.length > 0 && (
              <DoughnutChart
                width={width}
                height={height}
                inputData={{ names, totals, backgroundColors }}
              />
            )}
        </div>
        <div className={`${className}_chart-wrap_percents-wrap`}>
          {marketingStatistic !== undefined &&
            marketingStatistic.length > 0 &&
            marketingStatistic.map((item, index) => {
              return (
                <span
                  style={{ color: `${productsColors[index]}` }}
                  key={`${item.total}-${index}`}
                >{`${item.total}%`}</span>
              );
            })}
          {marketingStatisticDetails !== undefined &&
            marketingStatisticDetails.length > 0 &&
            marketingStatisticDetails.map((item, index) => {
              return (
                <span
                  style={{ color: `${productsColors[index]}` }}
                  key={`${item.value}-${index}`}
                >{`${item.value}%`}</span>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default DoughnutChartMarketing;
