import { Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  ChartOptions,
  ChartData,
} from "chart.js";

ChartJS.register(ArcElement, Tooltip);

type DoughnutChartProps = {
  width: number;
  height: number;
  inputData?: {
    names: string[];
    totals: number[];
    backgroundColors: string[];
  };
};

const DoughnutChart: React.FC<DoughnutChartProps> = ({
  width,
  height,
  inputData,
}: DoughnutChartProps) => {
  const data: ChartData<"doughnut"> = {
    labels: [...(inputData?.names || ["Red", "Orange", "Yellow"])],
    datasets: [
      {
        data: [...(inputData?.totals || [23, 55, 15])],
        backgroundColor: [
          ...(inputData?.backgroundColors || ["#2AD4AC", "#DA5674", "#F3A50C"]),
        ],
      },
    ],
  };

  const options: ChartOptions<"doughnut"> = {
    plugins: {
      legend: {
        display: false,
      },
    },
    maintainAspectRatio: false,
    responsive: true,
  };

  return (
    <Doughnut data={data} options={options} width={width} height={height} />
  );
};

export default DoughnutChart;
