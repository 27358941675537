import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import FileSaver from "file-saver";

import { getFetchProp } from "helpers/getFetchProp";

import { LoginUserState } from "features/auth/auth-slice";

import { apiUrl } from "../../constants";

type Composit = {
  id: number;
  trade_name: string;
  resin_name: string;
  composite_length: number;
  composite_width: number;
  composite_thickness: number;
  composite_type: string;
  minimum_operating_temperature: number;
  maximum_operating_temperature: number;
  minimum_installation_temperature: number;
  maximum_installation_temperature: number;
  epsilon_lt: number;
  sigma_lt: number;
  alpha_a: number;
  alpha_c: number;
  e_a: number;
  e_c: number;
  nu_lt: number;
  sheer_modulus: number;
  g_lcl_1: number;
  g_lcl_2: number;
  g_lcl_3: number;
  tau: number;
  tg_c: number;
  t_test: number;
  t_amb: number;
  laminate_covering_offset_percent: number;
  performance_data: number;
  tape_type: string;
  suitable_residual_or_internal_corrosion: boolean;
  suitable_subsea: boolean;
  nb_resin_tape: number;
  color_hexadecimal: string;
  display_for_distributor: boolean;
  status: boolean;
};

interface Filler {
  defect_zone: string | null;
  density: number;
  display_for_distributor: number;
  id: number;
  is_subsea_env: boolean;
  max_temp: number;
  name: string;
  status: boolean;
  suitable_for_leaks: boolean;
}

type Primer = {
  consumption: number;
  display_for_distributor: number;
  id: number;
  is_subsea_env: boolean;
  max_temp: number;
  max_temp_install: number;
  min_temp: number;
  min_temp_install: number;
  name: string;
  status: boolean;
  weight_bucket: number;
};

type History = {
  id: number;
  field_name: string;
  from: string;
  to: string;
  updated_at: string;
  user_email: string;
  user_name: string;
};

type HistoryChange = {
  id: number;
  field_name: string;
  from: string;
  to: string;
  updated_at: string;
  user_email: string;
  user_name: string;
};

export type ProductsManagementState = {
  listComposits: Composit[] | null;
  listCompositsTotal: number;
  listFillers: Filler[] | null;
  listFillersTotal: number;
  listPrimers: Primer[] | null;
  listPrimersTotal: number;
  listHistoryChanges: HistoryChange[] | null;
  listHistoryChangesTotal: number;
  isCreatedProduct: boolean;
  isEditedProduct: boolean;
  history: History[] | null;
  error: string | null;
  loading: boolean;
};

const initialState: ProductsManagementState = {
  listComposits: null,
  listCompositsTotal: 0,
  listFillers: null,
  listFillersTotal: 0,
  listPrimers: null,
  listPrimersTotal: 0,
  listHistoryChanges: null,
  listHistoryChangesTotal: 0,
  isCreatedProduct: false,
  isEditedProduct: false,
  history: null,
  error: null,
  loading: false,
};

export const getComposits = createAsyncThunk(
  "getAllComposits",
  async (_, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    const res = await fetch(`${apiUrl}/rea/v1.0/api/material/full`, {
      ...getFetchProp({
        method: "GET",
        contentType: "application/json",
        token: loginUser.token,
      }),
    });

    return res.json();
  }
);

export const getFillers = createAsyncThunk(
  "getAllFillers",
  async (_, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    const res = await fetch(`${apiUrl}/rea/v1.0/api/filler/full`, {
      ...getFetchProp({
        method: "GET",
        contentType: "application/json",
        token: loginUser.token,
      }),
    });

    return res.json();
  }
);

export const getPrimers = createAsyncThunk(
  "getAllPrimers",
  async (_, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    const res = await fetch(`${apiUrl}/rea/v1.0/api/primer/full`, {
      ...getFetchProp({
        method: "GET",
        contentType: "application/json",
        token: loginUser.token,
      }),
    });

    return res.json();
  }
);

type getHistoryInput = {
  id: number;
  type: string;
};

export const getHistory = createAsyncThunk(
  "getHistory",
  async ({ id, type }: getHistoryInput, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };

    const typeProduct = type === "composit" ? "MATERIAL" : type.toUpperCase();

    const res = await fetch(
      `${apiUrl}/rea/v1.0/api/history?history_type=${typeProduct}&id=${id}`,
      {
        ...getFetchProp({
          method: "GET",
          contentType: "application/json",
          token: loginUser.token,
        }),
      }
    );

    return res.json();
  }
);

export const getHistoryExport = createAsyncThunk(
  "getHistoryExport",
  async ({ id, type }: getHistoryInput, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };

    let filename = "report.zip";

    const typeProduct = type === "composit" ? "MATERIAL" : type.toUpperCase();

    const response = await fetch(
      `${apiUrl}/rea/v1.0/api/history/export?history_type=${typeProduct}&id=${id}`,
      {
        ...getFetchProp({
          method: "GET",
          contentType: "application/json",
          token: loginUser.token,
        }),
      }
    );

    if (!response.ok && response.status !== 200)
      throw new Error("Some error happend");

    const blob = await response.blob();

    if (response !== null && response.headers !== null) {
      const header_with_name = response?.headers?.get("Content-Disposition");
      if (header_with_name !== null) {
        const name = header_with_name.split("=").pop();

        if (name) {
          filename = name.substring(1, name.length - 1);
        }
      }
    }

    FileSaver.saveAs(blob, filename);
  }
);

export const getHistoryChanges = createAsyncThunk(
  "getHistoryChanges",
  async (type: string, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };

    const typeProduct =
      type === "composits"
        ? "MATERIAL"
        : type.substring(0, type.length - 1).toUpperCase();

    const res = await fetch(
      `${apiUrl}/rea/v1.0/api/history/all?history_type=${typeProduct}`,
      {
        ...getFetchProp({
          method: "GET",
          contentType: "application/json",
          token: loginUser.token,
        }),
      }
    );

    return res.json();
  }
);

interface ProductCreateEditFillerInput extends Omit<Filler, "id"> {
  id: number | null;
}

export const productCreateEditFiller = createAsyncThunk(
  "create_edit_Filler",
  async (
    {
      id,
      name,
      max_temp,
      is_subsea_env,
      suitable_for_leaks,
      density,
      defect_zone,
      display_for_distributor,
      status,
    }: ProductCreateEditFillerInput,
    { getState }
  ) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    if (
      (loginUser.accountType === "ADMINISTRATOR" ||
        loginUser.accountType === "TECHNICAL_ADMINISTRATOR") &&
      loginUser.token
    ) {
      const res = await fetch(`${apiUrl}/rea/v1.0/api/filler/full`, {
        ...getFetchProp({
          method: "POST",
          contentType: "application/json",
          token: loginUser.token,
          body: JSON.stringify([
            {
              id: id,
              name: name,
              max_temp,
              status,
              display_for_distributor: +display_for_distributor,
              is_subsea_env,
              suitable_for_leaks,
              density,
              defect_zone,
            },
          ]),
        }),
      });

      return res.json();
    }
  }
);

export const productEditSwitchFiller = createAsyncThunk(
  "edit_switch_Filler",
  async (
    {
      id,
      name,
      max_temp,
      is_subsea_env,
      suitable_for_leaks,
      density,
      defect_zone,
      display_for_distributor,
      status,
    }: ProductCreateEditFillerInput,
    { getState }
  ) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    if (
      (loginUser.accountType === "ADMINISTRATOR" ||
        loginUser.accountType === "TECHNICAL_ADMINISTRATOR") &&
      loginUser.token
    ) {
      const res = await fetch(`${apiUrl}/rea/v1.0/api/filler/full`, {
        ...getFetchProp({
          method: "POST",
          contentType: "application/json",
          token: loginUser.token,
          body: JSON.stringify([
            {
              id,
              name,
              max_temp,
              status,
              display_for_distributor: +display_for_distributor,
              is_subsea_env,
              suitable_for_leaks,
              density,
              defect_zone,
            },
          ]),
        }),
      });

      return res.json();
    }
  }
);

type productEditSwitchStatusInput = {
  id: number;
  status: boolean;
};

export const productEditSwitchFillerStatus = createAsyncThunk(
  "edit_switch_Filler_status",
  async ({ id, status }: productEditSwitchStatusInput, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    if (
      (loginUser.accountType === "ADMINISTRATOR" ||
        loginUser.accountType === "TECHNICAL_ADMINISTRATOR") &&
      loginUser.token
    ) {
      const res = await fetch(
        `${apiUrl}/rea/v1.0/api/filler/status?id=${id}&status=${status}`,
        {
          ...getFetchProp({
            method: "PUT",
            contentType: "application/json",
            token: loginUser.token,
          }),
        }
      );

      return new Promise<any>((resolve, reject) => {
        if (res.ok && res.status === 200) {
          resolve({ id, status });
        } else {
          reject(new Error("error"));
        }
      });
    }
  }
);

export const productDeleteFiller = createAsyncThunk(
  "product_delete_Filler",
  async (id: number, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    if (
      (loginUser.accountType === "ADMINISTRATOR" ||
        loginUser.accountType === "TECHNICAL_ADMINISTRATOR") &&
      loginUser.token
    ) {
      const res = await fetch(`${apiUrl}/rea/v1.0/api/filler?id=${id}`, {
        ...getFetchProp({
          method: "DELETE",
          contentType: "application/json",
          token: loginUser.token,
        }),
      });

      return new Promise<any>((resolve, reject) => {
        if (res.ok && res.status === 200) {
          resolve({ id });
        } else {
          reject(new Error("error"));
        }
      });
    }
  }
);

interface ProductCreateEditPrimerInput extends Omit<Primer, "id"> {
  id: number | null;
}

export const productCreateEditPrimer = createAsyncThunk(
  "create_edit_Primer",
  async (
    {
      id,
      name,
      max_temp,
      min_temp,
      max_temp_install,
      min_temp_install,
      status,
      display_for_distributor,
      is_subsea_env,
      consumption,
      weight_bucket,
    }: ProductCreateEditPrimerInput,
    { getState }
  ) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    if (
      (loginUser.accountType === "ADMINISTRATOR" ||
        loginUser.accountType === "TECHNICAL_ADMINISTRATOR") &&
      loginUser.token
    ) {
      const res = await fetch(`${apiUrl}/rea/v1.0/api/primer/full`, {
        ...getFetchProp({
          method: "POST",
          contentType: "application/json",
          token: loginUser.token,
          body: JSON.stringify([
            {
              id,
              name,
              max_temp,
              min_temp,
              max_temp_install,
              min_temp_install,
              status,
              display_for_distributor: +display_for_distributor,
              is_subsea_env,
              consumption,
              weight_bucket,
            },
          ]),
        }),
      });

      return res.json();
    }
  }
);

export const productEditSwitchPrimer = createAsyncThunk(
  "edit_switch_Primer",
  async (
    {
      id,
      name,
      max_temp,
      min_temp,
      max_temp_install,
      min_temp_install,
      status,
      display_for_distributor,
      is_subsea_env,
      consumption,
      weight_bucket,
    }: Primer,
    { getState }
  ) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    if (
      (loginUser.accountType === "ADMINISTRATOR" ||
        loginUser.accountType === "TECHNICAL_ADMINISTRATOR") &&
      loginUser.token
    ) {
      const res = await fetch(`${apiUrl}/rea/v1.0/api/primer/full`, {
        ...getFetchProp({
          method: "POST",
          contentType: "application/json",
          token: loginUser.token,
          body: JSON.stringify([
            {
              id,
              name,
              max_temp,
              min_temp,
              max_temp_install,
              min_temp_install,
              status,
              display_for_distributor: +display_for_distributor,
              is_subsea_env,
              consumption,
              weight_bucket,
              catalog: 1,
            },
          ]),
        }),
      });

      return res.json();
    }
  }
);

export const productEditSwitchPrimerStatus = createAsyncThunk(
  "edit_switch_Primer_status",
  async ({ id, status }: productEditSwitchStatusInput, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    if (
      (loginUser.accountType === "ADMINISTRATOR" ||
        loginUser.accountType === "TECHNICAL_ADMINISTRATOR") &&
      loginUser.token
    ) {
      const res = await fetch(
        `${apiUrl}/rea/v1.0/api/primer/status?id=${id}&status=${status}`,
        {
          ...getFetchProp({
            method: "PUT",
            contentType: "application/json",
            token: loginUser.token,
          }),
        }
      );

      return new Promise<any>((resolve, reject) => {
        if (res.ok && res.status === 200) {
          resolve({ id, status });
        } else {
          reject(new Error("error"));
        }
      });
    }
  }
);

export const productDeletePrimer = createAsyncThunk(
  "product_delete_Primer",
  async (id: number, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    if (
      (loginUser.accountType === "ADMINISTRATOR" ||
        loginUser.accountType === "TECHNICAL_ADMINISTRATOR") &&
      loginUser.token
    ) {
      const res = await fetch(`${apiUrl}/rea/v1.0/api/primer?id=${id}`, {
        ...getFetchProp({
          method: "DELETE",
          contentType: "application/json",
          token: loginUser.token,
        }),
      });

      return new Promise<any>((resolve, reject) => {
        if (res.ok && res.status === 200) {
          resolve({ id });
        } else {
          reject(new Error("error"));
        }
      });
    }
  }
);

interface ProductCreateEditCompositInput extends Omit<Composit, "id"> {
  id: number | null;
}

export const productCreateEditComposit = createAsyncThunk(
  "create_edit_Composit",
  async (
    {
      alpha_a,
      alpha_c,
      color_hexadecimal,
      composite_length,
      composite_thickness,
      composite_type,
      composite_width,
      display_for_distributor,
      e_a,
      e_c,
      epsilon_lt,
      g_lcl_1,
      g_lcl_2,
      g_lcl_3,
      id,
      laminate_covering_offset_percent,
      maximum_installation_temperature,
      maximum_operating_temperature,
      minimum_installation_temperature,
      minimum_operating_temperature,
      nb_resin_tape,
      nu_lt,
      performance_data,
      resin_name,
      sheer_modulus,
      sigma_lt,
      status,
      suitable_residual_or_internal_corrosion,
      suitable_subsea,
      t_amb,
      t_test,
      tape_type,
      tau,
      tg_c,
      trade_name,
    }: ProductCreateEditCompositInput,
    { getState }
  ) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    if (
      (loginUser.accountType === "ADMINISTRATOR" ||
        loginUser.accountType === "TECHNICAL_ADMINISTRATOR") &&
      loginUser.token
    ) {
      const res = await fetch(`${apiUrl}/rea/v1.0/api/material/full`, {
        ...getFetchProp({
          method: "POST",
          contentType: "application/json",
          token: loginUser.token,
          body: JSON.stringify([
            {
              alpha_a,
              alpha_c,
              color_hexadecimal,
              composite_length,
              composite_thickness,
              composite_type,
              composite_width,
              display_for_distributor,
              e_a,
              e_c,
              epsilon_lt,
              g_lcl_1,
              g_lcl_2,
              g_lcl_3,
              id,
              laminate_covering_offset_percent,
              maximum_installation_temperature,
              maximum_operating_temperature,
              minimum_installation_temperature,
              minimum_operating_temperature,
              nb_resin_tape,
              nu_lt,
              performance_data,
              resin_name,
              sheer_modulus,
              sigma_lt,
              status,
              suitable_residual_or_internal_corrosion,
              suitable_subsea,
              t_amb,
              t_test,
              tape_type,
              tau,
              tg_c,
              trade_name,
            },
          ]),
        }),
      });

      return res.json();
    }
  }
);

export const productEditSwitchComposit = createAsyncThunk(
  "edit_switch_Composit",
  async (
    {
      alpha_a,
      alpha_c,
      color_hexadecimal,
      composite_length,
      composite_thickness,
      composite_type,
      composite_width,
      display_for_distributor,
      e_a,
      e_c,
      epsilon_lt,
      g_lcl_1,
      g_lcl_2,
      g_lcl_3,
      id,
      laminate_covering_offset_percent,
      maximum_installation_temperature,
      maximum_operating_temperature,
      minimum_installation_temperature,
      minimum_operating_temperature,
      nb_resin_tape,
      nu_lt,
      performance_data,
      resin_name,
      sheer_modulus,
      sigma_lt,
      status,
      suitable_residual_or_internal_corrosion,
      suitable_subsea,
      t_amb,
      t_test,
      tape_type,
      tau,
      tg_c,
      trade_name,
    }: Composit,
    { getState }
  ) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    if (
      (loginUser.accountType === "ADMINISTRATOR" ||
        loginUser.accountType === "TECHNICAL_ADMINISTRATOR") &&
      loginUser.token
    ) {
      const res = await fetch(`${apiUrl}/rea/v1.0/api/material/full`, {
        ...getFetchProp({
          method: "POST",
          contentType: "application/json",
          token: loginUser.token,
          body: JSON.stringify([
            {
              alpha_a,
              alpha_c,
              color_hexadecimal,
              composite_length,
              composite_thickness,
              composite_type,
              composite_width,
              display_for_distributor,
              e_a,
              e_c,
              epsilon_lt,
              g_lcl_1,
              g_lcl_2,
              g_lcl_3,
              id,
              laminate_covering_offset_percent,
              maximum_installation_temperature,
              maximum_operating_temperature,
              minimum_installation_temperature,
              minimum_operating_temperature,
              nb_resin_tape,
              nu_lt,
              performance_data,
              resin_name,
              sheer_modulus,
              sigma_lt,
              status,
              suitable_residual_or_internal_corrosion,
              suitable_subsea,
              t_amb,
              t_test,
              tape_type,
              tau,
              tg_c,
              trade_name,
            },
          ]),
        }),
      });

      return res.json();
    }
  }
);

export const productEditSwitchCompositStatus = createAsyncThunk(
  "edit_switch_Composit_status",
  async ({ id, status }: productEditSwitchStatusInput, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    if (
      (loginUser.accountType === "ADMINISTRATOR" ||
        loginUser.accountType === "TECHNICAL_ADMINISTRATOR") &&
      loginUser.token
    ) {
      const res = await fetch(
        `${apiUrl}/rea/v1.0/api/material/status?id=${id}&status=${status}`,
        {
          ...getFetchProp({
            method: "PUT",
            contentType: "application/json",
            token: loginUser.token,
          }),
        }
      );

      return new Promise<any>((resolve, reject) => {
        if (res.ok && res.status === 200) {
          resolve({ id, status });
        } else {
          reject(new Error("error"));
        }
      });
    }
  }
);

export const productDeleteComposit = createAsyncThunk(
  "product_delete_Composit",
  async (id: number, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    if (
      (loginUser.accountType === "ADMINISTRATOR" ||
        loginUser.accountType === "TECHNICAL_ADMINISTRATOR") &&
      loginUser.token
    ) {
      const res = await fetch(`${apiUrl}/rea/v1.0/api/material?id=${id}`, {
        ...getFetchProp({
          method: "DELETE",
          contentType: "application/json",
          token: loginUser.token,
        }),
      });

      return new Promise<any>((resolve, reject) => {
        if (res.ok && res.status === 200) {
          resolve({ id });
        } else {
          reject(new Error("error"));
        }
      });
    }
  }
);

type groupProducts = {
  ids: number[];
  nameProduct: string;
};

export const productGroupDelete = createAsyncThunk(
  "product_Group_delete",
  async ({ ids, nameProduct }: groupProducts, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    if (
      (loginUser.accountType === "ADMINISTRATOR" ||
        loginUser.accountType === "TECHNICAL_ADMINISTRATOR") &&
      loginUser.token
    ) {
      let query = "";
      ids.forEach((item, index) => {
        if (index + 1 === ids.length) {
          query += `ids=${item}`;
        } else query += `ids=${item}&`;
      });

      const res = await fetch(
        `${apiUrl}/rea/v1.0/api/${nameProduct}/all?${query}`,
        {
          ...getFetchProp({
            method: "DELETE",
            contentType: "application/json",
            token: loginUser.token,
          }),
        }
      );

      return new Promise<any>((resolve, reject) => {
        if (res.ok && res.status === 200) {
          resolve({ ids });
        } else {
          reject(new Error("error"));
        }
      });
    }
  }
);

const productsManagementSlice = createSlice({
  name: "products management",
  initialState,
  reducers: {
    cleanError(state) {
      state.error = null;
    },
    cleanHistory(state) {
      state.history = null;
    },
    resetCreatedProduct(state) {
      state.isCreatedProduct = false;
      state.isEditedProduct = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getComposits.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getComposits.fulfilled, (state, action) => {
        state.listComposits = action.payload;
        state.listCompositsTotal = action.payload.length;
        state.loading = false;
        state.error = null;
      })
      .addCase(getComposits.rejected, (state) => {
        state.loading = false;
        state.error = "Request get composits is fail!";
      })
      .addCase(getFillers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getFillers.fulfilled, (state, action) => {
        state.listFillers = action.payload;
        state.listFillersTotal = action.payload.length;
        state.loading = false;
        state.error = null;
      })
      .addCase(getFillers.rejected, (state) => {
        state.loading = false;
        state.error = "Request get fillers is fail!";
      })
      .addCase(getPrimers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPrimers.fulfilled, (state, action) => {
        state.listPrimers = action.payload;
        state.listPrimersTotal = action.payload.length;
        state.loading = false;
        state.error = null;
      })
      .addCase(getPrimers.rejected, (state) => {
        state.loading = false;
        state.error = "Request get primers is fail!";
      })
      .addCase(getHistory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getHistory.fulfilled, (state, action) => {
        state.history = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getHistory.rejected, (state) => {
        state.loading = false;
        state.error = "Request get history is fail!";
      })
      .addCase(getHistoryExport.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getHistoryExport.fulfilled, (state) => {
        state.error = null;
        state.loading = false;
      })
      .addCase(getHistoryExport.rejected, (state) => {
        state.loading = false;
        state.error = "Request get history export is fail!";
      })
      .addCase(getHistoryChanges.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getHistoryChanges.fulfilled, (state, action) => {
        state.listHistoryChanges = action.payload;
        state.listHistoryChangesTotal = action.payload.length;
        state.loading = false;
        state.error = null;
      })
      .addCase(getHistoryChanges.rejected, (state) => {
        state.loading = false;
        state.error = "Request get history of changes is fail!";
      })
      .addCase(productCreateEditComposit.pending, (state) => {
        state.isCreatedProduct = false;
        state.loading = true;
        state.error = null;
      })
      .addCase(productCreateEditComposit.fulfilled, (state, action) => {
        if (
          Array.isArray(action.payload) &&
          action.payload.length === 1 &&
          action.payload[0].id !== null
        ) {
          state.error = null;
          if (state.listComposits !== null) {
            const newList = [...state.listComposits];
            const ind = newList.findIndex(
              (item) => item.id === action.payload[0].id
            );
            if (ind > -1) {
              newList[ind] = {
                ...action.payload[0],
              };
              state.listComposits = [...newList];
              state.isEditedProduct = true;
            } else {
              state.listComposits = [...state.listComposits, ...action.payload];
              state.listCompositsTotal = state.listComposits.length;
              state.isCreatedProduct = true;
            }
          } else {
            state.listComposits = action.payload;
            state.listCompositsTotal = 1;
            state.isCreatedProduct = true;
          }
        }
        if (
          action.payload.status &&
          action.payload.status > 0 &&
          action.payload.error
        ) {
          state.error = action.payload.error;
        }

        state.loading = false;
      })
      .addCase(productCreateEditComposit.rejected, (state) => {
        state.loading = false;
        state.error = "Request create/edit product composit is fail!";
      })
      .addCase(productEditSwitchComposit.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(productEditSwitchComposit.fulfilled, (state, action) => {
        if (
          Array.isArray(action.payload) &&
          action.payload.length === 1 &&
          action.payload[0].id !== null
        ) {
          state.error = null;
          if (state.listComposits !== null) {
            const newList = [...state.listComposits];
            const ind = newList.findIndex(
              (item) => item.id === action.payload[0].id
            );

            if (ind > -1) {
              newList[ind] = {
                ...action.payload[0],
              };
              state.listComposits = [...newList];
            }
          }
        }
        if (
          action.payload.status &&
          action.payload.status > 0 &&
          action.payload.error
        ) {
          state.error = action.payload.error;
        }

        state.loading = false;
      })
      .addCase(productEditSwitchComposit.rejected, (state) => {
        state.loading = false;
        state.error = "Request edit product composit is fail!";
      })
      .addCase(productCreateEditFiller.pending, (state) => {
        state.isCreatedProduct = false;
        state.loading = true;
        state.error = null;
      })
      .addCase(productEditSwitchCompositStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(productEditSwitchCompositStatus.fulfilled, (state, action) => {
        if (action.payload && action.payload.id > 0) {
          state.error = null;
          if (state.listComposits !== null) {
            const newList = [...state.listComposits];
            const ind = newList.findIndex(
              (item) => item.id === action.payload.id
            );

            if (ind > -1) {
              newList[ind] = {
                ...newList[ind],
                status: action.payload.status,
              };
              state.listComposits = [...newList];
            }
          }
        }

        state.loading = false;
      })
      .addCase(productEditSwitchCompositStatus.rejected, (state) => {
        state.loading = false;
        state.error = "Request edit status composit is fail!";
      })
      .addCase(productDeleteComposit.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(productDeleteComposit.fulfilled, (state, action) => {
        if (action.payload && action.payload.id > 0) {
          state.error = null;

          if (state.listComposits !== null) {
            state.listComposits = state.listComposits.filter(
              (item) => item.id !== action.payload.id
            );
          }
        }

        state.loading = false;
      })
      .addCase(productDeleteComposit.rejected, (state) => {
        state.loading = false;
        state.error = "Request delete product composit is fail!";
      })
      .addCase(productCreateEditFiller.fulfilled, (state, action) => {
        if (
          Array.isArray(action.payload) &&
          action.payload.length === 1 &&
          action.payload[0].id !== null
        ) {
          state.error = null;
          if (state.listFillers !== null) {
            const newList = [...state.listFillers];
            const ind = newList.findIndex(
              (item) => item.id === action.payload[0].id
            );
            if (ind > -1) {
              newList[ind] = {
                ...action.payload[0],
              };
              state.listFillers = [...newList];
              state.isEditedProduct = true;
            } else {
              state.listFillers = [...state.listFillers, ...action.payload];
              state.listFillersTotal = state.listFillers.length;
              state.isCreatedProduct = true;
            }
          } else {
            state.listFillers = action.payload;
            state.listFillersTotal = 1;
            state.isCreatedProduct = true;
          }
        }
        if (
          action.payload.status &&
          action.payload.status > 0 &&
          action.payload.error
        ) {
          state.error = action.payload.error;
        }

        state.loading = false;
      })
      .addCase(productCreateEditFiller.rejected, (state) => {
        state.loading = false;
        state.error = "Request create/edit product filler is fail!";
      })
      .addCase(productEditSwitchFiller.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(productEditSwitchFiller.fulfilled, (state, action) => {
        if (
          Array.isArray(action.payload) &&
          action.payload.length === 1 &&
          action.payload[0].id !== null
        ) {
          state.error = null;
          if (state.listFillers !== null) {
            const newList = [...state.listFillers];
            const ind = newList.findIndex(
              (item) => item.id === action.payload[0].id
            );

            if (ind > -1) {
              newList[ind] = {
                ...action.payload[0],
              };
              state.listFillers = [...newList];
            }
          }
        }
        if (
          action.payload.status &&
          action.payload.status > 0 &&
          action.payload.error
        ) {
          state.error = action.payload.error;
        }

        state.loading = false;
      })
      .addCase(productEditSwitchFiller.rejected, (state) => {
        state.loading = false;
        state.error = "Request edit product filler is fail!";
      })
      .addCase(productEditSwitchFillerStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(productEditSwitchFillerStatus.fulfilled, (state, action) => {
        if (action.payload && action.payload.id > 0) {
          state.error = null;
          if (state.listFillers !== null) {
            const newList = [...state.listFillers];
            const ind = newList.findIndex(
              (item) => item.id === action.payload.id
            );

            if (ind > -1) {
              newList[ind] = {
                ...newList[ind],
                status: action.payload.status,
              };
              state.listFillers = [...newList];
            }
          }
        }

        state.loading = false;
      })
      .addCase(productEditSwitchFillerStatus.rejected, (state) => {
        state.loading = false;
        state.error = "Request edit status filler is fail!";
      })
      .addCase(productDeleteFiller.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(productDeleteFiller.fulfilled, (state, action) => {
        if (action.payload && action.payload.id > 0) {
          state.error = null;

          if (state.listFillers !== null) {
            state.listFillers = state.listFillers.filter(
              (item) => item.id !== action.payload.id
            );
          }
        }

        state.loading = false;
      })
      .addCase(productDeleteFiller.rejected, (state) => {
        state.loading = false;
        state.error = "Request delete product filler is fail!";
      })
      .addCase(productCreateEditPrimer.pending, (state) => {
        state.isCreatedProduct = false;
        state.loading = true;
        state.error = null;
      })
      .addCase(productCreateEditPrimer.fulfilled, (state, action) => {
        if (
          Array.isArray(action.payload) &&
          action.payload.length === 1 &&
          action.payload[0].id !== null
        ) {
          state.error = null;
          if (state.listPrimers !== null) {
            const newList = [...state.listPrimers];
            const ind = newList.findIndex(
              (item) => item.id === action.payload[0].id
            );
            if (ind > -1) {
              newList[ind] = {
                ...action.payload[0],
              };
              state.listPrimers = [...newList];
              state.isEditedProduct = true;
            } else {
              state.listPrimers = [...state.listPrimers, ...action.payload];
              state.listPrimersTotal = state.listPrimers.length;
              state.isCreatedProduct = true;
            }
          } else {
            state.listPrimers = action.payload;
            state.listPrimersTotal = 1;
            state.isCreatedProduct = true;
          }
        }
        if (
          action.payload.status &&
          action.payload.status > 0 &&
          action.payload.error
        ) {
          state.error = action.payload.error;
        }

        state.loading = false;
      })
      .addCase(productCreateEditPrimer.rejected, (state) => {
        state.loading = false;
        state.error = "Request create/edit product primer is fail!";
      })
      .addCase(productEditSwitchPrimer.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(productEditSwitchPrimer.fulfilled, (state, action) => {
        if (
          Array.isArray(action.payload) &&
          action.payload.length === 1 &&
          action.payload[0].id !== null
        ) {
          state.error = null;
          if (state.listPrimers !== null) {
            const newList = [...state.listPrimers];
            const ind = newList.findIndex(
              (item) => item.id === action.payload[0].id
            );

            if (ind > -1) {
              newList[ind] = {
                ...action.payload[0],
              };
              state.listPrimers = [...newList];
            }
          }
        }
        if (
          action.payload.status &&
          action.payload.status > 0 &&
          action.payload.error
        ) {
          state.error = action.payload.error;
        }

        state.loading = false;
      })
      .addCase(productEditSwitchPrimer.rejected, (state) => {
        state.loading = false;
        state.error = "Request edit product primer is fail!";
      })
      .addCase(productEditSwitchPrimerStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(productEditSwitchPrimerStatus.fulfilled, (state, action) => {
        if (action.payload && action.payload.id > 0) {
          state.error = null;
          if (state.listPrimers !== null) {
            const newList = [...state.listPrimers];
            const ind = newList.findIndex(
              (item) => item.id === action.payload.id
            );

            if (ind > -1) {
              newList[ind] = {
                ...newList[ind],
                status: action.payload.status,
              };
              state.listPrimers = [...newList];
            }
          }
        }

        state.loading = false;
      })
      .addCase(productEditSwitchPrimerStatus.rejected, (state) => {
        state.loading = false;
        state.error = "Request edit status primer is fail!";
      })
      .addCase(productDeletePrimer.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(productDeletePrimer.fulfilled, (state, action) => {
        if (action.payload && action.payload.id > 0) {
          state.error = null;

          if (state.listPrimers !== null) {
            state.listPrimers = state.listPrimers.filter(
              (item) => item.id !== action.payload.id
            );
          }
        }

        state.loading = false;
      })
      .addCase(productDeletePrimer.rejected, (state) => {
        state.loading = false;
        state.error = "Request delete product primer is fail!";
      })
      .addCase(productGroupDelete.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(productGroupDelete.fulfilled, (state, action) => {
        if (
          action.meta.arg.nameProduct === "filler" &&
          state.listFillers !== null
        ) {
          state.listFillers = state.listFillers.filter(
            (item) => !action.meta.arg.ids.includes(item.id)
          );

          state.listFillersTotal = state.listFillers.length;
        }

        if (
          action.meta.arg.nameProduct === "material" &&
          state.listComposits !== null
        ) {
          state.listComposits = state.listComposits.filter(
            (item) => !action.meta.arg.ids.includes(item.id)
          );

          state.listCompositsTotal = state.listComposits.length;
        }

        if (
          action.meta.arg.nameProduct === "primer" &&
          state.listPrimers !== null
        ) {
          state.listPrimers = state.listPrimers.filter(
            (item) => !action.meta.arg.ids.includes(item.id)
          );

          state.listPrimersTotal = state.listPrimers.length;
        }

        state.loading = false;
        state.error = null;
      })
      .addCase(productGroupDelete.rejected, (state) => {
        state.loading = false;
        state.error = "Request group delete products is fail!";
      });
  },
});

export const { cleanError, cleanHistory, resetCreatedProduct } =
  productsManagementSlice.actions;

export type { Filler, Primer, Composit, History };

export default productsManagementSlice.reducer;
