import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import FileSaver from "file-saver";

import { getFetchProp } from "helpers/getFetchProp";

import { LoginUserState } from "features/auth/auth-slice";

import { dataFilterUrl } from "../../helpers/createUrl";
// import UserSvg from "assets/images/user.svg";

import { apiUrl } from "../../constants";

interface Filler {
  id: number;
  name: string;
}

type Primer = {
  id: number;
  name: string;
};
// type TapeRef = [string];

type Composit = {
  id: number;
  resin_name: string;
  composite_type: string;
  tape_type: string;
};

type IDatePickers = [startDate: string, endDate: string];

interface Filters {
  geozone: string | null;
  location: string | null;
  tapeRef: string | null;
  product: string | null;
  resinRef: string | null;
  fillerRef: string | null;
  primerRef: string | null;
  userType: string;
}

type User = {
  id: number;
  email: string;
  name: string;
  geozone: string;
  location: string;
  status: boolean;
  account_status: string;
  role: string;
};

type UserStatistic = {
  id: number;
  name: string;
  geo_zone: string;
  is_distributor: boolean;
};

type content = {
  id: number;
  status: string;
  status_description: string;
  distributor_id: number;
  distributor_name: string;
  sale_manager_id: number;
};

export interface Marketing {
  name: string;
  total: number;
  patch: number;
}

export interface MarketingDetails {
  resin: [];
  tape_type: [];
  width_type: [];
}

interface TechnicalStatisticRow {
  [key: string]: [];
}

type TechnicalStatisticType = TechnicalStatisticRow & {
  total: number;
};

export type StatisticsState = {
  listRisnRef: [];
  listProduct: [];
  listComposits: Composit[];
  listTapeRef: [];
  listPrimers: Primer[] | null;
  listFillers: Filler[] | null;
  numberOfUsers: number;
  numberOfConnectedUsers: number;
  persentOfConnectedUsers: number;
  listUsers: User[];
  listUsersStatistic: UserStatistic[];
  geoZones: [];
  statuses: [];
  locations: [] | null;
  geoByUser: null | string;
  error: string | null;
  loading: boolean;
  allProjects: {
    content: content[];
  };
  totalCreatedProjectsByUser: number;
  totalClosedProject: number;
  totalValidated: number;
  ActiveTotalGenereted: number;
  ProductivityTotalGenereted: number;
  ProductivitySentTechProp: number;
  totalValidation: number;
  allDistributors: User[];
  totalCreatedProjects: number;
  totalRepairCases: number;
  totalValidatedProposal: number;
  totalRepairLength: number;
  totalUsedLength: number;
  totalNumberOfPatches: number;
  totalNumberQuantity: number;
  totalQuantityFillers: number;
  totalQuantityPrimers: number;
  sentTechProposal: [];
  totalSentTechProposal: number;
  acceptedTechProposal: [];
  totalAcceptedTechProposal: number;
  marketingStatistic: { result: Marketing[]; total: number } | null;
  marketingStatisticDetails: MarketingDetails | null;
  technicalStatisticDescriptions: { [key: string]: string } | null;
  technicalStatistic: TechnicalStatisticType | null;
};

const initialState: StatisticsState = {
  listRisnRef: [],
  listProduct: [],
  listTapeRef: [],
  listPrimers: null,
  listFillers: null,
  listComposits: [],
  numberOfUsers: 0,
  numberOfConnectedUsers: 0,
  persentOfConnectedUsers: 0,
  listUsers: [],
  listUsersStatistic: [],
  geoZones: [],
  statuses: [],
  locations: null,
  geoByUser: null,
  error: null,
  loading: false,
  allProjects: {
    content: [],
  },
  allDistributors: [],
  totalCreatedProjectsByUser: 0,
  totalClosedProject: 0,
  totalValidated: 0,
  ActiveTotalGenereted: 0,
  ProductivityTotalGenereted: 0,
  ProductivitySentTechProp: 0,
  totalValidation: 0,
  totalCreatedProjects: 0,
  totalRepairCases: 0,
  totalValidatedProposal: 0,
  totalRepairLength: 0,
  totalUsedLength: 0,
  totalNumberOfPatches: 0,
  totalNumberQuantity: 0,
  totalQuantityFillers: 0,
  totalQuantityPrimers: 0,
  sentTechProposal: [],
  totalSentTechProposal: 0,
  acceptedTechProposal: [],
  totalAcceptedTechProposal: 0,
  marketingStatistic: null,
  marketingStatisticDetails: null,
  technicalStatisticDescriptions: null,
  technicalStatistic: null,
};

export const getComposits = createAsyncThunk(
  "getAllComposits",
  async (_, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    const res = await fetch(`${apiUrl}/rea/v1.0/api/material`, {
      ...getFetchProp({
        method: "GET",
        contentType: "application/json",
        token: loginUser.token,
      }),
    });

    return res.json();
  }
);
export const getFillers = createAsyncThunk(
  "getAllFillers",
  async (_, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    const res = await fetch(`${apiUrl}/rea/v1.0/api/filler/full`, {
      ...getFetchProp({
        method: "GET",
        contentType: "application/json",
        token: loginUser.token,
      }),
    });

    return res.json();
  }
);

export const getPrimers = createAsyncThunk(
  "getAllPrimers",
  async (_, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    const res = await fetch(`${apiUrl}/rea/v1.0/api/primer/full`, {
      ...getFetchProp({
        method: "GET",
        contentType: "application/json",
        token: loginUser.token,
      }),
    });

    return res.json();
  }
);

export const getGeoZonesStatistic = createAsyncThunk(
  "getGeoZonesStatistic",
  async (_, { rejectWithValue, dispatch, getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    const res = await fetch(`${apiUrl}/rea/v1.0/api/statistic/geozones`, {
      ...getFetchProp({
        method: "GET",
        contentType: "application/json",
        token: loginUser.token,
      }),
    });

    return res.json();
  }
);

export const getStatuses = createAsyncThunk(
  "geoStatuses",
  async (_, { rejectWithValue, dispatch, getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    const res = await fetch(`${apiUrl}/rea/v1.0/api/statistic/statuses`, {
      ...getFetchProp({
        method: "GET",
        contentType: "application/json",
        token: loginUser.token,
      }),
    });

    return res.json();
  }
);

export const getUsersStatistic = createAsyncThunk(
  "getUsersStatistic",
  async (_, { rejectWithValue, dispatch, getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    const res = await fetch(`${apiUrl}/rea/v1.0/api/statistic/users`, {
      ...getFetchProp({
        method: "GET",
        contentType: "application/json",
        token: loginUser.token,
      }),
    });

    return res.json();
  }
);

export const getLocation = createAsyncThunk(
  "Location",
  async (_, { rejectWithValue, dispatch, getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    const res = await fetch(`${apiUrl}/rea/v1.0/api/statistic/locations`, {
      ...getFetchProp({
        method: "GET",
        contentType: "application/json",
        token: loginUser.token,
      }),
    });

    return res.json();
  }
);

export const getFiltredUsers = createAsyncThunk(
  "getFiltredUsers",
  async (str: string, { rejectWithValue, dispatch, getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    const res = await fetch(
      `${apiUrl}/rea/v1.0/api/statistic/user_statistic?${str}`,
      {
        ...getFetchProp({
          method: "GET",
          contentType: "application/json",
          token: loginUser.token,
        }),
      }
    );

    return res.json();
  }
);

export const getScoringOfUser = createAsyncThunk(
  "getScoringOfUser",
  async (str: string, { rejectWithValue, dispatch, getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    const res = await fetch(
      `${apiUrl}/rea/v1.0/api/statistic/user_scoring?${str}`,
      {
        ...getFetchProp({
          method: "GET",
          contentType: "application/json",
          token: loginUser.token,
        }),
      }
    );

    return res.json();
  }
);

export const getCorelationData = createAsyncThunk(
  "getCorelationData",
  async (str: string, { rejectWithValue, dispatch, getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    const res = await fetch(
      `${apiUrl}/rea/v1.0/api/statistic/data_corelation_statistic?${str}`,
      {
        ...getFetchProp({
          method: "GET",
          contentType: "application/json",
          token: loginUser.token,
        }),
      }
    );

    return res.json();
  }
);
export const getUsersConfirmed = createAsyncThunk(
  "getAllConfirmedUsers",
  async (_, { rejectWithValue, dispatch, getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    const res = await fetch(`${apiUrl}/rea/v1.0/api/user/confirmed`, {
      ...getFetchProp({
        method: "GET",
        contentType: "application/json",
        token: loginUser.token,
      }),
    });

    return res.json();
  }
);
export const getAllProjects = createAsyncThunk(
  "get_all_projects",
  async (_, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    const res = await fetch(
      `${apiUrl}/rea/v1.0/api/project/all_projects?page=0&size=1000`,
      {
        ...getFetchProp({
          method: "GET",
          contentType: "application/json",
          token: loginUser.token,
        }),
      }
    );

    return res.json();
  }
);
export const fetchDistributors = createAsyncThunk(
  "fetch_distributors",
  async (_, { rejectWithValue, dispatch, getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };

    const res = await fetch(`${apiUrl}/rea/v1.0/api/user/distributor`, {
      ...getFetchProp({
        token: loginUser.token,
      }),
    });

    return res.json();
  }
);
export const getDataFiltredValues = createAsyncThunk(
  "get_data_values",
  async (
    { dates, filteredData }: { dates: IDatePickers; filteredData: Filters },
    { getState }
  ) => {
    const {
      geozone,
      fillerRef,
      location,
      primerRef,
      resinRef,
      product,
      tapeRef,
      userType,
    } = filteredData;
    const [startDate, endDate] = dates;
    const { loginUser } = getState() as { loginUser: LoginUserState };
    const res = await fetch(
      `${apiUrl}/rea/v1.0/api/statistic/data_statistic?${dataFilterUrl(
        startDate,
        endDate,
        geozone,
        location,
        tapeRef,
        product,
        resinRef,
        fillerRef,
        primerRef,
        userType
      )}`,
      {
        ...getFetchProp({
          method: "GET",
          contentType: "application/json",
          token: loginUser.token,
        }),
      }
    );

    return res.json();
  }
);

type MarketingStatisticInput = {
  userType: string;
  geoZone?: string;
  userId?: string;
  status?: string;
  compositeType?: string;
};

export const getMarketingStatistic = createAsyncThunk(
  "getMarketingStatistic",
  async (
    { userType, geoZone, status, userId }: MarketingStatisticInput,
    { getState }
  ) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };

    let query = `user_type=${userType}`;

    if (geoZone !== undefined) {
      query = `${query}&geo_zone=${geoZone}`;
    }

    if (status !== undefined) {
      query = `${query}&status=${status}`;
    }

    if (userId !== undefined) {
      query = `${query}&user_id=${userId}`;
    }

    const res = await fetch(
      `${apiUrl}/rea/v1.0/api/statistic/marketing?${query}`,
      {
        ...getFetchProp({
          method: "GET",
          contentType: "application/json",
          token: loginUser.token,
        }),
      }
    );

    return res.json();
  }
);

export const getMarketingStatisticDetails = createAsyncThunk(
  "getMarketingStatisticDetails",
  async (
    {
      compositeType,
      userType,
      geoZone,
      status,
      userId,
    }: MarketingStatisticInput,
    { getState }
  ) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };

    let query = `user_type=${userType}`;

    if (compositeType !== undefined) {
      query = `${query}&composite_type=${compositeType}`;
    }

    if (geoZone !== undefined) {
      query = `${query}&geo_zone=${geoZone}`;
    }

    if (status !== undefined) {
      query = `${query}&status=${status}`;
    }

    if (userId !== undefined) {
      query = `${query}&user_id=${userId}`;
    }

    const res = await fetch(
      `${apiUrl}/rea/v1.0/api/statistic/marketing_details?${query}`,
      {
        ...getFetchProp({
          method: "GET",
          contentType: "application/json",
          token: loginUser.token,
        }),
      }
    );

    return res.json();
  }
);

export const getTechnicalStatisticDescription = createAsyncThunk(
  "getTechnicalStatisticDescription",
  async (_, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };

    const res = await fetch(
      `${apiUrl}/rea/v1.0/api/statistic/tech_details_description`,
      {
        ...getFetchProp({
          method: "GET",
          contentType: "application/json",
          token: loginUser.token,
        }),
      }
    );

    return res.json();
  }
);

type TechnicalStatisticInput = {
  userType: string;
  geoZone?: string;
  userId?: string;
  status?: string;
};

export const getTechnicalStatistic = createAsyncThunk(
  "getTechnicalStatistic",
  async (
    { userType, geoZone, status, userId }: TechnicalStatisticInput,
    { getState }
  ) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };

    let query = `user_type=${userType}`;

    if (geoZone !== undefined) {
      query = `${query}&geo_zone=${geoZone}`;
    }

    if (status !== undefined) {
      query = `${query}&status=${status}`;
    }

    if (userId !== undefined) {
      query = `${query}&user_id=${userId}`;
    }

    const res = await fetch(
      `${apiUrl}/rea/v1.0/api/statistic/tech_details?${query}`,
      {
        ...getFetchProp({
          method: "GET",
          contentType: "application/json",
          token: loginUser.token,
        }),
      }
    );

    return res.json();
  }
);

export const getTechnicalStatisticReport = createAsyncThunk(
  "getTechnicalStatisticReport",
  async (_, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };

    let filename = "statistic_technical_report.zip";

    const response = await fetch(
      `${apiUrl}/rea/v1.0/api/statistic/download_technical_report`,
      {
        ...getFetchProp({
          method: "GET",
          contentType: "application/json",
          token: loginUser.token,
        }),
      }
    );

    if (!response.ok && response.status !== 200)
      throw new Error("Some error happend");

    const blob = await response.blob();

    if (response !== null && response.headers !== null) {
      const header_with_name = response?.headers?.get("Content-Disposition");
      if (header_with_name !== null) {
        const name = header_with_name.split("=").pop();

        if (name) {
          filename = name.substring(1, name.length - 1);
        }
      }
    }

    FileSaver.saveAs(blob, filename);
  }
);

export const getMarketingStatisticReport = createAsyncThunk(
  "getMarketingStatisticReport",
  async (_, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };

    let filename = "statistic_marketing_report.zip";

    const response = await fetch(
      `${apiUrl}/rea/v1.0/api/statistic/download_marketing_report`,
      {
        ...getFetchProp({
          method: "GET",
          contentType: "application/json",
          token: loginUser.token,
        }),
      }
    );

    if (!response.ok && response.status !== 200)
      throw new Error("Some error happend");

    const blob = await response.blob();

    if (response !== null && response.headers !== null) {
      const header_with_name = response?.headers?.get("Content-Disposition");
      if (header_with_name !== null) {
        const name = header_with_name.split("=").pop();

        if (name) {
          filename = name.substring(1, name.length - 1);
        }
      }
    }

    FileSaver.saveAs(blob, filename);
  }
);

const StatisticsSlice = createSlice({
  name: "statistics",
  initialState,
  reducers: {
    geoByUserFilter(state, action) {
      const arr = state.listUsers.find((item) => item.name === action.payload);
      if (arr?.geozone) state.geoByUser = arr?.geozone;
    },
    SelectedRisnRef(state, action) {
      let str = action.payload;
      const filtred = state.listComposits.filter(
        (item) => item.composite_type === str
      );
      let arr: any = filtred.map((item) => item.resin_name);
      let unique: any = Array.from(new Set(arr));
      state.listRisnRef = unique;
    },
    SelectedTapeRef(state, action) {
      let str = action.payload;
      const filtred = state.listComposits.filter(
        (item) => item.tape_type === str
      );
      let arr: any = filtred.map((item) => item.composite_type);
      let unique: any = Array.from(new Set(arr));
      state.listProduct = unique;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGeoZonesStatistic.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getGeoZonesStatistic.fulfilled, (state, action) => {
        state.geoZones = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getGeoZonesStatistic.rejected, (state) => {
        state.loading = false;
        state.error = "Request get geozones statistic is fail!";
      })
      .addCase(getStatuses.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getStatuses.fulfilled, (state, action) => {
        state.statuses = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getStatuses.rejected, (state) => {
        state.loading = false;
        state.error = "Request get statuses is fail!";
      })
      .addCase(getUsersStatistic.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUsersStatistic.fulfilled, (state, action) => {
        state.listUsersStatistic = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getUsersStatistic.rejected, (state) => {
        state.loading = false;
        state.error = "Request get list users is fail!";
      })
      .addCase(getLocation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getLocation.fulfilled, (state, action) => {
        state.locations = action.payload;
        state.error = null;
      })
      .addCase(getLocation.rejected, (state) => {
        state.loading = false;
        state.error = "Request get location is fail!";
      })
      .addCase(getUsersConfirmed.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUsersConfirmed.fulfilled, (state, action) => {
        state.listUsers = action.payload;

        state.loading = false;
        state.error = null;
      })
      .addCase(getUsersConfirmed.rejected, (state) => {
        state.loading = false;
        state.error = "Request get users confirmed is fail!";
      })
      .addCase(getAllProjects.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllProjects.fulfilled, (state, action) => {
        state.allProjects = action.payload;
        state.loading = false;
      })
      .addCase(fetchDistributors.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDistributors.fulfilled, (state, action) => {
        state.loading = false;
        state.allDistributors = action.payload;
      })
      .addCase(getFiltredUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFiltredUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.numberOfConnectedUsers = action.payload.number_of_connected_users;
        state.numberOfUsers = action.payload.number_of_users;
        state.persentOfConnectedUsers = action.payload.percent_connected_users;
      })
      .addCase(getFiltredUsers.rejected, (state) => {
        state.loading = false;
        state.error = "Request get filtred users confirmed is fail!";
      })
      .addCase(getScoringOfUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(getScoringOfUser.fulfilled, (state, action) => {
        state.loading = false;

        state.totalCreatedProjectsByUser =
          action.payload.activeness_num_of_created_project;

        state.ActiveTotalGenereted =
          action.payload.activeness_num_of_generated_technical_proposal;

        state.totalClosedProject =
          action.payload.productivity_num_of_closed_technical_proposal;

        state.ProductivityTotalGenereted =
          action.payload.productivity_num_of_generated_technical_proposal;

        state.ProductivitySentTechProp =
          action.payload.productivity_num_of_sent_technical_proposal;

        state.totalValidated =
          action.payload.productivity_num_of_validated_technical_proposal;
      })
      .addCase(getScoringOfUser.rejected, (state) => {
        state.loading = false;
        state.error = "Request get filtred ScrolingOfUusers confirmed is fail!";
      })
      /////////////////////////////
      .addCase(getCorelationData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCorelationData.fulfilled, (state, action) => {
        state.loading = false;

        state.sentTechProposal = action.payload.sent_tech_proposal;
        state.acceptedTechProposal = action.payload.accepted_tech_proposal;
        state.totalSentTechProposal = action.payload.sent_tech_proposal_size;
        state.totalAcceptedTechProposal =
          action.payload.accepted_tech_proposal_size;
      })
      .addCase(getCorelationData.rejected, (state) => {
        state.loading = false;
        state.error = "Request get filtred users confirmed is fail!";
      })

      ///////////////////////////

      .addCase(getDataFiltredValues.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDataFiltredValues.fulfilled, (state, action) => {
        state.loading = false;

        state.totalCreatedProjects = action.payload.number_of_created_projects;
        state.totalRepairCases = action.payload.number_of_repair_cases;
        state.totalValidatedProposal =
          action.payload.number_of_validated_tech_proposal;
        state.totalRepairLength = action.payload.total_repair_length;
        state.totalUsedLength = action.payload.total_used_tape_length;
        state.totalNumberOfPatches = action.payload.total_number_of_patches;
        state.totalNumberQuantity =
          action.payload.total_number_quantity_of_resin;
        state.totalQuantityFillers = action.payload.total_quantity_of_fillers;

        state.totalQuantityPrimers = action.payload.total_quantity_of_primers;
      })
      .addCase(getDataFiltredValues.rejected, (state) => {
        state.loading = false;
        state.error = "Request get filtred users confirmed is fail!";
      })
      .addCase(getComposits.pending, (state) => {
        state.loading = true;
      })
      .addCase(getComposits.fulfilled, (state, action) => {
        state.loading = false;
        state.listComposits = action.payload;
        let name: any = state.listComposits.map((item) => item.composite_type);
        let tape: any = state.listComposits.map((item) => item.tape_type);
        let risin: any = state.listComposits.map((item) => item.resin_name);
        let TapeRef: any = Array.from(new Set(tape));
        let Product: any = Array.from(new Set(name));
        let ResinRef: any = Array.from(new Set(risin));
        state.listTapeRef = TapeRef;
        state.listProduct = Product;
        state.listRisnRef = ResinRef;

        // state.listRisnRef = Array.from(new Set(risin))
      })
      .addCase(getComposits.rejected, (state) => {
        state.loading = false;
        state.error = "Request get filtred compositts confirmed is fail!";
      })
      .addCase(getFillers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getFillers.fulfilled, (state, action) => {
        state.listFillers = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getFillers.rejected, (state) => {
        state.loading = false;
        state.error = "Request get fillers is fail!";
      })
      .addCase(getPrimers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPrimers.fulfilled, (state, action) => {
        state.listPrimers = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getPrimers.rejected, (state) => {
        state.loading = false;
        state.error = "Request get primers is fail!";
      })
      .addCase(getMarketingStatistic.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.marketingStatisticDetails = null;
      })
      .addCase(getMarketingStatistic.fulfilled, (state, action) => {
        state.marketingStatistic = action.payload;
        state.loading = false;
      })
      .addCase(getMarketingStatistic.rejected, (state) => {
        state.loading = false;
        state.error = "Request get data marketing statistic is fail!";
      })
      .addCase(getMarketingStatisticDetails.pending, (state) => {
        state.marketingStatisticDetails = null;
        state.loading = true;
        state.error = null;
      })
      .addCase(getMarketingStatisticDetails.fulfilled, (state, action) => {
        state.marketingStatisticDetails = action.payload;
        state.loading = false;
      })
      .addCase(getMarketingStatisticDetails.rejected, (state) => {
        state.loading = false;
        state.error = "Request get data marketing statistic details is fail!";
      })
      .addCase(getTechnicalStatisticDescription.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTechnicalStatisticDescription.fulfilled, (state, action) => {
        state.technicalStatisticDescriptions = action.payload;
        state.loading = false;
      })
      .addCase(getTechnicalStatisticDescription.rejected, (state) => {
        state.loading = false;
        state.error =
          "Request get data technical statistic description is fail!";
      })
      .addCase(getTechnicalStatistic.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTechnicalStatistic.fulfilled, (state, action) => {
        state.technicalStatistic = action.payload;
        state.loading = false;
      })
      .addCase(getTechnicalStatistic.rejected, (state) => {
        state.loading = false;
        state.error = "Request get data technical statistic  is fail!";
      })
      .addCase(getTechnicalStatisticReport.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTechnicalStatisticReport.fulfilled, (state) => {
        state.error = null;
        state.loading = false;
      })
      .addCase(getTechnicalStatisticReport.rejected, (state) => {
        state.loading = false;
        state.error = "Request get statistic technical report is fail!";
      })
      .addCase(getMarketingStatisticReport.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getMarketingStatisticReport.fulfilled, (state) => {
        state.error = null;
        state.loading = false;
      })
      .addCase(getMarketingStatisticReport.rejected, (state) => {
        state.loading = false;
        state.error = "Request get statistic marketing report is fail!";
      });
  },
});
export type { Filters, Primer, Composit, IDatePickers };
export const { SelectedTapeRef, SelectedRisnRef, geoByUserFilter } =
  StatisticsSlice.actions;
export default StatisticsSlice.reducer;
