import { ProductsManagement } from "features/products-management";

const Composit = () => {
  return (
    <div>
      <ProductsManagement type="composit" />
    </div>
  );
};

export default Composit;
