import HistoryChanges from "features/products-management/components/history-changes";

const History = () => {
  return (
    <div>
      <HistoryChanges />
    </div>
  );
};

export default History;
