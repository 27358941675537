import { useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";

import { useAppDispatch, useAppSelector } from "app/hook";
import { TypesButton } from "types";
import {
  BootstrapDialog,
  BootstrapDialogTitle,
  Button,
} from "components/library";

import {
  cleanError,
  Grade,
  contentCreateEditGrade,
  resetCreatedContent,
} from "features/content-management/content-management-slice";

import "./modal-add-grade.scss";

type ModalAddGradeErrors = {
  grade?: string;
};

type ModalAddGradeProps = {
  show: boolean;
  handleClose: Function;
  grade: Grade | null;
};

const ModalAddGrade = ({ show, handleClose, grade }: ModalAddGradeProps) => {
  const { error, isCreatedContent, isEditedContent } = useAppSelector(
    (state) => state.contentManagement
  );

  const [form, setForm] = useState(
    grade === null
      ? {
          id: null,
          grade: "",
          smys: 0,
          mby: 0,
        }
      : {
          id: grade.id,
          grade: grade.grade,
          smys: grade.smys,
          mby: grade.mby,
        }
  );

  const [errors, setErrors] = useState<ModalAddGradeErrors>({});

  const dispatch = useAppDispatch();

  const setField = (field: string, value: number | string | boolean) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!!errors[field as keyof ModalAddGradeErrors]) {
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };

  const validateForm = () => {
    const { grade } = form;
    const newErrors: ModalAddGradeErrors = {};

    if (!grade || grade === "") newErrors.grade = "Please enter name grade";

    return newErrors;
  };

  const handleCloseModal = () => {
    handleClose(false);
    dispatch(cleanError());
    dispatch(resetCreatedContent());
  };

  const handleSave = () => {
    const formErrors = validateForm();

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      dispatch(contentCreateEditGrade({ ...form }));
      setErrors({});
    }
  };

  return (
    <BootstrapDialog
      onClose={() => handleCloseModal()}
      aria-labelledby="customized-add-grade"
      open={show}
      className="modal-add-grade"
    >
      <BootstrapDialogTitle
        id="customized-add-grade"
        onClose={() => handleCloseModal()}
      >
        {grade === null ? "Add a new grade" : "Edit grade"}
      </BootstrapDialogTitle>
      <DialogContent className="modal-add-grade_body">
        <form className="modal-add-grade_body_form" autoComplete="off">
          <FormControlLabel
            className="modal-add-grade_body_form_control-label"
            control={
              <TextField
                autoFocus
                id="grade"
                value={form.grade}
                onChange={(e) => {
                  setField("grade", e.target.value);
                }}
                required
                error={errors.grade ? true : false}
                helperText={errors.grade}
                sx={{
                  marginTop: "0px",
                  marginBottom: "0px",
                }}
              />
            }
            label={"Grade"}
            labelPlacement="start"
            value="grade"
          />

          <FormControlLabel
            className="modal-add-grade_body_form_control-label"
            control={
              <TextField
                type="number"
                // InputProps={{
                //   inputProps: {
                //     max: 100,
                //     min: 0,
                //   },
                // }}
                // error={errors.smys ? true : false}
                id="smys"
                value={form.smys}
                onChange={(e) => {
                  setField("smys", parseFloat(e.target.value));
                }}
                required
                // helperText={errors.smys}
                sx={{
                  marginTop: "0px",
                  marginBottom: "0px",
                }}
              />
            }
            label={"Specific Minimum Yield Stress (SMYS)"}
            labelPlacement="start"
            value="smys"
          />

          <FormControlLabel
            className="modal-add-grade_body_form_control-label"
            control={
              <TextField
                type="number"
                // InputProps={{
                //   inputProps: {
                //     max: 1000,
                //     min: 0,
                //   },
                // }}
                // error={errors.mby ? true : false}
                id="mby"
                value={form.mby}
                onChange={(e) => {
                  setField("mby", parseFloat(e.target.value));
                }}
                required
                // helperText={errors.mby}
                sx={{
                  marginTop: "0px",
                  marginBottom: "0px",
                }}
              />
            }
            label={"Elastic Modulus Before Yield (MBY)"}
            labelPlacement="start"
            value="mby"
          />

          <div className="modal-add-grade_body_form_wrap-btns">
            <Button
              type={TypesButton["modal-outline"]}
              handleClick={() => handleCloseModal()}
              text="Cancel"
            />
            <Button
              type={TypesButton.modal}
              text="Save"
              handleClick={() => handleSave()}
            />
          </div>
        </form>
        {isCreatedContent && (
          <div className="modal-add-grade_body_form_success-text">
            Grade successfully created
          </div>
        )}
        {isEditedContent && grade !== null && (
          <div className="modal-add-grade_body_form_success-text">
            Grade successfully edited
          </div>
        )}
        {error !== null && (
          <div className="modal-add-grade_body_form_error-text">{error}</div>
        )}
      </DialogContent>
    </BootstrapDialog>
  );
};

export default ModalAddGrade;
