import { useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";

import { useAppDispatch, useAppSelector } from "app/hook";
import { TypesButton } from "types";
import {
  BootstrapDialog,
  BootstrapDialogTitle,
  Button,
} from "components/library";

import {
  cleanError,
  Reason,
  contentCreateEditReason,
  resetCreatedContent,
} from "features/content-management/content-management-slice";

import "./modal-add-reason.scss";

type ModalAddReasonErrors = {
  name?: string;
};

type ModalAddReasonProps = {
  show: boolean;
  handleClose: Function;
  reason: Reason | null;
};

const ModalAddReason = ({ show, handleClose, reason }: ModalAddReasonProps) => {
  const { error, isCreatedContent, isEditedContent } = useAppSelector(
    (state) => state.contentManagement
  );

  const [form, setForm] = useState(
    reason === null
      ? {
          id: null,
          name: "",
        }
      : {
          id: reason.id,
          name: reason.name,
        }
  );

  const [errors, setErrors] = useState<ModalAddReasonErrors>({});

  const dispatch = useAppDispatch();

  const setField = (field: string, value: string) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!!errors[field as keyof ModalAddReasonErrors]) {
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };

  const validateForm = () => {
    const { name } = form;
    const newErrors: ModalAddReasonErrors = {};

    if (!name || name === "") newErrors.name = "Please enter name reason";

    return newErrors;
  };

  const handleCloseModal = () => {
    handleClose(false);
    dispatch(cleanError());
    dispatch(resetCreatedContent());
  };

  const handleSave = () => {
    const formErrors = validateForm();

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      dispatch(contentCreateEditReason({ ...form }));
      setErrors({});
    }
  };

  return (
    <BootstrapDialog
      onClose={() => handleCloseModal()}
      aria-labelledby="customized-add-reason"
      open={show}
      className="modal-add-reason"
    >
      <BootstrapDialogTitle
        id="customized-add-reason"
        onClose={() => handleCloseModal()}
      >
        {reason === null ? "Add a new reason" : "Edit reason"}
      </BootstrapDialogTitle>
      <DialogContent className="modal-add-reason_body">
        <form className="modal-add-reason_body_form" autoComplete="off">
          <FormControlLabel
            className="modal-add-reason_body_form_control-label"
            control={
              <TextField
                autoFocus
                error={errors.name ? true : false}
                id="name"
                value={form.name}
                onChange={(e) => {
                  setField("name", e.target.value);
                }}
                required
                helperText={errors.name}
                sx={{
                  marginTop: "0px",
                  marginBottom: "0px",
                }}
              />
            }
            label={"Name reason"}
            labelPlacement="start"
            value="name"
          />

          <div className="modal-add-reason_body_form_wrap-btns">
            <Button
              type={TypesButton["modal-outline"]}
              handleClick={() => handleCloseModal()}
              text="Cancel"
            />
            <Button
              type={TypesButton.modal}
              text="Save"
              handleClick={() => handleSave()}
            />
          </div>
        </form>
        {isCreatedContent && (
          <div className="modal-add-reason_body_form_success-text">
            Reason successfully created
          </div>
        )}
        {isEditedContent && reason !== null && (
          <div className="modal-add-reason_body_form_success-text">
            Reason successfully edited
          </div>
        )}
        {error !== null && (
          <div className="modal-add-reason_body_form_error-text">{error}</div>
        )}
      </DialogContent>
    </BootstrapDialog>
  );
};

export default ModalAddReason;
