import { useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";

import { useAppDispatch, useAppSelector } from "app/hook";
import { TypesButton } from "types";
import {
  BootstrapDialog,
  BootstrapDialogTitle,
  Button,
} from "components/library";

import {
  cleanError,
  Bend,
  contentCreateEditBend,
  resetCreatedContent,
} from "features/content-management/content-management-slice";

import "./modal-add-bend.scss";

type ModalAddBendErrors = {
  bend?: string;
};

type ModalAddBendProps = {
  show: boolean;
  handleClose: Function;
  bend: Bend | null;
};

const ModalAddBend = ({ show, handleClose, bend }: ModalAddBendProps) => {
  const { error, isCreatedContent, isEditedContent } = useAppSelector(
    (state) => state.contentManagement
  );

  const [form, setForm] = useState(
    bend === null
      ? {
          id: null,
          pipe_diameter: 0,
          bend_diameter: 0,
        }
      : {
          id: bend.id,
          pipe_diameter: bend.pipe_diameter,
          bend_diameter: bend.bend_diameter,
        }
  );

  const [errors, setErrors] = useState<ModalAddBendErrors>({});

  const dispatch = useAppDispatch();

  const setField = (field: string, value: number | string | boolean) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!!errors[field as keyof ModalAddBendErrors]) {
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };

  const validateForm = () => {
    // const { grade } = form;
    const newErrors: ModalAddBendErrors = {};

    // if (!grade || grade === "") newErrors.grade = "Please enter name grade";

    return newErrors;
  };

  const handleCloseModal = () => {
    handleClose(false);
    dispatch(cleanError());
    dispatch(resetCreatedContent());
  };

  const handleSave = () => {
    const formErrors = validateForm();

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      dispatch(contentCreateEditBend({ ...form }));
      setErrors({});
    }
  };

  return (
    <BootstrapDialog
      onClose={() => handleCloseModal()}
      aria-labelledby="customized-add-bend"
      open={show}
      className="modal-add-bend"
    >
      <BootstrapDialogTitle
        id="customized-add-bend"
        onClose={() => handleCloseModal()}
      >
        {bend === null ? "Add a new bend" : "Edit bend"}
      </BootstrapDialogTitle>
      <DialogContent className="modal-add-bend_body">
        <form className="modal-add-bend_body_form" autoComplete="off">
          <FormControlLabel
            className="modal-add-bend_body_form_control-label"
            control={
              <TextField
                autoFocus
                type="number"
                // InputProps={{
                //   inputProps: {
                //     max: 100,
                //     min: 0,
                //   },
                // }}
                // error={errors.smys ? true : false}
                id="pipe_diameter"
                value={form.pipe_diameter}
                onChange={(e) => {
                  setField("pipe_diameter", parseFloat(e.target.value));
                }}
                required
                // helperText={errors.pipe_diameter}
                sx={{
                  marginTop: "0px",
                  marginBottom: "0px",
                }}
              />
            }
            label={"Pipe diameter inch"}
            labelPlacement="start"
            value="pipe_diameter"
          />

          <FormControlLabel
            className="modal-add-bend_body_form_control-label"
            control={
              <TextField
                type="number"
                // InputProps={{
                //   inputProps: {
                //     max: 1000,
                //     min: 0,
                //   },
                // }}
                // error={errors.mby ? true : false}
                id="bend_diameter"
                value={form.bend_diameter}
                onChange={(e) => {
                  setField("bend_diameter", parseFloat(e.target.value));
                }}
                required
                // helperText={errors.bend_diameter}
                sx={{
                  marginTop: "0px",
                  marginBottom: "0px",
                }}
              />
            }
            label={"Bend diameter inch"}
            labelPlacement="start"
            value="bend_diameter"
          />

          <div className="modal-add-bend_body_form_wrap-btns">
            <Button
              type={TypesButton["modal-outline"]}
              handleClick={() => handleCloseModal()}
              text="Cancel"
            />
            <Button
              type={TypesButton.modal}
              text="Save"
              handleClick={() => handleSave()}
            />
          </div>
        </form>
        {isCreatedContent && (
          <div className="modal-add-bend_body_form_success-text">
            Bend dimension successfully created
          </div>
        )}
        {isEditedContent && bend !== null && (
          <div className="modal-add-bend_body_form_success-text">
            Bend dimension successfully edited
          </div>
        )}
        {error !== null && (
          <div className="modal-add-bend_body_form_error-text">{error}</div>
        )}
      </DialogContent>
    </BootstrapDialog>
  );
};

export default ModalAddBend;
