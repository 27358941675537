import { useState } from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";

import Avatar from "assets/images/avatar.svg";
import Logo from "assets/images/logo.png";
import Notification from "assets/images/notification.svg";
import HelpCircle from "assets/images/help-circle.svg";

import ModalInfoUser from "./components/modal-info-user/modal-info-user";

import "./header.scss";

const Header = () => {
  const [showInfo, setShowInfo] = useState(false);

  return (
    <header className="header">
      <div className="container">
        <Link to="/">
          <img src={Logo} alt="logo" />
        </Link>
        <div>
          <span>Admin</span>
          <span>Calculation Engine</span>
        </div>
        <div className="btn-wrapp">
          <Button onClick={() => {}}>
            <img className="" src={Notification} alt="notification" />
          </Button>
          <Button onClick={() => {}}>
            <img className="" src={HelpCircle} alt="help circle" />
          </Button>
          <Button onClick={() => setShowInfo(true)}>
            <img className="avatar-user" src={Avatar} alt="avatar user" />
          </Button>
        </div>
      </div>

      {showInfo && <ModalInfoUser handleClose={setShowInfo} />}
    </header>
  );
};

export default Header;
