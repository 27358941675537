import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";

import { Button } from "components/library";
import { TypesButton } from "types";

import { GeneralInformation, MissingInformation } from "./components";

import "./conditions.scss";

type ConditionsProps = {
  handleBack: Function;
};

export default function Conditions({ handleBack }: ConditionsProps) {
  return (
    <div className="conditions">
      <div className="conditions_title">
        <div>Conditions</div>
        <Button
          type={TypesButton.primary}
          text="Back to Content Management"
          handleClick={() => {
            handleBack(null);
          }}
          size={250}
        />
      </div>
      <div className="conditions_body">
        <Accordion className="conditions_body_accordion">
          <AccordionSummary
            expandIcon={
              <ArrowForwardIosSharpIcon
                sx={{ fontSize: "48px", padding: "12px" }}
              />
            }
            aria-controls="missing-content"
            id="missing-header"
            className="conditions_body_accordion_summary"
          >
            <Typography className="conditions_body_accordion_summary_title">
              Missing information
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <MissingInformation />
          </AccordionDetails>
        </Accordion>
        <Accordion className="conditions_body_accordion">
          <AccordionSummary
            expandIcon={
              <ArrowForwardIosSharpIcon
                sx={{ fontSize: "48px", padding: "12px" }}
              />
            }
            aria-controls="general-content"
            id="general-header"
            className="conditions_body_accordion_summary"
          >
            <Typography className="conditions_body_accordion_summary_title">
              General info
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <GeneralInformation />
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}
