import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import FileSaver from "file-saver";

import { getFetchProp } from "helpers/getFetchProp";

import { LoginUserState } from "features/auth/auth-slice";

import { apiUrl } from "../../constants";

export type Content = {
  client: string;
  date: string;
  distributor_id: number;
  document_technical_proposal: string;
  engineer_manager: string;
  id: number;
  offer_number: string;
  project: string;
  repair_reference: string;
  sale_manager_id: number;
  status: string;
  status_description: string;
};

export type ProjectData = {
  content: Array<Content>;
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  pageable: {
    offset: number;
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    sort: {
      empty: boolean;
      sorted: boolean;
      unsorted: boolean;
    };
    unpaged: boolean;
  };
  size: number;
  sort: {
    empty: boolean;
    sorted: boolean;
    unsorted: boolean;
  };
  totalElements: number;
  totalPages: number;
};

type AllDistributors = {
  user_id: number;
  name: string;
  continent: string;
  location: string;
  role: string;
};

export type ProposalTableData = {
  date: string;
  id: number;
  repair_reference: string;
  status: Array<string>;
  status_description: string;
  tech_proposal_name: string;
  offer_number?: string;
};

export type ProposalsAndProjectState = {
  error: null | string;
  loading: boolean;
  allProjects: ProjectData;
  allDistributors: Array<AllDistributors>;
  proposalsData: Array<ProposalTableData>;
};

const initialState: ProposalsAndProjectState = {
  error: null,
  loading: false,
  allProjects: {
    content: [],
    pageable: {
      sort: {
        sorted: false,
        unsorted: true,
        empty: true,
      },
      pageNumber: 0,
      pageSize: 10,
      offset: 0,
      paged: true,
      unpaged: false,
    },
    last: false,
    totalElements: 30,
    totalPages: 3,
    sort: {
      sorted: false,
      unsorted: true,
      empty: true,
    },
    first: true,
    numberOfElements: 10,
    size: 10,
    number: 0,
    empty: false,
  },
  allDistributors: [],
  proposalsData: [],
};

export const getAllProjects = createAsyncThunk(
  "get_all_projects",
  async (_, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };
    const res = await fetch(
      `${apiUrl}/rea/v1.0/api/project/all_projects?page=0&size=1000`,
      {
        ...getFetchProp({
          method: "GET",
          contentType: "application/json",
          token: loginUser.token,
        }),
      }
    );

    return res.json();
  }
);

export const fetchDistributors = createAsyncThunk(
  "fetch_distributors",
  async (_, { rejectWithValue, dispatch, getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };

    const res = await fetch(`${apiUrl}/rea/v1.0/api/user/distributor`, {
      ...getFetchProp({
        token: loginUser.token,
      }),
    });

    return res.json();
  }
);

export const fetchProposalsByRootId = createAsyncThunk(
  "fetch_proposal_by_root_id",
  async (id: number, { rejectWithValue, dispatch, getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };

    const res = await fetch(
      `${apiUrl}/rea/v1.0/api/tech_proposal/project?id=${id}`,
      {
        ...getFetchProp({
          token: loginUser.token,
        }),
      }
    );

    return res.json();
  }
);

export const fetchDownloadReportsByTechProposal = createAsyncThunk(
  "downloadReportsByTechProposal",
  async (proposalId: number, { getState }) => {
    const { loginUser } = getState() as { loginUser: LoginUserState };

    let filename = "report.zip";

    const response = await fetch(
      `${apiUrl}/rea/v1.0/api/report/download_for_tech_proposal?tech_proposal_id=${proposalId}`,
      {
        ...getFetchProp({
          method: "GET",
          token: loginUser.token,
        }),
      }
    );

    if (!response.ok && response.status !== 200)
      throw new Error("Some error happend");

    const blob = await response.blob();

    if (response !== null && response.headers !== null) {
      const header_with_name = response?.headers?.get("Content-Disposition");
      if (header_with_name !== null) {
        const name = header_with_name.split("=").pop();

        if (name) {
          filename = name.substring(1, name.length - 1);
        }
      }
    }

    FileSaver.saveAs(blob, filename);
  }
);

const proposalsAndProjectSlice = createSlice({
  name: "proposals and projects",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllProjects.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllProjects.fulfilled, (state, action) => {
        state.allProjects = action.payload;
        state.loading = false;
      })
      .addCase(fetchDistributors.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDistributors.fulfilled, (state, action) => {
        state.loading = false;
        state.allDistributors = action.payload;
      })
      .addCase(fetchProposalsByRootId.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProposalsByRootId.fulfilled, (state, action) => {
        state.loading = false;
        state.proposalsData = action.payload;
      })
      .addCase(fetchDownloadReportsByTechProposal.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDownloadReportsByTechProposal.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchDownloadReportsByTechProposal.rejected, (state) => {
        state.loading = false;
        state.error = "error";
      });
  },
});

export default proposalsAndProjectSlice.reducer;
