import GeneralStatistic from "./general";
import TechnicalStatistic from "./technical";
import MarketingStatistic from "./marketing";

type StatisticsProps = {
  type: string;
};

const Statistics: React.FC<StatisticsProps> = ({ type }) => {
  return (
    <>
      {type === "general" && <GeneralStatistic />}
      {type === "technical" && <TechnicalStatistic />}
      {type === "marketing" && <MarketingStatistic />}
    </>
  );
};

export default Statistics;
