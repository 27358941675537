import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import Avatar from "assets/images/avatar.svg";

import { useAppDispatch, useAppSelector } from "app/hook";
import { authLogout } from "features/auth/auth-slice";

import { BootstrapDialog, BootstrapDialogTitle } from "components/library";

import "./modal-info-user.scss";

type ModalInfoUserProps = {
  handleClose: Function;
};

const ModalInfoUser = ({ handleClose }: ModalInfoUserProps) => {
  const loginUser = useAppSelector((state) => state.loginUser);
  const { name, email, continent, phone, location, saleManagerName } =
    loginUser;

  const dispatch = useAppDispatch();

  const handleExit = () => {
    handleClose(false);
    dispatch(authLogout());
  };

  return (
    <BootstrapDialog
      onClose={() => handleClose(false)}
      aria-labelledby="customized-dialog-title"
      open={true}
      className="modal-info-user"
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={() => handleClose(false)}
      />

      <DialogContent className="modal-info-user_body">
        <div>
          <img src={Avatar} width="87px" alt="avatar user" />
        </div>
        <div className="modal-info-user_body_fio">{name}</div>
        <div className="modal-info-user_body_email">{email}</div>
        <div className="modal-info-user_body_subtitle">{continent}</div>
        <div className="modal-info-user_body_subtitle">{location}</div>
        <div className="modal-info-user_body_subtitle">{phone}</div>

        {saleManagerName !== null && saleManagerName !== undefined && (
          <div className="modal-info-user_body_wrap-manager">
            <div className="modal-info-user_body_wrap-manager_fio-manager">
              Adam Drampe
            </div>
            <div className="modal-info-user_body_wrap-manager_position">
              (sales manager)
            </div>
          </div>
        )}
      </DialogContent>
      <DialogActions className="modal-info-user_footer">
        <Button
          autoFocus
          variant="outlined"
          color="inherit"
          onClick={() => handleExit()}
        >
          Logout
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

export default ModalInfoUser;
