import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Checkbox from "@mui/material/Checkbox";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";

import { useAppDispatch, useAppSelector } from "app/hook";

import { getHistoryChanges } from "../products-management-slice";

import "./history-changes.scss";

const HistoryChanges = () => {
  const { listHistoryChanges, loading } = useAppSelector(
    (state) => state.productsManagement
  );

  const { email, userId } = useAppSelector((state) => state.loginUser);

  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [activeButton, setActiveButton] = useState("composits");

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      userId !== "" &&
      email !== "" &&
      !loading &&
      listHistoryChanges === null
    ) {
      dispatch(getHistoryChanges(activeButton));
    }
  }, [dispatch, email, userId, listHistoryChanges, activeButton, loading]);

  const columnsHistoryChanges: GridColDef[] = [
    // {
    //   field: "ACTION",
    //   sortable: false,
    //   width: 130,
    //   disableColumnMenu: true,
    //   renderCell: (params: any) => {
    //     return (
    //       <>
    //         <IconButton
    //           className="action-btn hover-btn"
    //           onClick={() => {
    //             setSelectedFiller(params.row);
    //             setShowModalAddProduct(true);
    //           }}
    //         >
    //           <EditSvg />
    //           <EditSvg isHover />
    //         </IconButton>
    //         <IconButton
    //           className="action-btn hover-btn"
    //           onClick={() => {
    //             setSelectedHistory({
    //               type: "filler",
    //               id: params.row.id,
    //               name: params.row.name,
    //             });
    //             setShowModalHistory(true);
    //           }}
    //         >
    //           <HistorySvg />
    //           <HistorySvg isHover />
    //         </IconButton>
    //         <IconButton className="action-btn hover-btn" onClick={() => {}}>
    //           <CopySvg />
    //           <CopySvg isHover />
    //         </IconButton>
    //         <IconButton
    //           className="action-btn hover-btn"
    //           onClick={() => {
    //             setSelectedFiller(params.row);
    //             setShowModalConfirm(true);
    //           }}
    //         >
    //           <DeleteSvg />
    //           <DeleteSvg isHover />
    //         </IconButton>
    //       </>
    //     );
    //   },
    // },

    {
      field: "N",
      headerName: "№",
      width: 75,
      renderCell: (params: any) => {
        return (
          <div>
            {params.api.getRowIndex(params.row.id) + 1}
            <Checkbox />
          </div>
        );
      },
    },
    { field: "user_name", headerName: "user_name", width: 200 },
    { field: "field_name", headerName: "field_name", width: 200 },
    { field: "from", headerName: "from", width: 150 },
    { field: "to", headerName: "to", width: 150 },
    {
      field: "updated_at",
      headerName: "updated_at",
      width: 200,
      renderCell: (params: any) => {
        return (
          <div>{params.row.updated_at.substring(0, 19).replace("T", " ")}</div>
        );
      },
    },
    { field: "user_email", headerName: "user_email", width: 250 },
  ];

  const getRows = () => {
    return listHistoryChanges !== null ? listHistoryChanges : [];
  };

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newActiveButton: string
  ) => {
    setActiveButton(newActiveButton);
    dispatch(getHistoryChanges(newActiveButton));
  };

  return (
    <div className="products-management">
      <div className="products-management_head">
        <div>Products</div>
        <div className="products-management_buttons">
          <ToggleButtonGroup
            className="history_btn-group"
            color="primary"
            value={activeButton}
            exclusive
            onChange={handleChange}
          >
            <ToggleButton
              className={
                activeButton === "composits"
                  ? "history_btn-group_btn history_btn-group_btn-active"
                  : "history_btn-group_btn"
              }
              value="composits"
            >
              Composites
            </ToggleButton>
            <ToggleButton
              className={
                activeButton === "fillers"
                  ? "history_btn-group_btn history_btn-group_btn-active"
                  : "history_btn-group_btn"
              }
              value="fillers"
            >
              Fillers
            </ToggleButton>
            <ToggleButton
              className={
                activeButton === "primers"
                  ? "history_btn-group_btn history_btn-group_btn-active"
                  : "history_btn-group_btn"
              }
              value="primers"
            >
              Primers
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
      <Box>
        <div style={{ height: "65vh", width: "100%", marginTop: "40px" }}>
          <div className="products-management_subtitle">List of changes</div>
          <DataGrid
            rows={getRows()}
            columns={columnsHistoryChanges}
            rowsPerPageOptions={[10, 20, 50, 100]}
            loading={loading}
            components={{
              Toolbar: GridToolbar,
            }}
            onSelectionModelChange={(rowId) => {
              if (
                typeof rowId[0] === "number" &&
                selectedRows.includes(rowId[0])
              ) {
                setSelectedRows([
                  ...selectedRows.filter((item) => item !== rowId[0]),
                ]);
              }

              if (
                typeof rowId[0] === "number" &&
                !selectedRows.includes(rowId[0])
              ) {
                setSelectedRows([...selectedRows, rowId[0]]);
              }
            }}
          />
        </div>
      </Box>
    </div>
  );
};

export default HistoryChanges;
