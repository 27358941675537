import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";

import { useAppDispatch, useAppSelector } from "app/hook";
import { TypesButton } from "types";
import { ModalConfirm, Button, EditSvg, DeleteSvg } from "components/library";

import {
  Grade,
  getGrades,
  contentDeleteGrade,
} from "features/content-management/content-management-slice";

import ModalAddGrade from "./components/modal-add-grade";

// import "./grade-management.scss";

const GradeManagement = () => {
  const { listGrades, loading } = useAppSelector(
    (state) => state.contentManagement
  );

  const { email, userId } = useAppSelector((state) => state.loginUser);

  const [showModalAdd, setShowModalAdd] = useState(false);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [selectedGrade, setSelectedGrade] = useState<Grade | null>(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (userId !== "" && email !== "" && !loading && listGrades === null) {
      dispatch(getGrades());
    }
  }, [dispatch, email, userId, listGrades, loading]);

  const columnsGrade: GridColDef[] = [
    {
      field: "",
      // width: 150,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: any) => {
        return (
          <>
            <IconButton
              className="action-btn hover-btn"
              onClick={() => {
                setSelectedGrade(params.row);
                setShowModalAdd(true);
              }}
            >
              <EditSvg />
              <EditSvg isHover />
            </IconButton>
            <IconButton
              className="action-btn hover-btn"
              onClick={() => {
                setSelectedGrade(params.row);
                setShowModalConfirm(true);
              }}
            >
              <DeleteSvg />
              <DeleteSvg isHover />
            </IconButton>
          </>
        );
      },
    },

    {
      field: "N",
      headerName: "№",
      width: 200,
      renderCell: (params: any) => {
        return params.api.getRowIndex(params.row.id) + 1;
      },
    },
    { field: "grade", headerName: "Grade", width: 350 },
    { field: "smys", headerName: "Smys", width: 300 },
    { field: "mby", headerName: "Mby", width: 300 },
  ];

  return (
    <div className="grade-management">
      <div className="grade-management_head">
        <div>
          <Button
            type={TypesButton.primary}
            text="Add a new grade"
            handleClick={() => {
              setSelectedGrade(null);
              setShowModalAdd(true);
            }}
            size={170}
            icon={<AddIcon fontSize="small" />}
          />
        </div>
      </div>
      <Box>
        <div style={{ height: "65vh", width: "100%" }}>
          <DataGrid
            rows={listGrades || []}
            columns={columnsGrade}
            rowsPerPageOptions={[10, 20, 50, 100]}
            // checkboxSelection
            loading={loading}
            components={{
              Toolbar: GridToolbar,
            }}
            onRowDoubleClick={(selectedRow) => {
              setSelectedGrade(selectedRow.row);
              setShowModalAdd(true);
            }}
          />
        </div>
      </Box>
      {showModalAdd && (
        <ModalAddGrade
          show={showModalAdd}
          grade={selectedGrade}
          handleClose={setShowModalAdd}
        />
      )}
      {showModalConfirm && selectedGrade !== null && (
        <ModalConfirm
          show={showModalConfirm}
          textQuestion={`Are you sure that you want to delete grade -  ${selectedGrade?.id} ${selectedGrade?.grade}?`}
          handleCancel={() => setShowModalConfirm(false)}
          handleYes={() => {
            dispatch(contentDeleteGrade(selectedGrade?.id || -1));
            setShowModalConfirm(false);
          }}
        />
      )}
    </div>
  );
};

export default GradeManagement;
