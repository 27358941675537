import { FC } from "react";

import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import "./datePicker.scss";

interface ICustomDateProps {
  setDates: any;
}
const moment = require("moment");

const CustomDate: FC<ICustomDateProps> = ({ setDates }) => {
  const handleDateChange = (value: any) => {
    if (value) {
      setDates(value.map((date: any) => moment(date).format("DD.MM.YYYY")));
    }
  };

  return (
    <>
      <DateRangePicker
        style={{ width: "100%", height: 48 }}
        size="lg"
        showOneCalendar
        placeholder="DD.MM.YYYY"
        format="dd.MM.yyyy"
        onChange={handleDateChange}
      />
    </>
  );
};

export default CustomDate;
