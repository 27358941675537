import { configureStore } from "@reduxjs/toolkit";

import contentManagementReducer from "features/content-management/content-management-slice";
import loginUserReducer from "features/auth/auth-slice";
import productsManagementReducer from "features/products-management/products-management-slice";
import proposalsAndProjectsReducer from "features/tech-proposals-and-projects/proposals-and-projects-slice";
import statisticsSliceReducer from "features/statistics/statistics-slice";
import usersManagementReducer from "features/users-management/users-management-slice";

export const store = configureStore({
  reducer: {
    contentManagement: contentManagementReducer,
    loginUser: loginUserReducer,
    productsManagement: productsManagementReducer,
    proposalsAndProjects: proposalsAndProjectsReducer,
    statistics: statisticsSliceReducer,
    usersManagement: usersManagementReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
