import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { Box } from "@mui/material";

import { useAppDispatch, useAppSelector } from "app/hook";
import DownloadSvg from "components/library/svg/download";

import { fetchDownloadReportsByTechProposal } from "./proposals-and-projects-slice";

import "./proposals-and-projects.scss";

const TechProposalsList = () => {
  const { loading, proposalsData } = useAppSelector(
    (state) => state.proposalsAndProjects
  );
  const dispatch = useAppDispatch();

  const columnsProject: GridColDef[] = [
    {
      field: "actions",
      headerName: "Action",
      width: 80,
      sortable: false,
      renderCell: (params: any) => {
        if (params.row.is_report_download_avaliable)
          return (
            <div className="action-btn-wrapp">
              <button
                className="action-btn hover-btn"
                onClick={() =>
                  dispatch(fetchDownloadReportsByTechProposal(params.id))
                }
              >
                <DownloadSvg />
                <DownloadSvg isHover />
              </button>
            </div>
          );

        return <div />;
      },
    },
    {
      field: "number",
      headerName: "№",
      width: 70,
      sortable: false,
      renderCell: (params: any) => {
        return (
          <div className="number-wrapp">
            <span>{params.id}</span>
          </div>
        );
      },
    },
    {
      field: "tech_proposal_name",
      headerName: "Tech proposal name",
      width: 180,
    },
    {
      field: "technical_offer",
      headerName: "Technical offer",
      width: 150,
    },
    {
      field: "technical_offer_status",
      headerName: "Technical offer Status",
      width: 240,
      renderCell: (params: any) => {
        return (
          <div className={`project-status ${params.row.status}`}>
            {params.row.status_description}
          </div>
        );
      },
    },
    { field: "project", headerName: "Project Name", width: 150 },
    { field: "editor", headerName: "Editor", width: 130 },
    { field: "repair_reference", headerName: "Reparation number", width: 160 },
    { field: "date", headerName: "Creation Date", width: 120 },
  ];

  return (
    <div className="project-list">
      <div className="project-list_header">
        <span className="project-list_title">Proposals</span>
      </div>
      <Box>
        <div style={{ height: "75vh", width: "100%" }}>
          <DataGrid
            rows={proposalsData}
            columns={columnsProject}
            // pageSize={10}
            rowsPerPageOptions={[10, 20, 50, 100]}
            loading={loading}
            components={{
              Toolbar: GridToolbar,
            }}
          />
        </div>
      </Box>
    </div>
  );
};

export default TechProposalsList;
