import { useState } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";

import { TypesButton } from "types";
import { Button } from "components/library";

import { Conditions, PipeInformation, Reason } from "./components";

import "./content-management.scss";

const ContentManagement = () => {
  const [selectedContent, setSelectedContent] = useState<string | null>(null);

  if (selectedContent === "condition") {
    return <Conditions handleBack={setSelectedContent} />;
  }

  if (selectedContent === "pipe") {
    return <PipeInformation handleBack={setSelectedContent} />;
  }

  if (selectedContent === "reason") {
    return <Reason handleBack={setSelectedContent} />;
  }

  return (
    <div className="content-management">
      <div className="content-management_title">Сontent management</div>
      <div className="content-management_body">
        <Card className="content-management_body_card">
          <CardContent className="content-management_body_card_content">
            Pipe Information
          </CardContent>
          <CardActions className="content-management_body_card_action">
            <Button
              type={TypesButton.primary}
              text="Go to edit content"
              handleClick={() => {
                setSelectedContent("pipe");
              }}
              size={170}
            />
          </CardActions>
        </Card>
        <Card className="content-management_body_card">
          <CardContent className="content-management_body_card_content">
            Conditions
          </CardContent>
          <CardActions className="content-management_body_card_action">
            <Button
              type={TypesButton.primary}
              text="Go to edit content"
              handleClick={() => {
                setSelectedContent("condition");
              }}
              size={170}
            />
          </CardActions>
        </Card>
        <Card className="content-management_body_card">
          <CardContent className="content-management_body_card_content">
            Rejecred status reason
          </CardContent>
          <CardActions className="content-management_body_card_action">
            <Button
              type={TypesButton.primary}
              text="Go to edit content"
              handleClick={() => {
                setSelectedContent("reason");
              }}
              size={170}
            />
          </CardActions>
        </Card>
      </div>
    </div>
  );
};

export default ContentManagement;
