import { Dispatch, SetStateAction, useState } from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";

type SearchInputProps = {
  setSearchText: Dispatch<SetStateAction<string>>;
};

const SearchInput = ({ setSearchText }: SearchInputProps) => {
  const [value, setValue] = useState("");

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      setSearchText(event.target.value);
      event.preventDefault();
    }
  };

  const handleClick = () => {
    setSearchText(value);
  };

  return (
    <Paper
      sx={{
        p: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: "100%",
        border: "1px solid #767676",
        borderRadius: "5px",
        boxShadow: "none",
      }}
    >
      <IconButton
        type="button"
        sx={{ p: "10px" }}
        aria-label="search"
        onClick={handleClick}
      >
        <SearchIcon />
      </IconButton>
      <InputBase
        sx={{
          ml: 1,
          flex: 1,
          fontFamily: "Arial",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "18px",
          color: "#94A1A2",
        }}
        placeholder="Quick search"
        inputProps={{ "aria-label": "quick search" }}
        onKeyDown={handleKeyDown}
        onChange={(e) => setValue(e.target.value)}
      />
    </Paper>
  );
};

export default SearchInput;
