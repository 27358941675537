import { useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";

import { useAppDispatch, useAppSelector } from "app/hook";
import { TypesButton } from "types";
import {
  BootstrapDialog,
  BootstrapDialogTitle,
  Button,
} from "components/library";

import {
  cleanError,
  Primer,
  productCreateEditPrimer,
  resetCreatedProduct,
} from "../products-management-slice";

import "./modal-add-primer.scss";

type ModalAddPrimerErrors = {
  name?: string;
  max_temp?: string;
  min_temp?: string;
  max_temp_install?: string;
  min_temp_install?: string;
  consumption?: string;
  weight_bucket?: string;
};

type ModalAddPrimerProps = {
  show: boolean;
  handleClose: Function;
  primer: Primer | null;
  isCopy: boolean;
};

const ModalAddPrimer = ({
  show,
  handleClose,
  primer,
  isCopy,
}: ModalAddPrimerProps) => {
  const { error, isCreatedProduct, isEditedProduct } = useAppSelector(
    (state) => state.productsManagement
  );

  const [form, setForm] = useState(
    primer === null
      ? {
          id: null,
          name: "",
          max_temp: 0,
          min_temp: 0,
          max_temp_install: 0,
          min_temp_install: 0,
          status: true,
          display_for_distributor: 0,
          is_subsea_env: false,
          consumption: 0,
          weight_bucket: 0,
        }
      : {
          id: primer.id,
          name: primer.name,
          max_temp: primer.max_temp,
          min_temp: primer.min_temp,
          max_temp_install: primer.max_temp_install,
          min_temp_install: primer.min_temp_install,
          status: primer.status,
          display_for_distributor: primer.display_for_distributor,
          is_subsea_env: primer.is_subsea_env,
          consumption: primer.consumption,
          weight_bucket: primer.weight_bucket,
        }
  );
  const [errors, setErrors] = useState<ModalAddPrimerErrors>({});

  const dispatch = useAppDispatch();

  const setField = (field: string, value: string | boolean) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!!errors[field as keyof ModalAddPrimerErrors]) {
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };

  const validateForm = () => {
    const { name, max_temp, consumption } = form;
    const newErrors: ModalAddPrimerErrors = {};

    if (!name || name === "") newErrors.name = "Please enter name";
    if (!max_temp) newErrors.max_temp = "Please enter temperature Pipe Max";
    if (!consumption) newErrors.consumption = "Please enter consumption";

    return newErrors;
  };

  const handleCloseModal = () => {
    handleClose();
    dispatch(cleanError());
    dispatch(resetCreatedProduct());
  };

  const handleSave = () => {
    const formErrors = validateForm();

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      if (isCopy) {
        dispatch(productCreateEditPrimer({ ...form, id: null }));
      } else {
        dispatch(productCreateEditPrimer({ ...form }));
      }
      setErrors({});
    }
  };

  return (
    <BootstrapDialog
      onClose={() => handleCloseModal()}
      aria-labelledby="customized-add-primer"
      open={show}
      className="modal-add-primer"
    >
      <BootstrapDialogTitle
        id="customized-add-primer"
        onClose={() => handleCloseModal()}
      >
        {primer === null && "Add a new primer"}
        {primer !== null && (isCopy ? "Copy primer" : "Edit primer")}
      </BootstrapDialogTitle>
      <DialogContent className="modal-add-primer_body">
        <form className="modal-add-primer_body_form" autoComplete="off">
          <FormControlLabel
            className="modal-add-filler_body_form_control-label-switch"
            control={
              <Switch
                checked={form.status}
                onChange={(e) => {
                  setField("status", e.target.checked);
                }}
              />
            }
            label={"Product status"}
            labelPlacement="start"
            value="status"
          />

          <FormControlLabel
            className="modal-add-filler_body_form_control-label-switch"
            control={
              <Switch
                checked={!!form.display_for_distributor}
                onChange={(e) => {
                  setField("display_for_distributor", e.target.checked);
                }}
              />
            }
            label={"Display for distrib"}
            labelPlacement="start"
            value="display_for_distributor"
          />

          <FormControlLabel
            className="modal-add-filler_body_form_control-label-switch"
            control={
              <Switch
                checked={form.is_subsea_env}
                onChange={(e) => {
                  setField("is_subsea_env", e.target.checked);
                }}
              />
            }
            label={"Is subsea"}
            labelPlacement="start"
            value="is_subsea_env"
          />

          <FormControlLabel
            className="modal-add-primer_body_form_control-label"
            control={
              <TextField
                autoFocus
                id="name"
                value={form.name}
                onChange={(e) => {
                  setField("name", e.target.value);
                }}
                required
                error={errors.name ? true : false}
                helperText={errors.name}
                sx={{
                  marginTop: "0px",
                  marginBottom: "0px",
                }}
              />
            }
            label={"Name"}
            labelPlacement="start"
            value="name"
          />
          <FormControlLabel
            className="modal-add-primer_body_form_control-label"
            control={
              <TextField
                type="number"
                // InputProps={{
                //   inputProps: {
                //     max: 100,
                //     min: 0,
                //   },
                // }}
                error={errors.consumption ? true : false}
                id="density"
                value={form.consumption}
                onChange={(e) => {
                  setField("consumption", e.target.value);
                }}
                required
                helperText={errors.consumption}
                sx={{
                  marginTop: "0px",
                  marginBottom: "0px",
                }}
              />
            }
            label={"Consumption"}
            labelPlacement="start"
            value="consumption"
          />

          <FormControlLabel
            className="modal-add-primer_body_form_control-label"
            control={
              <TextField
                type="number"
                // InputProps={{
                //   inputProps: {
                //     max: 100,
                //     min: 0,
                //   },
                // }}
                error={errors.max_temp ? true : false}
                id="max_temp"
                value={form.max_temp}
                onChange={(e) => {
                  setField("max_temp", e.target.value);
                }}
                required
                helperText={errors.max_temp}
                sx={{
                  marginTop: "0px",
                  marginBottom: "0px",
                }}
              />
            }
            label={"Temperature pipe C max"}
            labelPlacement="start"
            value="max_temp"
          />
          <FormControlLabel
            className="modal-add-primer_body_form_control-label"
            control={
              <TextField
                type="number"
                // InputProps={{
                //   inputProps: {
                //     max: 100,
                //     min: 0,
                //   },
                // }}
                error={errors.min_temp ? true : false}
                id="min_temp"
                value={form.min_temp}
                onChange={(e) => {
                  setField("min_temp", e.target.value);
                }}
                required
                helperText={errors.min_temp}
                sx={{
                  marginTop: "0px",
                  marginBottom: "0px",
                }}
              />
            }
            label={"Temperature pipe C min"}
            labelPlacement="start"
            value="min_temp"
          />

          <FormControlLabel
            className="modal-add-primer_body_form_control-label"
            control={
              <TextField
                type="number"
                // InputProps={{
                //   inputProps: {
                //     max: 100,
                //     min: 0,
                //   },
                // }}
                error={errors.max_temp_install ? true : false}
                id="max_temp_install"
                value={form.max_temp_install}
                onChange={(e) => {
                  setField("max_temp_install", e.target.value);
                }}
                required
                helperText={errors.max_temp_install}
                sx={{
                  marginTop: "0px",
                  marginBottom: "0px",
                }}
              />
            }
            label={"Temperature pipe install C max"}
            labelPlacement="start"
            value="max_temp_install"
          />
          <FormControlLabel
            className="modal-add-primer_body_form_control-label"
            control={
              <TextField
                type="number"
                // InputProps={{
                //   inputProps: {
                //     max: 100,
                //     min: 0,
                //   },
                // }}
                error={errors.min_temp_install ? true : false}
                id="min_temp_install"
                value={form.min_temp_install}
                onChange={(e) => {
                  setField("min_temp_install", e.target.value);
                }}
                required
                helperText={errors.min_temp_install}
                sx={{
                  marginTop: "0px",
                  marginBottom: "0px",
                }}
              />
            }
            label={"Temperature pipe install C min"}
            labelPlacement="start"
            value="min_temp_install"
          />

          <FormControlLabel
            className="modal-add-primer_body_form_control-label"
            control={
              <TextField
                type="number"
                // InputProps={{
                //   inputProps: {
                //     max: 100,
                //     min: 0,
                //   },
                // }}
                error={errors.weight_bucket ? true : false}
                id="weight_bucket"
                value={form.weight_bucket}
                onChange={(e) => {
                  setField("weight_bucket", e.target.value);
                }}
                required
                helperText={errors.weight_bucket}
                sx={{
                  marginTop: "0px",
                  marginBottom: "0px",
                }}
              />
            }
            label={"Weight of bucket"}
            labelPlacement="start"
            value="weight_bucket"
          />

          <div className="modal-add-primer_body_form_wrap-btns">
            <Button
              type={TypesButton["modal-outline"]}
              handleClick={() => handleCloseModal()}
              text="Cancel"
            />
            <Button
              type={TypesButton.modal}
              text="Save"
              handleClick={() => handleSave()}
            />
          </div>
        </form>
        {isCreatedProduct && (
          <div className="modal-add-primer_body_form_success-text">
            Primer successfully created
          </div>
        )}
        {isEditedProduct && primer !== null && (
          <div className="modal-add-primer_body_form_success-text">
            Primer successfully edited
          </div>
        )}
        {error !== null && (
          <div className="modal-add-primer_body_form_error-text">{error}</div>
        )}
      </DialogContent>
    </BootstrapDialog>
  );
};

export default ModalAddPrimer;
