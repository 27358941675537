import DialogContent from "@mui/material/DialogContent";

import { BootstrapDialog, Button } from "components/library";

import { TypesButton } from "types";

import "./modal-confirm.scss";

type ModalConfirmProps = {
  show: boolean;
  textQuestion: string;
  handleCancel: Function;
  handleYes: Function;
};

const ModalConfirm = ({
  show,
  textQuestion,
  handleCancel,
  handleYes,
}: ModalConfirmProps) => {
  return (
    <BootstrapDialog
      aria-labelledby="customized-confirm-dialog"
      open={show}
      className="modal-confirm"
    >
      <DialogContent className="modal-confirm_body">
        <div className="modal-confirm_body_title">Are you sure?</div>
        <div className="modal-confirm_body_question">{textQuestion}</div>

        <div className="modal-confirm_body_wrap-btns">
          <Button
            type={TypesButton["popup-outline"]}
            handleClick={() => handleCancel()}
            text="Cancel"
          />
          <Button
            type={TypesButton.popup}
            handleClick={() => handleYes()}
            text="Yes"
          />
        </div>
      </DialogContent>
    </BootstrapDialog>
  );
};

export default ModalConfirm;
