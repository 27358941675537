import { useEffect } from "react";

import DialogContent from "@mui/material/DialogContent";

import { TypesButton } from "types";

import { useAppDispatch, useAppSelector } from "app/hook";

import {
  BootstrapDialog,
  BootstrapDialogTitle,
  Button,
  ExportSvg,
} from "components/library";

import {
  cleanError,
  cleanHistory,
  getHistory,
  getHistoryExport,
} from "../products-management-slice";

import BasicTable from "./table-history";

import "./modal-history.scss";

type ModalHistoryProps = {
  show: boolean;
  handleClose: Function;
  selectedMaterial: {
    id: number;
    type: string;
    name: string;
  } | null;
};

const ModalHistory = ({
  show,
  handleClose,
  selectedMaterial,
}: ModalHistoryProps) => {
  const { error, loading, history } = useAppSelector(
    (state) => state.productsManagement
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      !loading &&
      history === null &&
      selectedMaterial !== null &&
      error === null
    ) {
      dispatch(getHistory(selectedMaterial));
    }
  }, [dispatch, loading, history, selectedMaterial, error]);

  const handleCloseModal = () => {
    handleClose(false);
    dispatch(cleanHistory());
    dispatch(cleanError());
  };

  return (
    <BootstrapDialog
      onClose={() => handleCloseModal()}
      aria-labelledby="customized-history"
      open={show}
      className="modal-history"
    >
      <BootstrapDialogTitle
        id="customized-history"
        onClose={() => handleCloseModal()}
      >
        <div>History of changes</div>
        <div>{selectedMaterial?.name}</div>
      </BootstrapDialogTitle>
      <DialogContent className="modal-history_body">
        <BasicTable
          columns={[
            { id: "id", label: "id", minWidth: 50, hide: true },
            { id: "user_name", label: "USER NAME", minWidth: 170 },
            { id: "field_name", label: "FIELD NAME", minWidth: 170 },
            { id: "from", label: "FROM", minWidth: 170 },
            { id: "to", label: "TO", minWidth: 170 },
            { id: "updated_at", label: "LAST UPDATE", minWidth: 170 },
          ]}
          rows={history || []}
        />
        <div className="modal-history_body_wrap-btns">
          <Button
            type={TypesButton.link}
            handleClick={() =>
              selectedMaterial !== null
                ? dispatch(getHistoryExport(selectedMaterial))
                : ""
            }
            text="Export table"
            icon={
              <ExportSvg isDisable={history === null || history.length === 0} />
            }
            disabled={history === null || history.length === 0}
          />
        </div>
        {error !== null && (
          <div className="modal-history_body_error-text">{error}</div>
        )}
      </DialogContent>
    </BootstrapDialog>
  );
};

export default ModalHistory;
