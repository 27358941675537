export const getDate = (strDate: string): string => {
  const curDate = new Date(strDate);
  return `${
    curDate.getDate() < 10 ? `0${curDate.getDate()}` : curDate.getDate()
  }.${
    curDate.getMonth() + 1 < 10
      ? `0${curDate.getMonth() + 1}`
      : curDate.getMonth() + 1
  }.${curDate.getFullYear()}`;
};

export const getDateTime = (strDate: string): string => {
  const curDate = new Date(strDate);
  return `${
    curDate.getDate() < 10 ? `0${curDate.getDate()}` : curDate.getDate()
  }.${
    curDate.getMonth() + 1 < 10
      ? `0${curDate.getMonth() + 1}`
      : curDate.getMonth() + 1
  }.${curDate.getFullYear()} ${
    curDate.getHours() < 10 ? `0${curDate.getHours()}` : curDate.getHours()
  }:${
    curDate.getMinutes() < 10
      ? `0${curDate.getMinutes()}`
      : curDate.getMinutes()
  }:${
    curDate.getSeconds() < 10
      ? `0${curDate.getSeconds()}`
      : curDate.getSeconds()
  }`;
};
