import { ContentManagement } from "features/content-management";

const Composit = () => {
  return (
    <div>
      <ContentManagement />
    </div>
  );
};

export default Composit;
