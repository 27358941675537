import { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import DialogContent from "@mui/material/DialogContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Switch from "@mui/material/Switch";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";

import { useAppDispatch, useAppSelector } from "app/hook";

import { TypesButton } from "types";
import {
  BasicTable,
  BootstrapDialog,
  BootstrapDialogTitle,
  Button,
  ModalConfirm,
  SearchInput,
} from "components/library";

import { Column } from "components/library/basic-table/basic-table";

import {
  cleanError,
  getUserDetail,
  resetCreatedUser,
  userAccept,
  userCreateAdmin,
  userCreateSale,
  userEdit,
} from "../users-management-slice";

import "./modal-add-user.scss";

type ModalAddUserErrors = {
  name?: string;
  telephone?: string;
  email?: string;
  location?: string;
  geozone?: string;
};

type ModalAddUserProps = {
  show: boolean;
  handleClose: Function;
  user: {
    id: number;
    name: string;
    email: string;
    phone: string;
    location: string;
    geozone: string;
    role: string;
  } | null;
  type: string;
};

type ModalAddUserForm = {
  id: number | null;
  name: string;
  email: string;
  telephone: string;
  location: string;
  geozone: string;
  role: string;
  importManyDefects: boolean;
  importManyComposits: boolean;
  accessLevel: {
    permissions: string[] | null;
    composits: number[] | null;
    fillers: number[] | null;
    primers: number[] | null;
  };
};

const ModalAddUser = ({ show, handleClose, user, type }: ModalAddUserProps) => {
  const { email, userId } = useAppSelector((state) => state.loginUser);
  const {
    error,
    isCreatedUser,
    isEditedUser,
    geoZones,
    permissions,
    detailEditUser,
    loading,
  } = useAppSelector((state) => state.usersManagement);
  const {
    // error: errorP,
    listComposits,
    listFillers,
    listPrimers,
  } = useAppSelector((state) => state.productsManagement);

  const [showModalConfirm, setShowModalConfirm] = useState(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      userId !== "" &&
      email !== "" &&
      user !== null &&
      !loading &&
      detailEditUser === null
    ) {
      dispatch(getUserDetail(user.id));
    }
  }, [dispatch, email, userId, loading, detailEditUser, user]);

  let defaultFillers =
    listFillers
      ?.filter((item) => !!item.display_for_distributor)
      .map((item) => {
        return item.id;
      }) || [];

  let defaultPrimers =
    listPrimers
      ?.filter((item) => !!item.display_for_distributor)
      .map((item) => {
        return item.id;
      }) || [];

  let defaultComposits =
    listComposits
      ?.filter((item) => !!item.display_for_distributor)
      .map((item) => {
        return item.id;
      }) || [];

  const [form, setForm] = useState<ModalAddUserForm>(
    user === null
      ? {
          id: null,
          name: "",
          telephone: "",
          email: "",
          location: "",
          geozone: "Africa",
          role: "SALE_MANAGER",
          importManyDefects: false,
          importManyComposits: false,
          accessLevel: {
            permissions: [],
            composits: defaultComposits,
            fillers: defaultFillers,
            primers: defaultPrimers,
          },
        }
      : {
          id: user.id,
          name: user.name,
          telephone: user.phone || "",
          email: user.email,
          location: user.location,
          geozone: user.geozone,
          role: user.role,
          importManyDefects: false,
          importManyComposits: false,
          accessLevel: {
            permissions: null,
            composits: null,
            fillers: null,
            primers: null,
          },
        }
  );
  const [errors, setErrors] = useState<ModalAddUserErrors>({});

  const [showModalRights, setShowModalRights] = useState(false);
  const [activeButton, setActiveButton] = useState("permissions");
  const [searchText, setSearchText] = useState("");

  if (detailEditUser !== null && form.accessLevel.permissions === null) {
    setForm({
      ...form,
      importManyDefects: detailEditUser.is_lot_of_damage_allowed,
      importManyComposits: detailEditUser.is_lot_of_composite_allowed,
      accessLevel: {
        permissions: detailEditUser.calculation_permissions,
        composits: detailEditUser.material_ids,
        fillers: detailEditUser.filler_ids,
        primers: detailEditUser.primer_ids,
      },
    });
  }

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newActiveButton: string
  ) => {
    setActiveButton(newActiveButton);
  };

  const setField = (field: string, value: string | boolean) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!!errors[field as keyof ModalAddUserErrors]) {
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };

  const validateForm = () => {
    const { name, telephone, email, location } = form;
    const newErrors: ModalAddUserErrors = {};

    if (!name || name === "") newErrors.name = "Please enter name";
    if (telephone) {
      const check = telephone.toLowerCase().match(/^[+0-9]*$/);
      if (check === null) newErrors.telephone = "Please enter telephone";
    }

    if (email === "") newErrors.email = "Please enter email";
    else {
      const check = email.toLowerCase().match(
        // eslint-disable-next-line max-len
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      if (check === null) newErrors.email = "Please enter email";
    }

    if (!location || location === "")
      newErrors.location = "Please enter location";

    return newErrors;
  };

  const handleCloseModal = () => {
    handleClose(false);
    dispatch(cleanError());
    dispatch(resetCreatedUser());
  };

  const handleSetDefault = () => {
    setForm({
      ...form,
      accessLevel: {
        permissions: form.accessLevel.permissions,
        composits: defaultComposits,
        fillers: defaultFillers,
        primers: defaultPrimers,
      },
    });
    setShowModalConfirm(false);
  };

  const handleSave = () => {
    const formErrors = validateForm();

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      setErrors({});
      if (type === "new") {
        if (
          form.role === "ADMINISTRATOR" ||
          form.role === "TECHNICAL_ADMINISTRATOR"
        )
          dispatch(userCreateAdmin({ ...form }));
        if (form.role === "SALE_MANAGER" || form.role === "TECHNICAL_3X")
          dispatch(userCreateSale({ ...form }));
        // if (form.role === "DISTRIBUTOR") dispatch(userCreateSale({ ...form }));
      }
      if (type === "edit") {
        dispatch(userEdit({ ...form }));
      }
      if (type === "accept") {
        dispatch(userAccept({ ...form }));
      }
    }
  };

  const handleChangeAction = (id: string | number, value: boolean) => {
    if (
      activeButton === "permissions" ||
      activeButton === "composits" ||
      activeButton === "fillers" ||
      activeButton === "primers"
    ) {
      const newId =
        activeButton === "permissions"
          ? `${id}`
          : typeof id === "number"
          ? id
          : parseInt(id);

      const accessList = form.accessLevel[activeButton];

      if (
        value &&
        accessList !== null &&
        !(accessList as Array<string | number>).includes(newId)
      ) {
        const newPermissions = [...accessList];
        newPermissions.push(newId);

        setForm({
          ...form,
          accessLevel: {
            ...form.accessLevel,
            [activeButton]: [...newPermissions],
          },
        });
      }

      if (
        !value &&
        accessList !== null &&
        (accessList as Array<string | number>).includes(newId)
      ) {
        const newPermissions = (accessList as Array<string | number>).filter(
          (item) => item !== newId
        );

        setForm({
          ...form,
          accessLevel: {
            ...form.accessLevel,
            [activeButton]: [...newPermissions],
          },
        });
      }
    }
  };

  const getColumns = (): Column[] => {
    return [
      { id: "id", label: "id", minWidth: 50, hide: true },
      { id: "name", label: "Product name", minWidth: 170 },
      {
        id: "action",
        label: "Action",
        minWidth: 170,
        align: "right",
        renderCell: (params: any) => {
          return (
            <>
              <Switch
                checked={params.action}
                onChange={(e) => {
                  handleChangeAction(params.id, e.target.checked);
                }}
              />
            </>
          );
        },
      },
    ];
  };

  const getRows = () => {
    if (listComposits !== null && activeButton === "composits") {
      return listComposits
        .filter(
          (row) =>
            row.trade_name.toLowerCase().includes(searchText.toLowerCase()) // was crash here
        )
        .map((row) => {
          const action =
            form.accessLevel.composits !== null
              ? form.accessLevel.composits.includes(row.id)
              : false;
          return { ...row, action, name: row.trade_name };
        });
    }

    if (listFillers !== null && activeButton === "fillers") {
      return listFillers
        .filter((row) =>
          row.name.toLowerCase().includes(searchText.toLowerCase())
        )
        .map((row) => {
          const action =
            form.accessLevel.fillers !== null
              ? form.accessLevel.fillers.includes(row.id)
              : false;
          return { ...row, action };
        });
    }

    if (listPrimers !== null && activeButton === "primers") {
      return listPrimers
        .filter((row) =>
          row.name.toLowerCase().includes(searchText.toLowerCase())
        )
        .map((row) => {
          const action =
            form.accessLevel.primers !== null
              ? form.accessLevel.primers.includes(row.id)
              : false;
          return { ...row, action };
        });
    }

    if (permissions !== null)
      return permissions
        .filter((row) =>
          row.name.toLowerCase().includes(searchText.toLowerCase())
        )
        .map((row) => {
          const action =
            form.accessLevel.permissions !== null
              ? form.accessLevel.permissions.includes(`${row.id}`)
              : false;
          return { ...row, action };
        });

    return [];
  };

  return (
    <BootstrapDialog
      onClose={() => handleCloseModal()}
      aria-labelledby="customized-add-user"
      open={show}
      className="modal-add-user"
    >
      <BootstrapDialogTitle
        id="customized-add-user"
        onClose={() => handleCloseModal()}
      >
        {showModalRights ? (
          <>
            Set access level, <span>{form.name}</span>
          </>
        ) : type === "new" ? (
          "Add a new user"
        ) : type === "edit" ? (
          <>
            {`Edit user, ${form.name}`}{" "}
            <NoteAltOutlinedIcon style={{ marginLeft: "14px" }} />
          </>
        ) : (
          `Accept user, ${form.name}`
        )}
      </BootstrapDialogTitle>
      {!showModalRights && (
        <DialogContent className="modal-add-user_body">
          <form className="modal-add-user_body_form" autoComplete="off">
            <FormControlLabel
              className="modal-add-user_body_form_control-label"
              control={
                <TextField
                  autoFocus
                  id="name"
                  value={form.name}
                  onChange={(e) => {
                    setField("name", e.target.value);
                  }}
                  required
                  error={errors.name ? true : false}
                  helperText={errors.name}
                  sx={{
                    marginTop: "0px",
                    marginBottom: "0px",
                  }}
                />
              }
              label={"Name"}
              labelPlacement="start"
              value="name"
            />
            <FormControlLabel
              className="modal-add-user_body_form_control-label"
              control={
                <TextField
                  error={errors.location ? true : false}
                  id="location"
                  value={form.location}
                  onChange={(e) => {
                    setField("location", e.target.value);
                  }}
                  required
                  helperText={errors.location}
                  sx={{
                    marginTop: "0px",
                    marginBottom: "0px",
                  }}
                />
              }
              label={"Location"}
              labelPlacement="start"
              value="location"
            />

            <FormControlLabel
              className="modal-add-user_body_form_control-label not-require"
              control={
                <TextField
                  error={errors.telephone ? true : false}
                  id="telephone"
                  value={form.telephone}
                  onChange={(e) => {
                    setField("telephone", e.target.value);
                  }}
                  helperText={errors.telephone}
                  sx={{
                    marginTop: "0px",
                    marginBottom: "0px",
                  }}
                />
              }
              label={"Telephone"}
              labelPlacement="start"
              value="telephone"
            />
            <FormControlLabel
              className="modal-add-user_body_form_control-label"
              control={
                <TextField
                  error={errors.email ? true : false}
                  id="email"
                  type="email"
                  value={form.email}
                  onChange={(e) => {
                    setField("email", e.target.value);
                  }}
                  required
                  helperText={errors.email}
                  sx={{
                    marginTop: "0px",
                    marginBottom: "0px",
                  }}
                  disabled={user !== null}
                />
              }
              label={"E-mail"}
              labelPlacement="start"
              value="email"
            />
            <FormControlLabel
              className="modal-add-user_body_form_control-label"
              control={
                <Select
                  id="role"
                  value={form.role}
                  onChange={(e) => {
                    setField("role", e.target.value);
                  }}
                  disabled={user !== null}
                >
                  <MenuItem key="ADMINISTRATOR" value="ADMINISTRATOR">
                    ADMINISTRATOR
                  </MenuItem>
                  <MenuItem
                    key="TECHNICAL_ADMINISTRATOR"
                    value="TECHNICAL_ADMINISTRATOR"
                  >
                    TECHNICAL ADMINISTRATOR
                  </MenuItem>
                  <MenuItem key="TECHNICAL_3X" value="TECHNICAL_3X">
                    TECHNICAL 3X
                  </MenuItem>
                  <MenuItem key="SALE_MANAGER" value="SALE_MANAGER">
                    SALE_MANAGER
                  </MenuItem>
                  {user !== null && user.role === "DISTRIBUTOR" && (
                    <MenuItem key="DISTRIBUTOR" value="DISTRIBUTOR">
                      DISTRIBUTOR
                    </MenuItem>
                  )}
                </Select>
              }
              label={"Role"}
              labelPlacement="start"
              value="role"
            />
            <FormControlLabel
              className="modal-add-user_body_form_control-label"
              control={
                <Select
                  id="geozone"
                  value={form.geozone}
                  onChange={(e) => {
                    setField("geozone", e.target.value);
                  }}
                >
                  {geoZones !== null &&
                    geoZones.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.name}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              }
              disabled={user !== null}
              label={"Geozone"}
              labelPlacement="start"
              value="geozone"
            />
            {/* <FormControlLabel
              className="modal-add-user_body_form_control-label"
              control={
                <TextField
                  error={errors.company ? true : false}
                  id="company"
                  value={form.company}
                  onChange={(e) => {
                    setField("company", e.target.value);
                  }}
                  required
                  helperText={errors.company}
                  sx={{
                    marginTop: "0px",
                    marginBottom: "0px",
                  }}
                />
              }
              label={"Company"}
              labelPlacement="start"
              value="company"
            /> */}
            <Button
              type={TypesButton.link}
              text="Set access level"
              handleClick={() => {
                setShowModalRights(true);
              }}
              disabled={
                form.role === "ADMINISTRATOR" ||
                form.role === "TECHNICAL_ADMINISTRATOR"
              }
            />
            <div className="modal-add-user_body_form_wrap-btns">
              <Button
                type={TypesButton["modal-outline"]}
                handleClick={() => handleCloseModal()}
                text="Cancel"
              />
              <Button
                type={TypesButton.modal}
                text="Save"
                handleClick={() => handleSave()}
              />
            </div>
          </form>
          {isCreatedUser && (
            <div className="modal-add-user_body_form_success-text">
              User successfully created
            </div>
          )}
          {isEditedUser && user !== null && (
            <div className="modal-add-user_body_form_success-text">
              User successfully edited
            </div>
          )}
          {error !== null && (
            <div className="modal-add-user_body_form_error-text">{error}</div>
          )}
        </DialogContent>
      )}

      {showModalRights && (
        <DialogContent
          className="modal-add-user_body"
          style={{ height: "682px" }}
        >
          <form className="form-access-level" autoComplete="off">
            <ToggleButtonGroup
              className="form-access-level_btn-group"
              color="primary"
              value={activeButton}
              exclusive
              onChange={handleChange}
              aria-label="Set acees level"
            >
              <ToggleButton
                className={
                  activeButton === "permissions"
                    ? "form-access-level_btn-group_btn form-access-level_btn-group_btn-active"
                    : "form-access-level_btn-group_btn"
                }
                value="permissions"
              >
                Permissions
              </ToggleButton>
              <ToggleButton
                className={
                  activeButton === "composits"
                    ? "form-access-level_btn-group_btn form-access-level_btn-group_btn-active"
                    : "form-access-level_btn-group_btn"
                }
                value="composits"
              >
                Composites
              </ToggleButton>
              <ToggleButton
                className={
                  activeButton === "fillers"
                    ? "form-access-level_btn-group_btn form-access-level_btn-group_btn-active"
                    : "form-access-level_btn-group_btn"
                }
                value="fillers"
              >
                Fillers
              </ToggleButton>
              <ToggleButton
                className={
                  activeButton === "primers"
                    ? "form-access-level_btn-group_btn form-access-level_btn-group_btn-active"
                    : "form-access-level_btn-group_btn"
                }
                value="primers"
              >
                Primers
              </ToggleButton>
            </ToggleButtonGroup>
            <SearchInput setSearchText={setSearchText} />
            <BasicTable rows={getRows()} columns={getColumns()} />
            <FormControlLabel
              className="modal-add-user_body_form_checkbox"
              control={
                <Checkbox
                  checked={
                    form.role === "ADMINISTRATOR" ||
                    form.role === "TECHNICAL_ADMINISTRATOR"
                      ? false
                      : form.importManyDefects
                  }
                  onChange={(e) => {
                    setField("importManyDefects", e.target.checked);
                  }}
                />
              }
              label="Import your project file"
              disabled={
                form.role === "ADMINISTRATOR" ||
                form.role === "TECHNICAL_ADMINISTRATOR"
              }
            />{" "}
            <FormControlLabel
              className="modal-add-user_body_form_checkbox"
              control={
                <Checkbox
                  checked={
                    form.role === "ADMINISTRATOR" ||
                    form.role === "TECHNICAL_ADMINISTRATOR"
                      ? false
                      : form.importManyComposits
                  }
                  onChange={(e) => {
                    setField("importManyComposits", e.target.checked);
                  }}
                />
              }
              label="Save results to file"
              disabled={
                form.role === "ADMINISTRATOR" ||
                form.role === "TECHNICAL_ADMINISTRATOR"
              }
            />
            {showModalConfirm && (
              <ModalConfirm
                show={showModalConfirm}
                // eslint-disable-next-line max-len
                textQuestion={"Are you sure to set default products?"}
                handleCancel={() => setShowModalConfirm(false)}
                handleYes={() => handleSetDefault()}
              />
            )}
            <Button
              type={TypesButton.secondary}
              text="Set default products"
              handleClick={() => setShowModalConfirm(true)}
            />
            <div className="modal-add-user_body_form_wrap-btns">
              <Button
                type={TypesButton["modal-outline"]}
                handleClick={() =>
                  showModalRights
                    ? setShowModalRights(false)
                    : handleCloseModal()
                }
                text="Cancel"
              />
              <Button
                type={TypesButton.modal}
                text="Save"
                handleClick={() => handleSave()}
              />
            </div>
          </form>
          {isCreatedUser && (
            <div className="modal-add-user_body_form_success-text">
              User successfully created
            </div>
          )}
          {isEditedUser && user !== null && (
            <div className="modal-add-user_body_form_success-text">
              User successfully edited
            </div>
          )}
          {error !== null && (
            <div className="modal-add-user_body_form_error-text">{error}</div>
          )}
        </DialogContent>
      )}
    </BootstrapDialog>
  );
};

export default ModalAddUser;
