import { UsersManagement } from "features/users-management";

const Home = () => {
  return (
    <div>
      <UsersManagement />
    </div>
  );
};

export default Home;
