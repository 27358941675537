import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";

import { Button } from "components/library";
import { TypesButton } from "types";

import ReasonManagement from "./components/reason-management";

import "./reason.scss";

type ReasonProps = {
  handleBack: Function;
};

export default function Reason({ handleBack }: ReasonProps) {
  return (
    <div className="reason">
      <div className="reason_title">
        <div>Rejected status reason</div>
        <Button
          type={TypesButton.primary}
          text="Back to Content Management"
          handleClick={() => {
            handleBack(null);
          }}
          size={250}
        />
      </div>
      <div className="reason_body">
        <Accordion className="reason_body_accordion">
          <AccordionSummary
            expandIcon={
              <ArrowForwardIosSharpIcon
                sx={{ fontSize: "48px", padding: "12px" }}
              />
            }
            aria-controls="status-content"
            id="status-header"
            className="reason_body_accordion_summary"
          >
            <Typography className="reason_body_accordion_summary_title">
              Status reason
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ReasonManagement />
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}
