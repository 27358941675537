import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";

import { useAppDispatch, useAppSelector } from "app/hook";
import { TypesButton } from "types";
import { ModalConfirm, Button, DeleteSvg } from "components/library";

import {
  Reason,
  getReasons,
  contentDeleteReason,
} from "features/content-management/content-management-slice";

import ModalAddReason from "./components/modal-add-reason";

const ReasonManagement = () => {
  const { listReasons, loading } = useAppSelector(
    (state) => state.contentManagement
  );

  const { email, userId } = useAppSelector((state) => state.loginUser);

  const [showModalAdd, setShowModalAdd] = useState(false);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [selectedReason, setSelectedReason] = useState<Reason | null>(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (userId !== "" && email !== "" && !loading && listReasons === null) {
      dispatch(getReasons());
    }
  }, [dispatch, email, userId, listReasons, loading]);

  const columnsReason: GridColDef[] = [
    {
      field: "",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: any) => {
        return (
          <>
            {/*<IconButton
              className="action-btn hover-btn"
              onClick={() => {
                setSelectedReason(params.row);
                setShowModalAdd(true);
              }}
            >
              <EditSvg />
              <EditSvg isHover />
            </IconButton>*/}
            <IconButton
              className="action-btn hover-btn"
              onClick={() => {
                setSelectedReason(params.row);
                setShowModalConfirm(true);
              }}
            >
              <DeleteSvg />
              <DeleteSvg isHover />
            </IconButton>
          </>
        );
      },
    },

    {
      field: "N",
      headerName: "№",
      width: 50,
      renderCell: (params: any) => {
        return params.api.getRowIndex(params.row.id) + 1;
      },
    },
    {
      field: "name",
      headerName: "Text reason",
      width: 150,
    },
  ];

  return (
    <div className="reason-management">
      <div className="reason-management_head">
        <div>
          <Button
            type={TypesButton.primary}
            text="Add a new reason"
            handleClick={() => {
              setSelectedReason(null);
              setShowModalAdd(true);
            }}
            size={178}
            icon={<AddIcon fontSize="small" />}
          />
        </div>
      </div>
      <Box>
        <div style={{ height: "65vh", width: "100%" }}>
          <DataGrid
            rows={listReasons || []}
            columns={columnsReason}
            rowsPerPageOptions={[10, 20, 50, 100]}
            // checkboxSelection
            loading={loading}
            components={{
              Toolbar: GridToolbar,
            }}
            onRowDoubleClick={(selectedRow) => {
              setSelectedReason(null);
              setShowModalAdd(true);
            }}
          />
        </div>
      </Box>
      {showModalAdd && (
        <ModalAddReason
          show={showModalAdd}
          reason={selectedReason}
          handleClose={setShowModalAdd}
        />
      )}
      {showModalConfirm && selectedReason !== null && (
        <ModalConfirm
          show={showModalConfirm}
          textQuestion={`Are you sure that you want to delete reason - ${selectedReason?.id} ${selectedReason?.name}?`}
          handleCancel={() => setShowModalConfirm(false)}
          handleYes={() => {
            dispatch(contentDeleteReason(selectedReason.id || -1));
            setShowModalConfirm(false);
          }}
        />
      )}
    </div>
  );
};

export default ReasonManagement;
