import { useEffect } from "react";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "app/hook";

import {
  getAllProjects,
  fetchDistributors,
  fetchProposalsByRootId,
} from "./proposals-and-projects-slice";

import "./proposals-and-projects.scss";

const ProjectsList = () => {
  const { allProjects, allDistributors, loading } = useAppSelector(
    (state) => state.proposalsAndProjects
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAllProjects());
    dispatch(fetchDistributors());
  }, [dispatch]);

  const columnsProject: GridColDef[] = [
    {
      field: "number",
      headerName: "№",
      width: 70,
      sortable: false,
      renderCell: (params: any) => {
        return (
          <div className="number-wrapp">
            <span>{params.id}</span>
          </div>
        );
      },
    },
    { field: "client", headerName: "Client", width: 150 },
    {
      field: "distributor",
      headerName: "Distributor",
      width: 180,
      renderCell: (params: any) => {
        const distributor = allDistributors.find(
          (item) => item.user_id === params.row.distributor_id
        )?.name;
        return <div>{distributor}</div>;
      },
    },
    {
      field: "company",
      headerName: "Company",
      width: 150,
      renderCell: () => {
        return <div>3X Engineering</div>;
      },
    },
    { field: "project", headerName: "Project", width: 150 },
    {
      field: "projectStatus",
      headerName: "Project Status",
      width: 240,
      renderCell: (params: any) => {
        return (
          <div className={`project-status ${params.row.status}`}>
            {params.row.status_description}
          </div>
        );
      },
    },
    { field: "engineer_manager", headerName: "Editor", width: 140 },
    { field: "id", headerName: "Project ID", width: 110 },
    { field: "date", headerName: "Creation Date", width: 120 },
  ];

  return (
    <div className="project-list">
      <div className="project-list_header">
        <span className="project-list_title">Projects</span>
      </div>
      <Box>
        <div style={{ height: "75vh", width: "100%" }}>
          <DataGrid
            rows={allProjects.content || []}
            columns={columnsProject}
            // pageSize={10}
            rowsPerPageOptions={[10, 20, 50, 100]}
            loading={loading}
            components={{
              Toolbar: GridToolbar,
            }}
            onCellClick={(params) => {
              dispatch(fetchProposalsByRootId(params.row.id));
              navigate("/proposals");
            }}
          />
        </div>
      </Box>
    </div>
  );
};

export default ProjectsList;
