import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { History } from "features/products-management/products-management-slice";
import { getDateTime } from "helpers/getDate";

export interface Column {
  id: "id" | "user_name" | "field_name" | "from" | "to" | "updated_at";
  label: string;
  hide?: boolean;
  minWidth?: number;
  align?: "right";
  //   format?: (value: number) => string;
  renderCell?: (params: any) => React.ReactNode;
}

type BasicTableProps = {
  columns: Column[];
  rows: History[];
};

const BasicTable = ({ columns, rows }: BasicTableProps) => {
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 335 }}>
        <Table stickyHeader size="small" aria-label="simple table">
          <TableHead>
            <TableRow key={0}>
              {columns.map((column) => {
                if (!column.hide) {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  );
                }
                return null;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length > 0 &&
              rows.map((row, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={`${row.id} - ${index}`}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    {columns.map((column, index) => {
                      if (!column.hide) {
                        const value = row[column.id];
                        return (
                          <TableCell
                            key={`${column.id}-${row.id}-${index}`}
                            align={column.align}
                          >
                            {column.id === "updated_at" &&
                            typeof value === "string"
                              ? getDateTime(value)
                              : value}
                          </TableCell>
                        );
                      }
                      return null;
                    })}
                  </TableRow>
                );
              })}
            {rows.length === 0 && (
              <TableRow key={1}>
                <TableCell key={"1-1"} align="center" colSpan={5}>
                  No data
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default BasicTable;
